import { Box, Button, Grid, Typography } from "@mui/material";
import { Info, X } from "phosphor-react";
import React from "react";
import useAuth from "../../../../hooks/useAuth";
import DefaultImg from "../../../../assets/defaultimg.png";

const UserInfoModal = ({ closeModal, userInfo }) => {
  const { currentOrg } = useAuth();
  return (
    <Box
      p={4}
      style={{
        borderRadius: "10px",
        width: "600px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Info size={25} />
          <Typography variant="h2" fontWeight={600}>
            User Info
          </Typography>
        </div>
        <Button onClick={closeModal}>
          <X color="#000000" size={25} />
        </Button>
      </div>
      <Box
        mt={3}
        style={{ display: "flex", alignItems: "flex-start", gap: "24px" }}
      >
        <img
          src={userInfo.avatar ? userInfo.avatar : DefaultImg}
          style={{ height: "100px", width: "100px", borderRadius: "5px" }}
        />
        <Box style={{ width: "80%" }}>
          <Grid container>
            <Grid item xs={5} color="#666666">
              User
            </Grid>
            <Grid item xs={2}>
              :
            </Grid>
            <Grid item xs={5}>
              {userInfo.first_name} {userInfo.last_name}
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={5} color="#666666">
              Email ID
            </Grid>
            <Grid item xs={2}>
              :
            </Grid>
            <Grid item xs={5}>
              {userInfo.email}
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={5} color="#666666">
              Phone Number
            </Grid>
            <Grid item xs={2}>
              :
            </Grid>
            <Grid item xs={5}>
              {userInfo.phone_number?.raw}
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={5} color="#666666">
              Org Name
            </Grid>
            <Grid item xs={2}>
              :
            </Grid>
            <Grid item xs={5}>
              {currentOrg?.name}
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={5} color="#666666">
              User Role
            </Grid>
            <Grid item xs={2}>
              :
            </Grid>
            <Grid item xs={5}>
              {userInfo.role}
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default UserInfoModal;
