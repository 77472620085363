import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles({
  container2: {
    width: "100%",
    height: "100%",
    padding: "64px 128px",
    display: "flex",
    flexDirection: "column",
    boxSizing: "border-box",
  },
  container3: {
    width: "3%",
    height: "100%",
    backgroundColor: "#141417",
  },
  innerContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    width: "60%",
  },
  flexBoxSpaceBetween: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  inputContainer: {
    width: "47%",
  },
  gapContainer: {
    display: "flex",
    alignItems: "center",
    gap: "32px",
  },
  gapContainer2: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  blackText: {
    color: "#000000",
  },
  footer: {
    position: "absolute",
    bottom: "55px",
    color: "#666666 !important",
    fontSize: "16px !important",
  },
});

export default useStyles;
