import React, { useEffect, useState } from "react";
import { Typography, Grid, Box, Modal, Button } from "@mui/material";
import { Gear, User, UsersThree } from "phosphor-react";
import { useHistory } from "react-router-dom";
import GearPlusIcon from "../../../ReusableComponents/Svgs/GearPlusIcon";
import GearArrowIcon from "../../../ReusableComponents/Svgs/GearArrowIcon";
import AdminUserIcon from "../../../ReusableComponents/Svgs/AdminUserIcon";
import DeleteModal from "../../../ReusableComponents/DeleteModal/DeleteModal";
import { fget, fpatch } from "../../../../API/callsApi";
import CommonLoader from "../../../ReusableComponents/CommonLoader/CommonLoader";
import OrganizationActionsModals from "../../OrganizationsModals/OrganizationActionModal/OrganizationActionsModals";
import useAuth from "../../../../hooks/useAuth";
import toast from "react-hot-toast";

const MyOrganizationUserView = ({ currentOrganization }) => {
  const { getOrgList, userData } = useAuth();
  const history = useHistory();
  const [isLeaveOrgModalOpen, setLeaveOrgModalOpen] = useState(false);
  const [currentOrgInfo, setCurrentOrgInfo] = useState({});
  const [isLoading, setLoading] = useState(false);
  const [orgActionModalOpen, setOrgActionModalOpen] = useState(false);
  const [isRemoving, setIsRemovingUser] = useState(false);

  const handleLeaveOrgModal = () => {
    setLeaveOrgModalOpen(!isLeaveOrgModalOpen);
  };

  const handleOrgActionModal = () => {
    setOrgActionModalOpen(!orgActionModalOpen);
  };

  const getOrg = async () => {
    if (currentOrganization) {
      setLoading(true);
      let userData = {
        url: `/user/org/${currentOrganization}/`,
      };
      try {
        const res = await fget(userData);
        if (res?.status === 200 || res.status === 201) {
          setCurrentOrgInfo(res.data);
          setLoading(false);
        }
      } catch (error) {
        console.log(error);
        setLoading(false);
      }
    }
  };

  const removeConnectionAndUser = async () => {
    setIsRemovingUser(true);
    const url = `/organization/${currentOrgInfo.id}/user/${userData.id}/remove/`;
    try {
      const res = await fpatch({ url });
      if (res.status === 200 || res.status === 201) {
        handleLeaveOrgModal();
        handleOrgActionModal();
        getOrgList();
        history.push("/dashboard/?dashboardType=organizations");
        setIsRemovingUser(false);
      }
    } catch (error) {
      toast.error("Some error occured");
      setIsRemovingUser(false);
    }
  };

  useEffect(() => {
    getOrg();
  }, [currentOrganization]);

  return (
    <div style={{ height: "100%" }}>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h2" fontWeight={600}>
          Organization Info
        </Typography>
        <Button onClick={handleOrgActionModal}>
          <Gear weight="fill" size={30} color="#000000" />
        </Button>
      </div>
      {!isLoading && (
        <Box
          mt={2}
          style={{ border: "1px solid #BBBBBB", borderRadius: "10px" }}
        >
          <Grid container spacing={2} p={2}>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <GearPlusIcon />
                  <Typography variant="h4" fontWeight="600">
                    {currentOrgInfo.service_count}
                  </Typography>
                </div>
                <Typography variant="h4">Total Services</Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <GearArrowIcon />
                  <Typography variant="h4" fontWeight="600">
                    {currentOrgInfo.active_services}
                  </Typography>
                </div>
                <Typography variant="h4">Active Services</Typography>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <UsersThree size={35} />
                  <Typography variant="h4" fontWeight="600">
                    {currentOrgInfo.user_count}
                  </Typography>
                </div>
                <Typography variant="h4">Total Users</Typography>
              </div>
            </Grid>
            <Grid item xs={2}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <AdminUserIcon />
                  <Typography variant="h4" fontWeight="600">
                    {currentOrgInfo.admin_count}
                  </Typography>
                </div>
                <Typography variant="h4">Admin Users</Typography>
              </div>
            </Grid>
            <Grid item xs={3}>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <div
                  style={{ display: "flex", alignItems: "center", gap: "10px" }}
                >
                  <User size={35} />
                  <Typography variant="h4" fontWeight="600">
                    {currentOrgInfo.user_count - currentOrgInfo.admin_count}
                  </Typography>
                </div>
                <Typography variant="h4">Regular Users</Typography>
              </div>
            </Grid>
          </Grid>
        </Box>
      )}

      {isLoading ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <CommonLoader />
        </div>
      ) : (
        <Grid container mt={3}>
          <Grid item xs={5}>
            <Grid container>
              <Grid item xs={5}>
                <Typography variant="h5" style={{ color: "#666666" }}>
                  Org Owner
                </Typography>
              </Grid>
              <Grid item xs={2}>
                :
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h5">
                  {currentOrgInfo.owner?.first_name}{" "}
                  {currentOrgInfo.owner?.last_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={1}>
              <Grid item xs={5}>
                <Typography variant="h5" style={{ color: "#666666" }}>
                  Created by
                </Typography>
              </Grid>
              <Grid item xs={2}>
                :
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h5">
                  {currentOrgInfo.created_by?.first_name}{" "}
                  {currentOrgInfo.created_by?.last_name}
                </Typography>
              </Grid>
            </Grid>
            <Grid container mt={1}>
              <Grid item xs={5}>
                <Typography variant="h5" style={{ color: "#666666" }}>
                  Created on
                </Typography>
              </Grid>
              <Grid item xs={2}>
                :
              </Grid>
              <Grid item xs={5}>
                <Typography variant="h5">
                  {new Date(currentOrgInfo.joining_date).toLocaleDateString(
                    "en-US",
                    {
                      month: "numeric",
                      day: "numeric",
                      year: "numeric",
                    }
                  )}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={4}>
            <Grid container>
              <Grid item xs={5}>
                <Typography variant="h5" style={{ color: "#666666" }}>
                  User Role
                </Typography>
              </Grid>
              <Grid item xs={2}>
                :
              </Grid>
              <Grid item xs={5} display="flex" alignItems="center" gap={1}>
                {currentOrgInfo.role === "user" && <User />}
                <Typography variant="h5">
                  {currentOrgInfo.role &&
                    currentOrgInfo.role?.slice(0, 1)?.toUpperCase()}
                  {currentOrgInfo.role?.slice(1)}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      )}

      <Modal
        open={isLeaveOrgModalOpen}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={handleLeaveOrgModal}
      >
        <DeleteModal
          leave={true}
          handleDelete={removeConnectionAndUser}
          closeModal={handleLeaveOrgModal}
          text1="Are you sure you want to leave the following organization?"
          text2={currentOrgInfo.name}
          btnText="Leave Organization"
          isRemoving={isRemoving}
        />
      </Modal>
      <Modal
        open={orgActionModalOpen}
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
        onClose={handleOrgActionModal}
      >
        <OrganizationActionsModals
          name={currentOrgInfo.name}
          close={handleOrgActionModal}
          view="user"
          openLeaveOrgModal={handleLeaveOrgModal}
        />
      </Modal>
    </div>
  );
};

export default MyOrganizationUserView;
