import React from "react";

const ReplaceUserIcon = () => {
  return (
    <svg
      width="22"
      height="22"
      viewBox="0 0 22 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M14.6654 8.25008C20.1654 8.25008 20.1654 11.9167 20.1654 11.9167V13.2917C20.1654 13.5449 19.9602 13.7501 19.707 13.7501H15.1237C14.8706 13.7501 14.6654 13.5449 14.6654 13.2917V11.9167C14.6654 11.9167 14.6654 10.3676 13.6112 8.98341C13.4125 8.71453 13.5406 8.30863 13.8739 8.28186C14.1224 8.26189 14.3851 8.25008 14.6654 8.25008ZM1.83203 11.9167C1.83203 11.9167 1.83203 8.25008 7.33203 8.25008C12.832 8.25008 12.832 11.9167 12.832 11.9167V13.2917C12.832 13.5449 12.6268 13.7501 12.3737 13.7501H2.29036C2.03723 13.7501 1.83203 13.5449 1.83203 13.2917V11.9167ZM7.46627 16.3658C7.75501 16.0771 8.2487 16.2816 8.2487 16.6899V17.4167H13.7487V16.6899C13.7487 16.2816 14.2424 16.0771 14.5311 16.3658L16.1746 18.0093C16.3536 18.1883 16.3536 18.4785 16.1746 18.6575L14.5311 20.301C14.2424 20.5897 13.7487 20.3852 13.7487 19.9769V19.2501H8.2487V19.9769C8.2487 20.3852 7.75501 20.5897 7.46627 20.301L5.82279 18.6575C5.6438 18.4785 5.6438 18.1883 5.82279 18.0093L7.46627 16.3658ZM7.33203 0.916748C5.81036 0.916748 4.58203 2.14508 4.58203 3.66675C4.58203 5.18841 5.81036 6.41675 7.33203 6.41675C8.8537 6.41675 10.082 5.18841 10.082 3.66675C10.082 2.14508 8.8537 0.916748 7.33203 0.916748ZM14.6654 0.916748C13.1437 0.916748 11.9154 2.14508 11.9154 3.66675C11.9154 5.18841 13.1437 6.41675 14.6654 6.41675C16.187 6.41675 17.4154 5.18841 17.4154 3.66675C17.4154 2.14508 16.187 0.916748 14.6654 0.916748Z"
        fill="black"
      />
    </svg>
  );
};

export default ReplaceUserIcon;
