import React from "react";
import { Box, Typography } from "@mui/material";
import TopBanner from "../../assets/TopBanner.png";
import Navbar from "../NewNavbar/Navbar";

const LicenseAndServiceSetupBanner = ({ children, type }) => {
  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        boxSizing: "border-box !important",
        overflow: "hidden",
      }}
    >
      <Box style={{ height: "33%" }}>
        <div style={{ height: "29%" }}>
          <Navbar servicePage={true} />
        </div>
        <Box
          style={{
            height: "71%",
            position: "relative",
          }}
        >
          <Box style={{ height: "100%" }}>
            <Box
              p={4}
              style={{
                width: "100%",
                height: "100%",
                backgroundImage: `url(${TopBanner})`,
                backgroundSize: "100% 100%",
                color: "#FFFFFF",
                boxSizing: "border-box",
              }}
            >
              <span
                style={{
                  fontSize: "48px",
                  marginTop: "15px",
                  textAlign: "left",
                }}
              >
                {type === "service"
                  ? "Setup your Service in Minutes "
                  : "Get Your License in Minutes"}
              </span>
              <Typography mt={3} align="left" variant="h3">
                Your product awaits—just a few steps to go.
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          height: "66%",
          boxSizing: "border-box",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default LicenseAndServiceSetupBanner;
