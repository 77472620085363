import React from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import RouteRequiresLogin from "./RouteRequiresLogin";
import NotFound from "./components/NotFound";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import UserDataProvider from "./context/UserDataProvider";
import HotToast from "./components/ReusableComponents/HotToast/HotToast";
//new imports
import SideBannerLayout from "./components/SideBarAndNavbar/SideBannerLayout";
import TopBannerLayout from "./components/SideBarAndNavbar/TopBannerLayout";
import CreateOrganizationOverview from "./components/CreateOrganization/CreateOrganizationOverview/CreateOrganizationOverview";
import NewLogin from "./components/Authentications/NewLogin/NewLogin";
import NewForgotPasswordForm from "./components/Authentications/ForgotPassword/NewForgotPasswordForm";
import NewResetPassword from "./components/Authentications/NewPasswordReset/NewResetPassword";
import DashBoardOverview from "./components/DashboardPage/DashBoardOverview/DashBoardOverview";
import ServicesOverview from "./components/ServicesPage/ServicesOverview/ServicesOverview";
import ServicePageBanner from "./components/SideBarAndNavbar/ServicePageBanner";
import ServiceOverview from "./components/ServicesPage/Services/ServiceOverview/ServiceOverview";
import LicenseAndServiceSetupBanner from "./components/SideBarAndNavbar/LicenseAndServiceSetupBanner";
import SetupServiceOverview from "./components/ServicesPage/SetupService/SetupServiceOverview";
import {
  OrganizationComponent,
  UserOrganizationComponent,
  LicenseOverviewComponent,
  PaymentOverviewComponent,
} from "./AdminRouteComponent/AdminRouteComponent";
//new imports

const theme = createTheme({
  typography: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
  button: {
    fontFamily: ["Poppins", "sans-serif"].join(","),
  },
});

//1)check if user is logged in or not!
//2)if user is verified, if email verification is required or not
//3)if user is verified then check if user is part of any organization or not
//4)if user is not part of any organization then prompt user to join one
//5)if user is part of an organization then allow him to access sso.

function App() {
  return (
    <ThemeProvider theme={theme}>
      <HotToast />
      <BrowserRouter>
        {/* if user configuration is pending we should not register other routes of app   */}
        {/* user verification component goes here */}
        <Switch>
          <Route
            exact
            path={["/", "/login", "/login/:slug"]}
            component={() => (
              <SideBannerLayout>
                <NewLogin />
              </SideBannerLayout>
            )}
          />
          <Route
            exact
            path={"/logout/:slug"}
            component={() => (
              <SideBannerLayout>
                <NewLogin clearLocalStorage={true} />
              </SideBannerLayout>
            )}
          />
          {/* <Route
              exact
              path="/signup"
              component={(routerParams) => <Signup {...routerParams} />}
            /> */}
          <Route
            exact
            path="/forgotpassword"
            component={() => (
              <SideBannerLayout>
                <NewForgotPasswordForm />
              </SideBannerLayout>
            )}
          />
          <UserDataProvider>
            <Route
              exact
              path="/reset-password"
              component={() => (
                <SideBannerLayout>
                  <NewResetPassword />
                </SideBannerLayout>
              )}
            />
            <Route
              exact
              path="/create-org"
              component={() => (
                <SideBannerLayout>
                  <CreateOrganizationOverview />
                </SideBannerLayout>
              )}
            />
            {/* ----------------------------------------------------------------------------- */}

            {/* only if user is verified and part of an org then render below component */}
            <RouteRequiresLogin
              exact
              path="/dashboard"
              component={() => (
                <TopBannerLayout>
                  <DashBoardOverview />
                </TopBannerLayout>
              )}
            />
            <RouteRequiresLogin
              exact
              path="/organizations/:orgId"
              component={OrganizationComponent}
            />
            <RouteRequiresLogin
              exact
              path="/user/organizations/:orgId"
              component={UserOrganizationComponent}
            />
            <RouteRequiresLogin
              exact
              path="/organizations/:orgId/services/:sid/license"
              component={LicenseOverviewComponent}
            />
            <RouteRequiresLogin
              exact
              path="/services"
              component={() => (
                <TopBannerLayout type="services">
                  <ServicesOverview />
                </TopBannerLayout>
              )}
            />
            <RouteRequiresLogin
              exact
              path="/services/:sid"
              component={() => (
                <ServicePageBanner>
                  <ServiceOverview />
                </ServicePageBanner>
              )}
            />
            <RouteRequiresLogin
              exact
              path="/services/:sid/setup-service"
              component={() => (
                <LicenseAndServiceSetupBanner type="service">
                  <SetupServiceOverview />
                </LicenseAndServiceSetupBanner>
              )}
            />

            <RouteRequiresLogin
              exact
              path="/organizations/:orgId/services/:sid/payment"
              component={PaymentOverviewComponent}
            />
          </UserDataProvider>
          <Route path="*" component={NotFound} />
        </Switch>
      </BrowserRouter>
    </ThemeProvider>
  );
}
export default App;
