import React from "react";

const LeaveIcon = () => {
  return (
    <svg
      width="36"
      height="36"
      viewBox="0 0 36 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <mask
        id="mask0_2038_9763"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="36"
        height="36"
      >
        <rect width="36" height="36" rx="0.5" fill="#D9D9D9" />
      </mask>
      <g mask="url(#mask0_2038_9763)">
        <path
          d="M7.5 31.5C6.675 31.5 5.96875 31.2063 5.38125 30.6188C4.79375 30.0312 4.5 29.325 4.5 28.5V23.25C4.5 22.8358 4.83579 22.5 5.25 22.5H6.75C7.16421 22.5 7.5 22.8358 7.5 23.25V27.75C7.5 28.1642 7.83579 28.5 8.25 28.5H27.75C28.1642 28.5 28.5 28.1642 28.5 27.75V8.25C28.5 7.83579 28.1642 7.5 27.75 7.5H8.25C7.83579 7.5 7.5 7.83579 7.5 8.25V12.75C7.5 13.1642 7.16421 13.5 6.75 13.5H5.25C4.83579 13.5 4.5 13.1642 4.5 12.75V7.5C4.5 6.675 4.79375 5.96875 5.38125 5.38125C5.96875 4.79375 6.675 4.5 7.5 4.5H28.5C29.325 4.5 30.0312 4.79375 30.6188 5.38125C31.2063 5.96875 31.5 6.675 31.5 7.5V28.5C31.5 29.325 31.2063 30.0312 30.6188 30.6188C30.0312 31.2063 29.325 31.5 28.5 31.5H7.5ZM16.2897 24.9603C15.9932 25.2568 15.5111 25.2526 15.2198 24.9509L14.1619 23.8552C13.8777 23.5609 13.8818 23.0932 14.1711 22.8039L17.475 19.5H5.25C4.83579 19.5 4.5 19.1642 4.5 18.75V17.25C4.5 16.8358 4.83579 16.5 5.25 16.5H17.475L14.1711 13.1961C13.8818 12.9068 13.8777 12.4391 14.1619 12.1448L15.2198 11.0491C15.5111 10.7474 15.9932 10.7432 16.2897 11.0397L22.7197 17.4697C23.0126 17.7626 23.0126 18.2374 22.7197 18.5303L16.2897 24.9603Z"
          fill="#E01717"
        />
      </g>
    </svg>
  );
};

export default LeaveIcon;
