import React, { useEffect, useState } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import useStyles from "../ManageTransactionAndInvoicesOverview/ManageTransactionAndInvoicesOverview.styles";
import { fget } from "../../../../../API/callsApi";
import CommonLoader from "../../../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../../../ReusableComponents/NoDataFound/NoDataFound";
import TransactionSuccessfulTableItem from "./TransactionSuccessfulTableItem";
import useAuth from "../../../../../hooks/useAuth";
import CustomPagination from "../../../../ReusableComponents/CustomPagination/CustomPagination";

const TransactionSuccessfulTable = () => {
  const classes = useStyles();
  const { currentOrg } = useAuth();
  const [successfulTransactions, setSuccessfulTransactions] = useState({
    count: 0,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const getSuccessfullTransaction = async () => {
    setIsLoading(true);
    try {
      const res = await fget({
        url: `/transactions/?payment_status=successful&org=${currentOrg.id}&page=${currentPage}`,
      });
      if (res.status === 200 || res.status === 201) {
        setSuccessfulTransactions({
          count: res.data.count,
          data: res.data.results,
        });
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getSuccessfullTransaction();
  }, [currentOrg.id, updateOnPageChange]);

  return (
    <>
      <TableContainer style={{ height: "8%", overflow: "hidden" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                <span style={{ marginLeft: "10px" }}>Transaction ID</span>
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Transaction Date
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                License/ Invoice
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Type
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Amount
              </TableCell>
              <TableCell align="right" className={classes.tableRowHeadService}>
                <span style={{ marginRight: "40px" }}>Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box
        style={{
          height: successfulTransactions.count > 50 ? "80%" : "87%",
          overflowY: "auto",
          border: "1px solid black",
          borderRadius: "5px",
        }}
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody style={{ height: "100%" }}>
              {isLoading ? (
                <div className={classes.commonLoader}>
                  <CommonLoader />
                </div>
              ) : successfulTransactions &&
                successfulTransactions.data.length > 0 ? (
                successfulTransactions.data.map((eachTransaction) => {
                  return (
                    <TransactionSuccessfulTableItem
                      key={eachTransaction.id}
                      eachTransaction={eachTransaction}
                    />
                  );
                })
              ) : (
                <NoDataFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {successfulTransactions.count > 50 && (
        <footer style={{ width: "100%", position: "relative" }}>
          <CustomPagination
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalItem={successfulTransactions?.count}
            totalPage={
              successfulTransactions
                ? Math.ceil(successfulTransactions?.count / 50)
                : 0
            }
            type="Available Licenses"
          />
        </footer>
      )}
    </>
  );
};

export default TransactionSuccessfulTable;
