import React from "react";
import { Box, Button, Divider, Grid, Typography, Tooltip } from "@mui/material";
import { Info, X } from "phosphor-react";

const TransactionInfoModal = ({ closeModal, transactionInfo }) => {
  return (
    <Box
      p={4}
      style={{
        borderRadius: "10px",
        width: "600px",
        height: "500px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Info size={25} />
          <Typography variant="h2" fontWeight={600}>
            Transaction Info
          </Typography>
        </div>
        <Button onClick={closeModal}>
          <X color="#000000" size={25} />
        </Button>
      </div>
      <Box mt={3} style={{ maxHeight: "450px", overflowY: "auto" }}>
        <Grid container>
          <Grid item xs={5} color="#666666">
            Transaction ID
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            <Tooltip title={transactionInfo.checkout_id}>
              <Typography
                variant="h5"
                fontWeight={500}
                style={{
                  width: "150px",
                  overflow: "hidden",
                  whiteSpace: "nowrap",
                  textOverflow: "ellipsis",
                }}
              >
                {transactionInfo.checkout_id}
              </Typography>
            </Tooltip>
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            Transaction Date
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {new Date(transactionInfo.created_at).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            User
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {transactionInfo.customer.first_name}{" "}
            {transactionInfo.customer.last_name}
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            Email ID
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {transactionInfo.customer.email}
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            License/ Invoice
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {transactionInfo.product_name}
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            No. of Licenses
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {transactionInfo.quantity}
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            Type
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {transactionInfo?.licenses_data?.length > 0
              ? transactionInfo.licenses_data[0]?.license_type
              : "--"}
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            Amount
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            Rs. {transactionInfo.total_amount}
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={5} color="#666666">
            Status
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={5}>
            {transactionInfo.payment_status}
          </Grid>
        </Grid>
        <Grid container mt={4}>
          <Grid item xs={3} color="#666666">
            Licenses ID
          </Grid>
          <Grid item xs={3}>
            Type
          </Grid>
          <Grid item xs={2}>
            :
          </Grid>
          <Grid item xs={4}>
            Validity Date
          </Grid>
        </Grid>
        <Divider />

        {transactionInfo.licenses_data.map((eachLicense) => {
          return (
            <Grid key={eachLicense.id} container mt={1}>
              <Grid item xs={3} color="#666666">
                {eachLicense.id}
              </Grid>
              <Grid item xs={3}>
                {eachLicense.license_type}
              </Grid>
              <Grid item xs={2}>
                :
              </Grid>
              <Grid item xs={4}>
                {new Date(eachLicense.validity).toLocaleDateString("en-US", {
                  month: "long",
                  day: "numeric",
                  year: "numeric",
                })}
              </Grid>
            </Grid>
          );
        })}
      </Box>
    </Box>
  );
};

export default TransactionInfoModal;
