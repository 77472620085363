import React from "react";
import { Box, Typography, Divider } from "@mui/material";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import { Warning } from "phosphor-react";

const PaymentErrorModal = ({ closeModal, searchParams }) => {
  return (
    <Box
      style={{
        padding: "36px",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        width: "560px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "30px",
      }}
    >
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <Warning size={25} color="#E01717" />
        <Typography variant="h2" fontWeight={600}>
          Purchase Error
        </Typography>
        <div style={{ rotate: "90deg" }}></div>
      </div>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "flex-start",
          gap: "10px",
        }}
      >
        {/* <Grid container mt={3}>
          <Grid item xs={7} fontWeight={500}>
            Movie Colab
          </Grid>
          <Grid item xs={2} color="#666666" fontWeight={500}>
            Licenses
          </Grid>
          <Grid item xs={1} color="#666666" fontWeight={500}>
            :
          </Grid>
          <Grid item xs={2} color="#666666" fontWeight={500}>
            19
          </Grid>
        </Grid> */}
        <Divider flexItem />
        {/* <Grid container mt={1}>
          <Grid item xs={6} color="#E01717">
            License ID
          </Grid>
          <Grid item xs={6} color="rgba(224, 23, 23, 0.6)">
            Subscription Type : End Date
          </Grid>
        </Grid>
        <Grid container mt={1}>
          <Grid item xs={6} color="#E01717">
            License ID
          </Grid>
          <Grid item xs={6} color="rgba(224, 23, 23, 0.6)">
            Subscription Type : End Date
          </Grid>
        </Grid> */}
        {/* <Grid container mt={1}>
          <Grid item xs={6} color="#E01717">
            License ID
          </Grid>
          <Grid item xs={6} color="rgba(224, 23, 23, 0.6)">
            Subscription Type : End Date
          </Grid>
        </Grid> */}
        <Typography variant="h4" color="#E01717">
          *An error occurred while making the payment. *If the payment has gone
          through from your side, it will return back to the same account
          shortly.
        </Typography>
      </div>
      <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
        <div style={{ width: "200px" }}>
          <FilledButton
            text="Retry Purchase"
            submit={() => {
              searchParams.delete("paymentstatus");
              searchParams.delete("session_id");
              const currentPath = window.location.pathname.replace(/\/$/, "");
              const updatedUrl = `${currentPath}${searchParams.toString()}`;
              window.history.replaceState({ path: updatedUrl }, "", updatedUrl);
              closeModal();
            }}
          />
        </div>
      </div>
    </Box>
  );
};

export default PaymentErrorModal;
