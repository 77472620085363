import React from "react";

const MovieColabIcon = () => {
  return (
    <svg
      width="52"
      height="35"
      viewBox="0 0 52 35"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10 2.63135L25.921 18.3634L41.8419 2.63135V32.1048H34.9998V19.0046L25.921 27.9758L16.8421 19.0046V32.1048H10V2.63135Z"
        fill="#6C65D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 34.9998V0H6.84206V34.9998H0Z"
        fill="#6C65D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M44.9961 34.9998V0H51.8382V34.9998H44.9961Z"
        fill="#6C65D9"
      />
    </svg>
  );
};

export default MovieColabIcon;
