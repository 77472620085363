import { Box, Typography, TextField, CircularProgress } from "@mui/material";
import React, { useState } from "react";
import {
  FilledButton,
  OutlinedButton,
} from "../../../ReusableComponents/Buttons/Buttons";
import { fpatch } from "../../../../API/callsApi";
import useAuth from "../../../../hooks/useAuth";

const OrgNameChangeModal = ({
  close,
  orgInfo,
  getOrg,
  handleOrgActionModal,
}) => {
  const { getOrgList } = useAuth();
  const [oldName, setOldName] = useState("");
  const [newName, setNewName] = useState("");
  const [error, setError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const changeOrgName = async () => {
    if (oldName !== orgInfo.name) {
      setError(true);
      return;
    } else {
      setError(false);
      setIsLoading(true);
      try {
        const res = await fpatch({
          url: `/organization/${orgInfo.id}/`,
          data: {
            name: newName,
          },
        });
        if (res.status === 200 || res.status === 201) {
          setIsLoading(false);
          getOrgList();
          getOrg();
          close();
          handleOrgActionModal();
        }
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  return (
    <Box
      sx={{
        padding: "36px",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: "30px",
        width: "600px",
      }}
    >
      <>
        <Typography variant="h2" fontWeight={600}>
          Request Name Change
        </Typography>
        <Typography variant="h5" color="#666666" fontWeight={400}>
          Organization names can only be changed once every 30 days. Would you
          like to go ahead and request for one?
        </Typography>
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "24px",
          }}
        >
          <TextField
            label="Old Name"
            variant="outlined"
            value={oldName}
            onChange={(event) => setOldName(event.target.value)}
          />
          <TextField
            label="New Name"
            variant="outlined"
            value={newName}
            onChange={(event) => setNewName(event.target.value)}
          />
        </div>
        {error && (
          <Typography color="red">Add Correct current org name</Typography>
        )}
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "12px",
          }}
        >
          <div style={{ width: "200px" }}>
            <OutlinedButton text="Cancel" submit={close} />
          </div>
          <div style={{ width: "200px" }}>
            <FilledButton
              disabled={(!oldName && !newName) || isLoading}
              text={isLoading ? <CircularProgress /> : "Yes I'm sure!"}
              submit={changeOrgName}
            />
          </div>
        </div>
      </>
    </Box>
  );
};

export default OrgNameChangeModal;
