import React, { useState } from "react";
import {
  Box,
  Button,
  Typography,
  Grid,
  FormControl,
  Select,
  InputLabel,
  MenuItem,
} from "@mui/material";
import { Check, Info, PencilSimple, X } from "phosphor-react";
import { FilledButton } from "../../../ReusableComponents/Buttons/Buttons";
import { fpatch, fpost } from "../../../../API/callsApi";
import toast from "react-hot-toast";
import DefaultImg from "../../../../assets/defaultimg.png";

const ChangeUserRoleModal = ({
  closeModal,
  currentOrg,
  handleRefreshCounter,
  userInfo,
}) => {
  const [userRole, setUserRole] = useState(userInfo.role);

  const handleAddAsAdmin = async () => {
    const url = `/organization/${currentOrg.id}/register-admin/user/${userInfo.id}/`;
    try {
      const res = await fpatch({ url });
      if (res.status === 200 || res.status === 201) {
        toast.success("Made User an admin");
        closeModal();
        handleRefreshCounter();
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Error occured, user couldn't be added as admin");
    }
  };

  const addAsNormalUser = async () => {
    const url = `/organization/${currentOrg.id}/remove-admin/`;
    try {
      const res = await fpost({
        url,
        data: {
          user: userInfo.id,
        },
      });
      if (res.status === 200 || res.status === 201) {
        toast.success("Changed user role to User");
        closeModal();
        handleRefreshCounter();
      }
    } catch (error) {
      console.log("error", error);
      toast.error("Error occured,user role couldn't be changed");
    }
  };
  return (
    <Box
      style={{
        width: "600px",
        padding: "36px",
        borderRadius: "10px",
        backgroundColor: "#FFFFFF",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "15px" }}>
          <PencilSimple size={30} />
          <Typography variant="h2" fontWeight={600}>
            Edit Role
          </Typography>
        </div>
        <Button onClick={closeModal}>
          <X size={30} color="black" />
        </Button>
      </div>
      <Box
        mt={3}
        style={{ display: "flex", alignItems: "flex-start", gap: "24px" }}
      >
        <img
          src={userInfo.avatar ? userInfo.avatar : DefaultImg}
          style={{ height: "100px", width: "100px", borderRadius: "5px" }}
        />
        <Box style={{ width: "80%" }}>
          <Grid container>
            <Grid item xs={5} color="#666666">
              User
            </Grid>
            <Grid item xs={2}>
              :
            </Grid>
            <Grid item xs={5}>
              {userInfo.first_name} {userInfo.last_name}
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={5} color="#666666">
              Email ID
            </Grid>
            <Grid item xs={2}>
              :
            </Grid>
            <Grid item xs={5}>
              {userInfo.email}
            </Grid>
          </Grid>
          <Grid container mt={1}>
            <Grid item xs={5} color="#666666">
              Org Name
            </Grid>
            <Grid item xs={2}>
              :
            </Grid>
            <Grid item xs={5}>
              {currentOrg?.name}
            </Grid>
          </Grid>
          <Grid container mt={2} mb={2}>
            <FormControl size="small">
              <InputLabel
                style={{ padding: "0px 5px" }}
                id="demo-simple-select-helper-label-user"
              >
                User Role
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label-user"
                id="demo-simple-select-helper-label-user"
                variant="outlined"
                label="User Role"
                style={{ width: "150px", height: "100%" }}
                value={userRole}
                onChange={(event) => setUserRole(event.target.value)}
              >
                <MenuItem value="User">User</MenuItem>
                <MenuItem value="Admin">Admin</MenuItem>
              </Select>
            </FormControl>
            <Typography
              mt={1}
              variant="h6"
              color="#E01717"
              style={{ display: "flex", alignItems: "center", gap: "10px" }}
            >
              <Info color="#E01717" />
              {userRole === "User" &&
                "Changing to User removes administrator access to your org"}
              {userRole === "Admin" &&
                "Changing to Admin grants administrator access to your org"}
            </Typography>
          </Grid>
        </Box>
      </Box>

      <div style={{ width: "200px", marginLeft: "auto", marginRight: "auto" }}>
        <FilledButton
          icon={<Check />}
          disabled={userRole === userInfo.role}
          text="Save Changes"
          submit={() => {
            if (userRole === "Admin") {
              handleAddAsAdmin();
            } else if (userRole === "User") {
              addAsNormalUser();
            }
          }}
        />
      </div>
    </Box>
  );
};

export default ChangeUserRoleModal;
