import React from "react";

const NotFoundCharacter = () => {
  return (
    <svg
      width="446"
      height="204"
      viewBox="0 0 446 204"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="9"
        y="21"
        width="426"
        height="182"
        rx="30"
        fill="url(#paint0_linear_4093_19210)"
      />
      <path
        d="M166.758 65.5274C164.455 184.395 151.27 192.869 144.965 203.165H301.63C293.406 164.098 291.898 109.073 292.172 65.5274C293.68 43.685 283.62 0 231.316 0C179.012 0 166.484 43.685 166.758 65.5274Z"
        fill="black"
      />
      <path
        d="M205.018 145.83H257.517L260.798 203.163H200.799L205.018 145.83Z"
        fill="white"
      />
      <path
        d="M270.797 178.33C216.131 178.33 196.075 147.497 192.881 132.08L270.797 128.33V178.33Z"
        fill="black"
      />
      <path
        d="M288.359 76.7844C291.455 112.175 271.345 158.417 239.48 161.205C207.616 163.993 176.064 122.271 172.968 86.8798C169.871 51.4887 193.193 20.5386 225.057 17.7509C256.921 14.9631 285.263 41.3933 288.359 76.7844Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M229.241 138.784C229.419 138.365 229.894 138.177 230.301 138.363C233.836 139.98 238.756 139.55 241.957 137.344C242.325 137.09 242.826 137.193 243.074 137.574C243.323 137.955 243.226 138.47 242.857 138.724C239.199 141.246 233.695 141.727 229.654 139.879C229.247 139.692 229.062 139.202 229.241 138.784Z"
        fill="black"
      />
      <path
        d="M222.82 134.606C222.046 134.858 221.15 135.032 220.392 135.144C220.068 135.192 219.996 135.678 220.241 135.884C220.294 135.928 220.368 135.939 220.437 135.939C228.435 135.9 232.158 134.089 236.101 133.624L235.572 128.262C235.572 128.262 234.524 128.339 234.176 128.366C233.828 128.394 232.616 128.073 231.852 128.513C227.604 130.96 229.155 132.544 222.82 134.606Z"
        fill="black"
      />
      <path
        d="M248.75 132.337C249.557 132.451 250.469 132.466 251.235 132.446C251.562 132.437 251.718 132.903 251.512 133.148C251.467 133.201 251.397 133.224 251.328 133.236C243.445 134.587 239.464 133.45 235.5 133.677L235.09 128.304C235.09 128.304 236.136 128.198 236.483 128.165C236.831 128.131 237.969 127.605 238.798 127.906C243.406 129.578 242.153 131.407 248.75 132.337Z"
        fill="black"
      />
      <path
        d="M280.746 79.094C276.932 100.72 276.117 97.1943 285.738 98.8908C291.9 99.9772 301.126 93.9792 303.057 83.0281C304.988 72.0769 298.467 67.4088 292.306 66.3224C278.883 63.9556 283.527 63.3217 280.746 79.094Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M300.364 77.6987C299.781 74.2591 297.729 71.6165 294.794 70.6844C294.356 70.545 293.887 70.7877 293.748 71.2263C293.609 71.6649 293.851 72.1335 294.29 72.2728C296.518 72.9805 298.221 75.0284 298.721 77.9769C299.22 80.9241 298.499 84.7569 295.823 88.833C295.57 89.2177 295.677 89.7344 296.062 89.987C296.447 90.2395 296.963 90.1324 297.216 89.7477C300.075 85.3928 300.947 81.1397 300.364 77.6987Z"
        fill="black"
      />
      <path
        d="M180.41 87.8733C187.921 108.509 188.111 104.895 178.931 108.236C173.052 110.376 162.924 106.071 159.121 95.622C155.317 85.1726 160.929 79.4429 166.808 77.3031C179.616 72.6414 174.932 72.8236 180.41 87.8733Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M160.845 89.9055C160.822 86.417 162.384 83.4582 165.112 82.0306C165.52 81.8172 166.023 81.9748 166.237 82.3826C166.45 82.7904 166.293 83.2939 165.885 83.5073C163.814 84.5912 162.491 86.9037 162.512 89.8942C162.532 92.8833 163.908 96.5326 167.251 100.082C167.566 100.417 167.551 100.945 167.216 101.26C166.881 101.576 166.353 101.56 166.038 101.225C162.466 97.4326 160.869 93.3954 160.845 89.9055Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M231.464 86.2049C232.152 86.1447 232.758 86.6535 232.818 87.3412L234.852 110.585C234.912 111.273 234.403 111.879 233.715 111.94C233.028 112 232.421 111.491 232.361 110.803L230.328 87.5591C230.268 86.8714 230.776 86.2651 231.464 86.2049Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M225.469 113.863C225.913 113.334 226.701 113.266 227.23 113.709L230.841 116.739C231.37 117.183 231.439 117.972 230.995 118.5C230.551 119.029 229.763 119.098 229.234 118.654L225.623 115.624C225.094 115.181 225.025 114.392 225.469 113.863Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M242.173 112.402C241.644 111.958 240.856 112.027 240.412 112.556L237.382 116.167C236.938 116.696 237.007 117.485 237.536 117.928C238.065 118.372 238.853 118.303 239.297 117.774L242.327 114.163C242.771 113.634 242.702 112.846 242.173 112.402Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M186.07 83.5377L186.578 82.3953C185.97 82.1255 185.258 82.3782 184.956 82.9706C184.655 83.5629 184.869 84.2876 185.445 84.6201L189.061 86.7085C190.513 87.7192 192.057 89.0382 193.11 90.5837C193.499 91.154 194.277 91.3011 194.847 90.9122C195.418 90.5233 195.565 89.7457 195.176 89.1753C194.472 88.1428 193.612 87.2189 192.716 86.4125C195.724 83.3703 199.894 80.9294 204.397 80.7404C209.199 80.5388 214.71 82.8877 219.89 90.5839C220.276 91.1566 221.052 91.3083 221.625 90.9228C222.198 90.5374 222.349 89.7606 221.964 89.1879C216.429 80.9644 210.173 77.9958 204.292 78.2426C198.898 78.4691 194.106 81.3931 190.781 84.8153L190.401 84.5953C190.188 84.4485 189.979 84.3093 189.776 84.1777C188.897 83.6066 188.105 83.165 187.532 82.8651C187.245 82.7149 187.011 82.5997 186.847 82.5209C186.765 82.4816 186.7 82.4513 186.654 82.4303L186.601 82.4058L186.585 82.3988L186.58 82.3966L186.579 82.3958C186.578 82.3957 186.578 82.3953 186.07 83.5377Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M201.552 80.8517C201.261 81.6399 201.167 82.6646 201.33 84.0059C201.9 88.7024 204.887 92.2036 208 91.8254C211.114 91.4472 213.144 87.6995 212.573 83.003C212.556 82.8643 212.538 82.7291 212.518 82.5975C208.643 80.2238 204.736 80.0606 201.552 80.8517Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M184.456 73.9314C184.216 72.5716 185.124 71.275 186.483 71.0352L217.669 65.5364C219.028 65.2967 220.325 66.2046 220.565 67.5643C220.805 68.924 219.897 70.2207 218.537 70.4604L187.352 75.9592C185.992 76.199 184.695 75.2911 184.456 73.9314Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M275.718 75.693L275.02 74.6561C275.571 74.2849 276.317 74.4101 276.717 74.9411C277.116 75.4721 277.031 76.2231 276.522 76.6504L273.323 79.335C272.069 80.5826 270.778 82.1495 270.008 83.8545C269.724 84.4837 268.984 84.7636 268.355 84.4797C267.725 84.1957 267.446 83.4554 267.729 82.8262C268.244 81.6871 268.93 80.6279 269.672 79.6782C266.181 77.2046 261.651 75.525 257.184 76.1207C252.42 76.756 247.401 80.0262 243.635 88.5049C243.355 89.1358 242.617 89.4202 241.986 89.14C241.355 88.8598 241.07 88.1212 241.351 87.4903C245.374 78.4306 251.019 74.4207 256.853 73.6427C262.204 72.929 267.432 74.9764 271.3 77.7693L271.637 77.4865C271.821 77.3051 272.002 77.1317 272.179 76.9669C272.946 76.2517 273.649 75.6793 274.162 75.2844C274.418 75.0867 274.628 74.9326 274.776 74.8266C274.85 74.7736 274.909 74.7325 274.95 74.7039L274.999 74.6705L275.013 74.6609L275.017 74.6579L275.019 74.6568C275.019 74.6567 275.02 74.6561 275.718 75.693Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M263.825 76.5236C263.988 77.0795 264.095 77.7355 264.136 78.5087C264.39 83.2329 262.057 87.1996 258.925 87.3678C255.793 87.536 253.143 84.1977 252.889 79.4735C252.837 78.5083 252.892 77.6994 253.038 77.0217C256.941 75.1893 260.954 75.503 263.825 76.5236Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M239.243 62.7732C239.003 64.133 239.911 65.4296 241.271 65.6693L272.456 71.1681C273.816 71.4079 275.112 70.5 275.352 69.1403C275.592 67.7805 274.684 66.4839 273.324 66.2442L242.139 60.7454C240.779 60.5056 239.482 61.4135 239.243 62.7732Z"
        fill="black"
      />
      <path
        d="M184.967 63.4172L174.51 64.3322C173.894 78.8409 179.903 117.732 182.077 133.603C177.237 119.571 169.745 88.2556 168.728 76.6334L170.56 59.3248L189.295 29.6626L213.183 15.8616L254.603 16.0021L280.767 39.9787L286.963 51.2321L290.2 64.333C290.734 84.7807 291.68 118.827 290.367 132.493L282.075 54.9214L264.147 56.4899C260.457 55.6082 249.438 43.2216 244.389 37.1385C244.808 41.9202 252.756 52.4675 256.677 57.1434L243.232 58.3198L231.597 45.7862L240.244 58.5812L226.798 59.7575L213.147 41.3776C214.088 52.1363 219.739 59.3713 222.447 61.6439C217.457 59.6713 212.437 50.4739 210.551 46.1218L202.895 61.8488L190.943 62.8944C190.211 54.5265 192.493 46.196 193.726 43.0767L184.967 63.4172Z"
        fill="black"
      />
      <path
        d="M70.8589 138.297L81.0868 146.046L90.1582 147.134L85.8039 165.64L55.3238 166.366L33.9152 138.426C19.1106 133.201 20.7314 127.298 23.3923 125L36.7075 131.145C37.2626 131.402 37.849 131.584 38.4515 131.688L66.9533 136.602C68.372 136.847 69.7113 137.428 70.8589 138.297Z"
        fill="black"
      />
      <path
        d="M0.807679 140.992L35.5917 166.477C36.7746 167.344 38.2784 167.648 39.705 167.308L45.0763 166.029L44.35 162.764L14.6824 141.832C7.38263 136.538 2.90627 139.027 0.807679 140.992Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.922997 141.074L0.807819 140.989C2.90641 139.024 7.38277 136.536 14.6826 141.83L33.6474 155.21L41.901 162.888L9.96948 141.328C9.96948 141.328 5.5074 138.239 0.922997 141.074Z"
        fill="black"
      />
      <path
        d="M46.4588 167.839L53.2114 167.579L31.1482 141.596C25.6603 134.44 19.145 135.222 16.5733 136.508L42.5042 166.135C43.4979 167.271 44.9511 167.897 46.4588 167.839Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.0175 136.31C19.8368 135.164 25.9528 134.824 31.1482 141.598L51.8379 165.964L51.6935 166.368L27.0191 140.605C27.0191 140.605 21.9973 135.043 17.0175 136.31Z"
        fill="black"
      />
      <path
        d="M57.3018 172.076L64.4971 171.819L44.8026 145.32C39.8677 137.773 33.6749 137.699 31.014 138.788L53.0396 169.964C54.0131 171.342 55.6158 172.136 57.3018 172.076Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M31.4673 138.624C34.33 137.728 40.1291 138.175 44.8009 145.32L56.916 161.621L56.4131 161.649L40.8102 142.78C40.8102 142.78 36.1297 138.609 31.4673 138.624Z"
        fill="black"
      />
      <path
        d="M60.1345 169.206L44.8016 142.055C50.317 138.571 54.8407 143.506 56.4131 146.409L64.9721 160.104C65.2579 160.561 65.8546 160.709 66.3212 160.439L86.1674 148.949C93.1343 143.724 101.77 150.763 105.399 158.021L127.533 203.741H95.9646L85.6956 174.921C85.112 173.283 83.5368 172.21 81.7988 172.266L66.4553 172.761C63.8511 172.845 61.4157 171.475 60.1345 169.206Z"
        fill="white"
      />
      <path
        d="M375.139 138.297L364.911 146.046L355.84 147.134L360.194 165.64L390.674 166.366L412.083 138.426C426.887 133.201 425.267 127.298 422.606 125L409.291 131.145C408.735 131.402 408.149 131.584 407.547 131.688L379.045 136.602C377.626 136.847 376.287 137.428 375.139 138.297Z"
        fill="black"
      />
      <path
        d="M445.19 140.992L410.406 166.477C409.223 167.344 407.72 167.648 406.293 167.308L400.922 166.029L401.648 162.764L431.316 141.832C438.615 136.538 443.092 139.027 445.19 140.992Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M445.075 141.074L445.19 140.989C443.092 139.024 438.615 136.536 431.315 141.83L412.351 155.21L404.097 162.888L436.029 141.328C436.029 141.328 440.491 138.239 445.075 141.074Z"
        fill="black"
      />
      <path
        d="M399.539 167.839L392.787 167.579L414.85 141.596C420.338 134.44 426.853 135.222 429.425 136.508L403.494 166.135C402.5 167.271 401.047 167.897 399.539 167.839Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M428.981 136.31C426.161 135.164 420.045 134.824 414.85 141.598L394.16 165.964L394.305 166.368L418.979 140.605C418.979 140.605 424.001 135.043 428.981 136.31Z"
        fill="black"
      />
      <path
        d="M388.696 172.076L381.501 171.819L401.195 145.32C406.13 137.773 412.323 137.699 414.984 138.788L392.958 169.964C391.985 171.342 390.382 172.136 388.696 172.076Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M414.531 138.624C411.668 137.728 405.869 138.175 401.197 145.32L389.082 161.621L389.585 161.649L405.188 142.78C405.188 142.78 409.868 138.609 414.531 138.624Z"
        fill="black"
      />
      <path
        d="M385.864 169.206L401.196 142.055C395.681 138.571 391.157 143.506 389.585 146.409L381.026 160.104C380.74 160.561 380.143 160.709 379.677 160.439L359.831 148.949C352.864 143.724 344.228 150.763 340.599 158.021L318.465 203.741H350.033L360.302 174.921C360.886 173.283 362.461 172.21 364.199 172.266L379.543 172.761C382.147 172.845 384.582 171.475 385.864 169.206Z"
        fill="white"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4093_19210"
          x1="239.75"
          y1="21"
          x2="241.18"
          y2="194.685"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BFFFB7" />
          <stop offset="0.315" stopColor="#63B4FF" />
          <stop offset="0.64" stopColor="#564ECA" />
          <stop offset="1" stopColor="#232058" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NotFoundCharacter;
