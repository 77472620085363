import React from "react";

const ConfettiIcon = () => {
  return (
    <svg
      width="36"
      height="43"
      viewBox="0 0 36 43"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.74392 9.80631C1.11599 16.6258 6.66879 22.0895 9.89868 23.9689L17.5582 5.14229C14.7984 3.85552 8.37184 2.98684 4.74392 9.80631Z"
        fill="#FFCC4D"
      />
      <ellipse
        cx="14.086"
        cy="14.7233"
        rx="10.076"
        ry="5.03801"
        transform="rotate(-65 14.086 14.7233)"
        fill="#FFAC33"
      />
      <path
        d="M17.2457 30.6553C17.0476 30.0822 17.7245 29.6082 18.1953 29.9904C18.4478 30.1954 18.8175 30.1631 19.0306 29.9173C19.4278 29.4592 20.1768 29.8084 20.0812 30.4072C20.0299 30.7284 20.2427 31.0324 20.5621 31.0941C21.1575 31.209 21.2295 32.0323 20.6631 32.2489C20.3593 32.3651 20.2025 32.7014 20.3088 33.0088C20.5069 33.5819 19.83 34.0559 19.3592 33.6737C19.1067 33.4686 18.737 33.501 18.5239 33.7468C18.1267 34.2049 17.3777 33.8557 17.4733 33.2569C17.5246 32.9356 17.3118 32.6317 16.9924 32.57C16.397 32.4551 16.325 31.6318 16.8914 31.4152C17.1952 31.299 17.352 30.9627 17.2457 30.6553Z"
        fill="#FF5050"
      />
      <path
        d="M19.3976 11.415C19.6035 10.9914 20.2355 11.1029 20.2842 11.5713C20.3103 11.8226 20.531 12.0078 20.783 11.9899C21.2528 11.9565 21.4723 12.5595 21.0909 12.8359C20.8864 12.9841 20.8363 13.2679 20.9778 13.4772C21.2417 13.8673 20.8291 14.3589 20.3992 14.1668C20.1685 14.0638 19.8977 14.1623 19.7872 14.3895C19.5813 14.8131 18.9493 14.7016 18.9007 14.2332C18.8746 13.9819 18.6538 13.7967 18.4018 13.8146C17.9321 13.8481 17.7126 13.245 18.0939 12.9687C18.2985 12.8204 18.3485 12.5366 18.207 12.3274C17.9431 11.9373 18.3557 11.4456 18.7857 11.6377C19.0163 11.7407 19.2871 11.6422 19.3976 11.415Z"
        fill="#252A38"
      />
      <path
        d="M30.8538 30.9283C31.0406 30.544 31.6142 30.6451 31.6583 31.0702C31.682 31.2982 31.8823 31.4663 32.111 31.45C32.5372 31.4196 32.7364 31.9669 32.3904 32.2176C32.2047 32.3522 32.1593 32.6097 32.2878 32.7996C32.5272 33.1536 32.1528 33.5997 31.7626 33.4254C31.5533 33.3319 31.3076 33.4213 31.2074 33.6275C31.0205 34.0118 30.447 33.9107 30.4029 33.4856C30.3792 33.2576 30.1789 33.0895 29.9502 33.1058C29.5239 33.1361 29.3248 32.5889 29.6708 32.3381C29.8564 32.2036 29.9018 31.9461 29.7734 31.7562C29.534 31.4022 29.9083 30.9561 30.2985 31.1304C30.5078 31.2239 30.7536 31.1345 30.8538 30.9283Z"
        fill="#2C93DC"
      />
      <path
        d="M23.7461 16.5942C24.251 16.6643 24.7287 16.8424 25.2418 16.9015"
        stroke="#2C93DC"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.7615 31.3672C12.6112 31.9481 12.1167 32.5258 11.7621 33.0083"
        stroke="#2C93DC"
        strokeLinecap="round"
      />
      <path
        d="M29.4375 24.4634C30.1265 24.8113 30.7378 25.2904 31.4381 25.617"
        stroke="#FF5050"
        strokeLinecap="round"
      />
      <path
        d="M24.6973 36.6008C24.633 37.2879 24.5253 38.0722 24.6296 38.7545"
        stroke="#252A38"
        strokeWidth="2"
        strokeLinecap="round"
      />
      <path
        d="M12.3555 20.7051C13.207 22.2396 14.0113 23.7998 14.849 25.3408"
        stroke="#FF5050"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M14.1289 17.7251C16.3397 17.7734 18.4919 19.357 19.3379 21.327C19.7511 22.2895 19.8111 23.6075 20.4352 24.462C21.15 25.4406 22.5986 25.2392 23.6314 25.1313"
        stroke="#2C93DC"
        strokeWidth="2"
        strokeLinecap="round"
      />
    </svg>
  );
};
export default ConfettiIcon;
