import React, { useEffect, useState, useRef } from "react";
import { useFormik } from "formik";
import {
  Grid,
  TextField,
  Modal,
  Box,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { Pencil, PencilSimple, Trash, X } from "phosphor-react";
import {
  FilledButton,
  RedFilledButton,
} from "../../ReusableComponents/Buttons/Buttons";
import DeleteModal from "../../ReusableComponents/DeleteModal/DeleteModal";
import useStyles from "../DashBoardOverview/DashBoard.styles";
import useAuth from "../../../hooks/useAuth";
import { fpatch } from "../../../API/callsApi";
import toast from "react-hot-toast";
import DefaultImg from "../../../assets/defaultimg.png";
import PhoneInput, {
  isValidPhoneNumber,
  // getCountryCallingCode,
  parsePhoneNumber,
} from "react-phone-number-input";
import "react-phone-number-input/style.css";

const MyProfile = () => {
  const phoneRef = useRef();
  const classes = useStyles();
  const { userData, loadUserData } = useAuth();
  const [loading, setLoading] = useState(false);
  const [userProfile, setUserProfile] = useState("");
  const [newImage, setNewImage] = useState("");
  const [isDeleteModalOpen, setDeleteModalOpen] = useState(false);
  const [isResetPasswordModalOpen, setResetPasswordModalOpen] = useState(false);
  const [isEditing, setIsEditing] = useState(false);
  const [country, setCountry] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");

  const handleDeleteOpen = () => {
    setDeleteModalOpen(!isDeleteModalOpen);
  };

  const handleResetPassowrdModal = () => {
    setResetPasswordModalOpen(!isResetPasswordModalOpen);
  };

  useEffect(() => {
    if (userData) {
      setUserProfile(userData.avatar);
      if (userData.phone_number?.raw) {
        const phoneNumberData = parsePhoneNumber(userData.phone_number?.raw);
        setPhoneNumber(phoneNumberData.number);
        setCountry(phoneNumberData.country);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (phoneRef && phoneRef.current) {
      phoneRef.current.style.height = "40px";
      phoneRef.current.style.paddingLeft = "10px";
    }
  }, [phoneRef]);

  const formik = useFormik({
    initialValues: {
      fname: userData ? userData.first_name : "",
      lname: userData ? userData.last_name : "",
      email: userData ? userData.email : "",
    },

    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("first_name", values.fname);
      formData.append("last_name", values.lname);
      if (country && phoneNumber) {
        if (isValidPhoneNumber(phoneNumber)) {
          formData.append("phone_number", `${phoneNumber}`);
        } else {
          setLoading(false);
          toast.error("phone number not valid");
          return;
        }
      }
      newImage && formData.append("avatar", newImage);
      formData.append("email", values.email);
      let obj = {
        url: "/user/",
        data: formData,
      };
      try {
        fpatch(obj).then(
          (res) => {
            if (res.status === 200 || res.status === 201) {
              toast.success("Successfully Saved");
              loadUserData();
              setLoading(false);
              formik.resetForm({
                values: {
                  fname: res.data.first_name,
                  lname: res.data.last_name,
                  code: `+${res.data.phone_number?.country_code}`,
                  phone: res.data.phone_number?.number.toString(),
                  email: res.data.email,
                },
              });
              setUserProfile(res.data.avatar);
              setNewImage("");
              setIsEditing(false);
            }
          },
          () => {
            setLoading(false);
          }
        );
      } catch (error) {
        setLoading(false);
        toast.error("oops, something went wrong, try again");
      }
    },
  });

  const handleCancel = () => {
    formik.resetForm({
      values: {
        fname: userData ? userData.first_name : "",
        lname: userData ? userData.last_name : "",
        code: userData ? `+${userData.phone_number?.country_code}` : "",
        phone: userData ? userData.phone_number?.number.toString() : "",
        email: userData ? userData.email : "",
      },
    });
    setNewImage("");
  };

  const resetPassWord = async () => {
    let obj = {
      url: "/reset-password/",
      data: {
        email: userData.email,
      },
    };
    try {
      const res = await fpatch(obj);
      if (res.status === 200 || res.status === 201) {
        handleResetPassowrdModal();
      }
    } catch (error) {
      toast.error("The email you entered is incorrect!");
    }
  };

  return (
    <div
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        alignItems: "flex-start",
        gap: "15px",
      }}
    >
      <div
        style={{
          position: "relative",
          height: "175px",
          width: "175px",
          border: "2px solid black",
          borderRadius: "5px",
        }}
      >
        {userProfile || newImage ? (
          <img
            src={newImage ? URL.createObjectURL(newImage) : userProfile}
            alt="Profile"
            style={{
              position: "relative",
              height: "175px",
              width: "175px",
              borderRadius: "5px",
            }}
          />
        ) : (
          <img src={DefaultImg} alt="profilepic" width="175" height="175" />
        )}

        {isEditing && (
          <label
            style={{
              right: "0",
              bottom: "0",
              position: "absolute",
              cursor: "pointer",
              height: "45px",
              width: "45px",
              backgroundColor: "black",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: "5px 0px",
            }}
          >
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                // setShowBtn(true);
                setNewImage(e.target.files[0]);
              }}
              style={{
                display: "none",
              }}
              multiple="false"
            />
            <PencilSimple color="#FFFFFF" size={30} />
          </label>
        )}
      </div>
      <form style={{ width: "100%" }} onSubmit={formik.handleSubmit}>
        <div style={{ width: "80%" }}>
          <Grid container spacing={2}>
            <Grid
              item
              xs={5}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <TextField
                InputProps={{
                  readOnly: !isEditing,
                }}
                name="fname"
                id="fname"
                value={formik.values.fname}
                onChange={formik.handleChange}
                required
                variant="outlined"
                label="First Name"
                fullWidth
              />
              <TextField
                InputProps={{
                  readOnly: !isEditing,
                }}
                value={formik.values.email}
                required
                name="email"
                id="email"
                onChange={formik.handleChange}
                variant="outlined"
                label="Email ID"
                fullWidth
              />

              <Typography
                variant="h5"
                onClick={resetPassWord}
                style={{
                  color: "#666666",
                  cursor: "pointer",
                  textDecoration: "underline",
                }}
              >
                Reset Password
              </Typography>
            </Grid>
            <Grid
              item
              xs={5}
              style={{
                display: "flex",
                flexDirection: "column",
                gap: "20px",
              }}
            >
              <TextField
                InputProps={{
                  readOnly: !isEditing,
                }}
                value={formik.values.lname}
                required
                variant="outlined"
                label="Last Name"
                fullWidth
                name="lname"
                id="lname"
                onChange={formik.handleChange}
              />
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <PhoneInput
                    placeholder="Enter phone number"
                    value={phoneNumber}
                    onChange={setPhoneNumber}
                    style={{
                      width: "100%",
                      height: "54px",
                    }}
                    disabled={!isEditing}
                    ref={phoneRef}
                    onCountryChange={(value) => {
                      setCountry(value);
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: "20px",
              marginTop: "20px",
            }}
          >
            <div style={{ width: "195px" }}>
              {isEditing ? (
                <FilledButton
                  text={
                    loading ? (
                      <CircularProgress style={{ color: "white" }} />
                    ) : (
                      "Save Changes"
                    )
                  }
                  submit={() => formik.submitForm()}
                  icon={<Pencil size={18} />}
                  disabled={loading}
                />
              ) : (
                <FilledButton
                  text="Edit Account"
                  submit={() => setIsEditing(true)}
                  icon={<Pencil size={18} />}
                />
              )}
            </div>
            <div style={{ width: "195px" }}>
              {isEditing ? (
                <RedFilledButton
                  text="Cancel"
                  submit={() => {
                    setIsEditing(false);
                    handleCancel();
                  }}
                  icon={<Trash size={18} />}
                />
              ) : (
                <RedFilledButton
                  text="Delete Account"
                  submit={handleDeleteOpen}
                  icon={<Trash size={18} />}
                />
              )}
            </div>
          </div>
        </div>
      </form>
      <Modal
        open={isDeleteModalOpen}
        className={classes.centerContainer}
        onClose={handleDeleteOpen}
      >
        <DeleteModal
          handleDelete={() => console.log("delete")}
          closeModal={handleDeleteOpen}
          text1="Are you sure you want to delete your account?"
          btnText="Delete Account"
          isRemoving={false}
        />
      </Modal>
      <Modal
        open={isResetPasswordModalOpen}
        className={classes.centerContainer}
        onClose={handleResetPassowrdModal}
      >
        <Box
          style={{
            width: "500px",
            backgroundColor: "white",
            borderRadius: "10px",
            padding: "35px",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div
            style={{
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
            }}
          >
            <Typography variant="h2" style={{ fontWeight: "600" }}>
              Reset Password
            </Typography>
            <Button onClick={handleResetPassowrdModal}>
              <X size={20} />
            </Button>
          </div>
          <Typography variant="h5" style={{ margin: "30px 0px" }}>
            A link to reset your password has been sent to your email
          </Typography>
          <Typography variant="h5" color="#666666">
            {userData.email}
          </Typography>
        </Box>
      </Modal>
    </div>
  );
};

export default MyProfile;
