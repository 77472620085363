import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from "@mui/material";
import MyServicesTable from "../MyServicesTable/MyServicesTable";
import MyInvitationsTable from "../MyInvitationTable/MyInvitationsTable";
import MyOrganizationsTable from "../MyOrganizationTable/MyOrganizations";
import MyProfile from "../MyProfile/MyProfile";
import { User, Headset, Users } from "phosphor-react";
import ToolBoxIcon from "../../ReusableComponents/Svgs/ToolBoxIcon";
import InvitationPlus from "../../ReusableComponents/Svgs/InvitationPlus";
import useStyles from "./DashBoard.styles";
import useAuth from "../../../hooks/useAuth";

const DashBoardOverview = () => {
  const classes = useStyles();
  const history = useHistory();
  const { organizationsList, currentGreeting, userData } = useAuth();
  const [currentListItem, setCurrentListItem] = useState("services");
  const searchParams = new URLSearchParams(
    history.location.search.substring(1)
  );

  useEffect(() => {
    searchParams.get("dashboardType") &&
      setCurrentListItem(searchParams.get("dashboardType"));
  }, [searchParams.get("dashboardType")]);

  return (
    <Box boxSizing="border-box" px={4} py={3} style={{ height: "100%" }}>
      <Typography variant="h1">
        {currentGreeting},{" "}
        <span style={{ fontWeight: "600" }}>
          {JSON.stringify(userData) !== "{}" &&
            userData.first_name.slice(0, 1).toUpperCase() +
              userData.first_name.slice(1)}{" "}
          {JSON.stringify(userData) !== "{}" &&
            userData.last_name.slice(0, 1).toUpperCase() +
              userData.last_name.slice(1)}
        </span>
      </Typography>
      <Grid container spacing={3} className={classes.container}>
        <Grid item xs={3} lg={2}>
          <Box className={classes.listContainer}>
            <List style={{ padding: "0px", height: "max-content" }}>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("dashboardType", "services");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  currentListItem === "services"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemIcon className={classes.listIcon}>
                  <ToolBoxIcon
                    color={
                      currentListItem === "services" ? "#FFFFFF" : "#000000"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="My Services" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("dashboardType", "organizations");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  currentListItem === "organizations"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemIcon className={classes.listIcon}>
                  <Users
                    size={20}
                    color={
                      currentListItem === "organizations"
                        ? "#FFFFFF"
                        : "#000000"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="My Organizations" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("dashboardType", "invitations");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  currentListItem === "invitations"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemIcon className={classes.listIcon}>
                  <InvitationPlus
                    size={20}
                    color={
                      currentListItem === "invitations" ? "#FFFFFF" : "#000000"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="My Invitations" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("dashboardType", "myprofile");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  currentListItem === "myprofile"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemIcon className={classes.listIcon}>
                  <User
                    size={20}
                    color={
                      currentListItem === "myprofile" ? "#FFFFFF" : "#000000"
                    }
                  />
                </ListItemIcon>
                <ListItemText primary="My Profile" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("dashboardType", "myprofile");
                  history.push({ search: searchParams.toString() });
                }}
              >
                <ListItemIcon className={classes.listIcon}>
                  <Headset size={20} color="#000000" />
                </ListItemIcon>
                <ListItemText primary="Contact Support" />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={9} lg={10} style={{ height: "100%" }}>
          {currentListItem === "services" && (
            <MyServicesTable organizationsList={organizationsList} />
          )}
          {currentListItem === "invitations" && <MyInvitationsTable />}
          {currentListItem === "organizations" && (
            <MyOrganizationsTable organizationsList={organizationsList} />
          )}
          {currentListItem === "myprofile" && <MyProfile />}
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashBoardOverview;
