import React from "react";
import {
  // Select,
  // MenuItem,
  TextField,
  InputAdornment,
  // FormControl,
  // InputLabel,
  Box,
} from "@mui/material";
import { MagnifyingGlass } from "phosphor-react";
import TransactionSuccessfulTable from "../TransactionSuccessfulTable/TransactionSuccessfulTable";
import TransactionFailedTable from "../TransactionFailedTable/TransactionFailedTable";

const ManageTransactionAndInvoicesOverview = ({ transactionType }) => {
  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
        }}
      >
        <TextField
          size="small"
          label="search"
          variant="outlined"
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MagnifyingGlass size={22} />
              </InputAdornment>
            ),
          }}
        />
        {/* <FormControl size="small">
          <InputLabel
            style={{ padding: "0px 5px" }}
            id="demo-simple-select-helper-label-org"
          >
            Type
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            variant="outlined"
            label="Organization"
            style={{ width: "150px", height: "100%" }}
          >
            <MenuItem value={1}>All</MenuItem>
            <MenuItem value={2}>All</MenuItem>
            <MenuItem value={3}>All</MenuItem>
          </Select>
        </FormControl> */}
        {/* <FormControl size="small">
          <InputLabel
            style={{ padding: "0px 5px" }}
            id="demo-simple-select-helper-label-org"
          >
            Transaction Status
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label"
            variant="outlined"
            label="Status"
            style={{ width: "250px", height: "100%" }}
          >
            <MenuItem>All</MenuItem>
            <MenuItem>All</MenuItem>
            <MenuItem>All</MenuItem>
          </Select>
        </FormControl> */}
      </div>
      <Box mt={1} sx={{ height: "100%" }}>
        {transactionType === "successful" && <TransactionSuccessfulTable />}
        {transactionType === "failed" && <TransactionFailedTable />}
      </Box>
    </>
  );
};

export default ManageTransactionAndInvoicesOverview;
