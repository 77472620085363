import React from "react";

const NoDataFound = () => {
  return (
    <div
      style={{
        height: "100%",
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
      }}
    >
      <svg
        width="100"
        height="100"
        viewBox="0 0 213 213"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <line
          x1="15"
          y1="198.858"
          x2="198.858"
          y2="15"
          stroke="#000000"
          strokeWidth="20"
          strokeLinecap="round"
        />
        <path
          d="M105.417 182.083C147.758 182.083 182.083 147.758 182.083 105.417C182.083 63.0748 147.758 28.75 105.417 28.75C63.0748 28.75 28.75 63.0748 28.75 105.417C28.75 147.758 63.0748 182.083 105.417 182.083Z"
          stroke="#000000"
          strokeWidth="19.1667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M201.249 201.25L159.562 159.563"
          stroke="#000000"
          strokeWidth="19.1667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
      <h1 style={{ color: "rgba(0, 0, 0, 0.6)" }}>No Result Found</h1>
    </div>
  );
};

export default NoDataFound;
