import React, { useState, useEffect } from "react";
import { Box, Typography, TextField, CircularProgress } from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import * as yup from "yup";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import { Headset } from "phosphor-react";
import { useFormik } from "formik";
import { fpatch } from "../../../API/callsApi";
import { toast } from "react-hot-toast";
import NewForgotPassword from "./NewForgotPassword";
import { readCookie } from "../../../authorization";
import useStyles from "../NewLogin/NewLogin.styles";

const NewForgotPasswordForm = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [linkSentSuccess, setLinkSuccess] = useState(false);

  const Schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
  });

  useEffect(() => {
    let data = readCookie("refresh");
    if (data) {
      history.push("/dashboard/?dashboardType=services");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
    },
    validationSchema: Schema,

    onSubmit: async (values) => {
      setLoading(true);
      let obj = {
        url: "/reset-password/",
        data: {
          email: values.email,
        },
      };
      try {
        const res = await fpatch(obj);
        if (res.status === 200 || res.status === 201) {
          toast.success("Email sent");
          setLoading(false);
          setLinkSuccess(true);
        }
      } catch (error) {
        setLoading(false);
        setLinkSuccess(false);
        toast.error("The email you entered is incorrect!");
      }
    },
  });

  if (linkSentSuccess) {
    return <NewForgotPassword email={formik.values.email} />;
  }

  return (
    <Box
      px={16}
      py={8}
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <form style={{ height: "100%" }} onSubmit={formik.handleSubmit}>
        <Box
          gap={4}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h1" fontWeight={600}>
            Forgot Password
          </Typography>

          <TextField
            required
            type="email"
            label="Email ID"
            id="email"
            name="email"
            className={classes.inputContainer}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          {formik.touched.email && formik.errors.email && (
            <Typography variant="h6" color="red">
              {formik.errors.email}
            </Typography>
          )}
          <div className={classes.gapContainer}>
            <div style={{ width: "250px" }}>
              <FilledButton
                submit={() => formik.submitForm()}
                text={
                  loading ? (
                    <CircularProgress
                      width="20"
                      height="20"
                      style={{ color: "white" }}
                    />
                  ) : (
                    "Submit"
                  )
                }
              />
            </div>
          </div>
          <Link to="/login">
            <Typography color="#666666" variant="h6">
              Want to try logging in? Login
            </Typography>
          </Link>
        </Box>
      </form>
      <div className={classes.footer}>
        <Headset /> Need help? Contact{" "}
        <Link to="#">
          <span style={{ color: "#666666" }}>support@vigastudios.com</span>
        </Link>
      </div>
    </Box>
  );
};

export default NewForgotPasswordForm;
