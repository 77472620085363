import React from "react";

const AdminUserIcon = ({ size }) => {
  return (
    <svg
      width={size === "small" ? "22" : "37"}
      height={size === "small" ? "22" : "37"}
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.874 13.5C10.874 9.15076 14.3998 5.625 18.749 5.625C22.9505 5.625 26.3834 8.91517 26.6119 13.0595C27.0962 13.0428 27.5827 13.1141 28.0473 13.2733L28.8739 13.5567C28.874 13.5378 28.874 13.5189 28.874 13.5C28.874 7.90812 24.3409 3.375 18.749 3.375C13.1571 3.375 8.62402 7.90812 8.62402 13.5C8.62402 17.1934 10.6016 20.4249 13.5556 22.1934C12.4306 22.5571 11.3423 23.0394 10.3099 23.6353C7.74424 25.116 5.61343 27.2459 4.13154 29.8109C3.82072 30.3489 4.00489 31.037 4.54287 31.3478C5.08086 31.6586 5.76896 31.4745 6.07977 30.9365C7.36414 28.7134 9.21092 26.8674 11.4346 25.584C13.0802 24.6342 14.8894 24.0173 16.7594 23.7607C16.7532 23.6013 16.75 23.4411 16.75 23.28V21.119C13.3688 20.2344 10.874 17.1585 10.874 13.5ZM19.75 21.312V23.28C19.75 23.4073 19.7526 23.534 19.7578 23.6599C21.9736 23.8132 24.1301 24.4695 26.0613 25.5841C27.9301 26.6628 29.5327 28.1388 30.759 29.902C31.1165 29.5724 31.4536 29.2084 31.7703 28.81C31.9455 28.5896 32.1091 28.366 32.2611 28.1392C30.8958 26.3154 29.1711 24.7812 27.1861 23.6354C26.154 23.0397 25.0662 22.5575 23.9416 22.1939C26.0412 20.9372 27.6476 18.9415 28.4014 16.5662L27.0743 16.1112C26.8641 16.0391 26.6359 16.0391 26.4257 16.1112L26.1465 16.2069C25.1527 18.9217 22.7072 20.9369 19.75 21.312Z"
        fill="black"
      />
      <path
        d="M26.9738 31.9457C26.827 31.9831 26.673 31.9831 26.5262 31.9457C24.5994 31.4537 23.0006 30.4085 21.7297 28.81C20.4099 27.15 19.75 25.3067 19.75 23.28V19.1143C19.75 18.687 20.0215 18.3069 20.4257 18.1683L26.4257 16.1112C26.6359 16.0391 26.8641 16.0391 27.0743 16.1112L33.0743 18.1683C33.4785 18.3069 33.75 18.687 33.75 19.1143V23.28C33.75 25.3067 33.0901 27.15 31.7703 28.81C30.4994 30.4085 28.9006 31.4537 26.9738 31.9457Z"
        fill="black"
      />
    </svg>
  );
};

export default AdminUserIcon;
