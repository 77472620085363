import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Popover,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Divider,
  Modal,
} from "@mui/material";
import { DotsThree, Info, UserPlus, X } from "phosphor-react";
import useStyles from "../LicensesOverview/LicensesOverview.styles";
import LicenseInfoModal from "../LicensePageModals/LicenseInfoModal";
import AddUserModal from "../LicensePageModals/AddUserModal";
import DeleteModal from "../../ReusableComponents/DeleteModal/DeleteModal";
import { fpatch, fpost } from "../../../API/callsApi";

const AvailableLicenseTableItem = ({
  eachLicense,
  licensedUsers,
  handleRefreshCounter,
}) => {
  const classes = useStyles();
  const [availableLicenseToolsModal, setAvailableLicenseToolsModal] =
    useState(null);
  const [isLicenseInfoModal, setLicenseInfoModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const [cancleLicenseModal, setCancleLicenseModal] = useState(false);
  const [isRemoving, setIsRemoving] = useState(false);

  const handleAvailableLicenseToolsModal = (event) => {
    setAvailableLicenseToolsModal(event.currentTarget);
  };

  const handleCloseAvailableLicenseModal = () => {
    setAvailableLicenseToolsModal(null);
  };

  const handleLicenseInfoModal = () => {
    setLicenseInfoModal(!isLicenseInfoModal);
  };

  const handleAddUserModal = () => {
    setAddUserModal(!addUserModal);
  };

  const handleCancleLicense = () => {
    setCancleLicenseModal(!cancleLicenseModal);
  };

  const handleCancelLicense = async () => {
    setIsRemoving(true);
    try {
      const res = await fpatch({
        url: `/license/${eachLicense.id}/`,
        data: {
          status: "cancelled",
        },
      });
      if (res.status === 200 || res.status === 201) {
        setIsRemoving(false);
        handleRefreshCounter();
        handleCancleLicense();
      }
    } catch (error) {
      console.log(error);
      setIsRemoving(false);
    }
  };

  const assignLicense = async (selectedUser) => {
    try {
      const res = await fpost({
        url: "/admin/assign-licenses/",
        data: [
          {
            user: selectedUser.id,
            id: eachLicense.id,
          },
        ],
      });
      if (res.status === 200 || res.status === 201) {
        handleRefreshCounter();
        handleAddUserModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <TableRow
        key={eachLicense.id}
        sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
      >
        <TableCell
          component="th"
          scope="row"
          className={`${classes.tableRowCellsLicensesAvailable} ${classes.tableRowCells}`}
        >
          <Typography variant="h5" fontWeight={600}>
            {eachLicense.license_number}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowCellsLicensesAvailable} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
        >
          <Typography variant="h5">
            {eachLicense.subscribed_on
              ? new Date(eachLicense.subscribed_on).toLocaleDateString(
                  "en-US",
                  {
                    month: "long",
                    day: "numeric",
                    year: "numeric",
                  }
                )
              : "--"}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowCellsLicensesAvailable} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
        >
          <Typography variant="h5">
            {new Date(eachLicense.license_validity).toLocaleDateString(
              "en-US",
              {
                month: "long",
                day: "numeric",
                year: "numeric",
              }
            )}
          </Typography>
        </TableCell>
        <TableCell
          align="right"
          className={`${classes.tableRowCellsLicensesAvailable} ${classes.tableRowCells}`}
        >
          <Button
            style={{
              margin: "0px",
              padding: "5px",
              minWidth: "0px",
              textTransform: "none",
              marginRight: "45px",
            }}
            onClick={(event) => {
              event.stopPropagation();
              event.preventDefault();
              handleAvailableLicenseToolsModal(event);
            }}
          >
            <DotsThree size={20} color="#000000" />
          </Button>
        </TableCell>
      </TableRow>
      <Popover
        open={availableLicenseToolsModal !== null}
        anchorEl={availableLicenseToolsModal}
        onClose={handleCloseAvailableLicenseModal}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <List
          style={{
            borderRadius: "10px",
            padding: "0px",
            height: "max-content",
            border: "1px solid #666666",
          }}
        >
          <ListItem button={true} onClick={handleLicenseInfoModal}>
            <ListItemIcon>
              <Info size={22} color="#000000" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5" color="#000000">
                License Info
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem button={true} onClick={handleAddUserModal}>
            <ListItemIcon>
              <UserPlus size={22} color="#000000" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5" color="#000000">
                Add User
              </Typography>
            </ListItemText>
          </ListItem>
          <Divider />
          <ListItem button={true} onClick={handleCancleLicense}>
            <ListItemIcon>
              <X size={22} color="red" />
            </ListItemIcon>
            <ListItemText>
              <Typography variant="h5" color="red">
                Cancel License
              </Typography>
            </ListItemText>
          </ListItem>
        </List>
      </Popover>
      <Modal
        open={isLicenseInfoModal}
        className={classes.centerContainer}
        onClose={handleLicenseInfoModal}
      >
        <LicenseInfoModal
          closeModal={handleLicenseInfoModal}
          licenseInfo={eachLicense}
          type="available"
        />
      </Modal>
      <Modal
        open={addUserModal}
        className={classes.centerContainer}
        onClose={handleAddUserModal}
      >
        <AddUserModal
          licensedUsers={licensedUsers}
          closeModal={handleAddUserModal}
          assignLicense={assignLicense}
        />
      </Modal>
      <Modal
        className={classes.centerContainer}
        open={cancleLicenseModal}
        onClose={handleCancleLicense}
      >
        <DeleteModal
          isRemoving={isRemoving}
          cancleLicense={true}
          handleDelete={handleCancelLicense}
          closeModal={handleCancleLicense}
          btnText="Cancel License"
          text1="Are you sure you want to disable the following license?"
          text2={
            <div
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                gap: "24px",
              }}
            >
              <Typography variant="h4">License ID</Typography>{" "}
              <Typography variant="h4">:</Typography>{" "}
              <Typography variant="h4">{eachLicense.license_number}</Typography>
            </div>
          }
        />
      </Modal>
    </>
  );
};

export default AvailableLicenseTableItem;
