import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  Typography,
  TextField,
  InputAdornment,
  Grid,
} from "@mui/material";
import { MagnifyingGlass, Users, User } from "phosphor-react";
import { OutlinedButton } from "../../ReusableComponents/Buttons/Buttons";
import useStyles from "../DashBoardOverview/DashBoard.styles";
import OwnersSvg from "../../ReusableComponents/Svgs/OwnersSvg";
import AdminUserIcon from "../../ReusableComponents/Svgs/AdminUserIcon";
import DashboardNotFound from "../DasboardNotFound/DashboardNotFound";

const MyOrganizationsTable = ({ organizationsList }) => {
  const classes = useStyles();
  const history = useHistory();
  const [orgSearchValue, setOrgSearchValue] = useState("");

  return (
    <>
      {organizationsList.length > 0 ? (
        <div style={{ height: "100%", width: "100%" }}>
          <TextField
            label="search"
            variant="outlined"
            size="small"
            value={orgSearchValue}
            onChange={(e) => setOrgSearchValue(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MagnifyingGlass size={22} />
                </InputAdornment>
              ),
            }}
          />
          <Box
            style={{
              height: "91%",
              overflowX: "hidden",
              overflow: "auto",
              padding: "0px 15px 0px 0px",
              marginTop: "1%",
              display: "flex",
              flexDirection: "column",
              gap: "10px",
            }}
          >
            {organizationsList
              ?.filter((search) => {
                if (orgSearchValue === undefined) {
                  return search;
                } else if (orgSearchValue === "") {
                  return search;
                } else if (
                  search?.name
                    ?.toLowerCase()
                    .includes(orgSearchValue.toLowerCase())
                ) {
                  return search;
                }
                return null;
              })
              .map((eachOrg) => {
                return (
                  <Box
                    key={eachOrg.id}
                    style={{
                      border: "1px solid #BBBBBB",
                      borderRadius: "10px",
                      padding: "20px",
                      boxSizing: "border-box",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography
                        ml={1}
                        variant="h3"
                        style={{ fontWeight: "600" }}
                      >
                        {eachOrg.name}
                      </Typography>
                      <div style={{ width: "185px" }}>
                        <OutlinedButton
                          small={true}
                          text="Go to Org"
                          submit={() => {
                            eachOrg.role === "admin" || eachOrg.role === "owner"
                              ? history.push(
                                  `/organizations/${eachOrg.id}/?orgInfoType=manage-users`
                                )
                              : eachOrg.role === "user" &&
                                history.push(
                                  `/user/organizations/${eachOrg.id}/?orgInfoType=manage-users`
                                );
                          }}
                          icon={
                            <Users weight="fill" color="#000000" size={18} />
                          }
                        />
                      </div>
                    </div>
                    <Grid container>
                      <Grid item xs={8}>
                        <Grid container>
                          <Grid item xs={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={5}>
                                <Typography
                                  variant="h5"
                                  className={classes.tableRowCellsColor}
                                >
                                  My Role
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                :
                              </Grid>
                              <Grid
                                item
                                xs={5}
                                display="flex"
                                alignItems="center"
                                gap={1}
                              >
                                {eachOrg.role === "admin" && (
                                  <AdminUserIcon size="small" />
                                )}
                                {eachOrg.role === "user" && <User size={22} />}
                                {eachOrg.role === "owner" && (
                                  <OwnersSvg size="22" />
                                )}
                                <Typography variant="h5">
                                  {eachOrg.role}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                              <Grid item xs={5}>
                                <Typography
                                  variant="h5"
                                  className={classes.tableRowCellsColor}
                                >
                                  Joining Date
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                :
                              </Grid>
                              <Grid item xs={5}>
                                <Typography variant="h5">
                                  {eachOrg.added_on
                                    ? new Date(
                                        eachOrg.added_on
                                      ).toLocaleDateString("en-US", {
                                        month: "numeric",
                                        day: "numeric",
                                        year: "numeric",
                                      })
                                    : "--"}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                              <Grid item xs={5}>
                                <Typography
                                  variant="h5"
                                  className={classes.tableRowCellsColor}
                                >
                                  Org Owner
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                :
                              </Grid>
                              <Grid item xs={5}>
                                <Typography variant="h5">
                                  {eachOrg.owner?.first_name}{" "}
                                  {eachOrg.owner?.last_name}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                          <Grid item xs={6}>
                            <Grid container alignItems="center">
                              <Grid item xs={5}>
                                <Typography
                                  variant="h5"
                                  className={classes.tableRowCellsColor}
                                >
                                  Active Services
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                :
                              </Grid>
                              <Grid item xs={5}>
                                <Typography variant="h5">
                                  {eachOrg.active_services}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                              <Grid item xs={5}>
                                <Typography
                                  variant="h5"
                                  className={classes.tableRowCellsColor}
                                >
                                  Admins
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                :
                              </Grid>
                              <Grid item xs={5}>
                                <Typography variant="h5">
                                  {eachOrg.admin_count}
                                </Typography>
                              </Grid>
                            </Grid>
                            <Grid container alignItems="center">
                              <Grid item xs={5}>
                                <Typography
                                  variant="h5"
                                  className={classes.tableRowCellsColor}
                                >
                                  Users
                                </Typography>
                              </Grid>
                              <Grid item xs={2}>
                                :
                              </Grid>
                              <Grid item xs={5}>
                                <Typography variant="h5">
                                  {eachOrg.user_count}
                                </Typography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Grid item xs={4} alignItems="flex-end">
                        <div
                          style={{
                            display: "flex",
                            flexDirection: "column",
                            alignItems: "flex-end",
                            justifyContent: "flex-end",
                            gap: "10px",
                            marginTop: "10px",
                          }}
                        ></div>
                      </Grid>
                    </Grid>
                  </Box>
                );
              })}
          </Box>
        </div>
      ) : (
        <DashboardNotFound text="You have no organizations that you are a part of yet!" />
      )}
    </>
  );
};

export default MyOrganizationsTable;
