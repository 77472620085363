import React, { useState } from "react";
import { TextField, InputAdornment } from "@mui/material";
import { Eye, EyeSlash } from "phosphor-react";

const PasswordTextField = ({ label, handleChange, id, name, error }) => {
  const [showPassword, setShowPassword] = useState(false);

  return (
    <TextField
      required
      label={label}
      id={id}
      name={name}
      onChange={handleChange}
      type={showPassword ? "text" : "password"}
      style={{ width: "100%" }}
      error={error}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            {showPassword ? (
              <EyeSlash
                onClick={() => setShowPassword(false)}
                style={{ cursor: "pointer" }}
                size={22}
              />
            ) : (
              <Eye
                onClick={() => setShowPassword(true)}
                style={{ cursor: "pointer" }}
                size={22}
              />
            )}
          </InputAdornment>
        ),
      }}
    />
  );
};

export default PasswordTextField;
