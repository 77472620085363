import React, { useState } from "react";
import { TableRow, TableCell, Button, Typography, Modal } from "@mui/material";
import { Info, User } from "phosphor-react";
import useStyles from "../OrganizationOverviewUserView/OrganizationsOverviewUserView.styles";
import OwnersSvg from "../../../ReusableComponents/Svgs/OwnersSvg";
import AdminUserIcon from "../../../ReusableComponents/Svgs/AdminUserIcon";
import UserInfoModal from "../../OrganizationsModals/NewUserInfoModal/UserInfoModal";

const UserTableItems = ({ eachUser }) => {
  const classes = useStyles();
  const [isUserInfoModal, setUserInfoModal] = useState(false);

  const handleUserInfoModal = () => {
    setUserInfoModal(!isUserInfoModal);
  };

  return (
    <TableRow
      key={eachUser.id}
      sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
    >
      <TableCell
        component="th"
        scope="row"
        className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
      >
        <Typography variant="h5" fontWeight={500}>
          {eachUser.first_name} {eachUser.last_name}
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
      >
        <Typography
          variant="h5"
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "8px",
          }}
        >
          {eachUser.role === "Admin" && <AdminUserIcon size="small" />}
          {eachUser.role === "User" && <User size={22} />}
          {eachUser.role === "Owner" && <OwnersSvg size="22" />}
          <Typography variant="h5">{eachUser.role}</Typography>
        </Typography>
      </TableCell>
      <TableCell
        align="center"
        className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
      >
        <Typography variant="h5">{eachUser.email}</Typography>
      </TableCell>
      <TableCell
        align="center"
        className={`${classes.tableRowHeadService} ${classes.tableRowCells} ${classes.tableRowCellsColor}`}
      >
        <Typography variant="h5">
          {eachUser?.added_on
            ? new Date(eachUser?.added_on).toLocaleDateString("en-US", {
                month: "numeric",
                day: "numeric",
                year: "numeric",
              })
            : "--"}
        </Typography>
      </TableCell>
      <TableCell
        align="right"
        className={`${classes.tableRowHeadService} ${classes.tableRowCells}`}
      >
        <Button
          onClick={handleUserInfoModal}
          style={{
            margin: "0px",
            padding: "5px",
            minWidth: "0px",
            textTransform: "none",
            marginRight: "15px",
          }}
        >
          <Info size={20} color="#666666" />
        </Button>
      </TableCell>
      <Modal
        open={isUserInfoModal}
        className={classes.centerContainer}
        onClose={handleUserInfoModal}
      >
        <UserInfoModal userInfo={eachUser} closeModal={handleUserInfoModal} />
      </Modal>
    </TableRow>
  );
};

export default UserTableItems;
