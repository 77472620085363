import React from "react";
import { Box, Typography, Button } from "@mui/material";
import { Gear, Trash, X } from "phosphor-react";
import { ExitToApp } from "@material-ui/icons";
import {
  OutlinedButton,
  RedFilledButton,
} from "../../../ReusableComponents/Buttons/Buttons";
import OwnersSvg from "../../../ReusableComponents/Svgs/OwnersSvg";

const OrganizationActionsModals = ({
  name,
  close,
  view,
  openLeaveOrgModal,
  openOrgNameChangeModal,
  openTransferOwnerModal,
  openDeleteOrgModal,
}) => {
  return (
    <Box
      sx={{
        width: "600px",
        padding: "35px",
        borderRadius: "10px",
        backgroundColor: "white",
        color: "black",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <Gear weight="fill" size={30} />{" "}
          <Typography fontWeight={600} variant="h2">
            Organization Actions
          </Typography>{" "}
        </div>
        <Button onClick={close}>
          <X size={30} color="black" />
        </Button>
      </div>
      <Typography mt={4} variant="h3" color="#666666">
        {name}
      </Typography>
      {view === "owner" && (
        <Box>
          <Typography variant="h5" mt={2} mb={2}>
            Request a name change for your organization. Name changes can only
            be requested once every 30 days.
          </Typography>
          <div style={{ width: "225px" }}>
            <OutlinedButton
              text="Request Name Change"
              submit={openOrgNameChangeModal}
            />
          </div>
          <Typography variant="h5" mt={3} mb={2}>
            Transfer ownership of this organization to another user within your
            organization. This action can only be requested once every 30 days.
          </Typography>
          <div style={{ width: "225px" }}>
            <OutlinedButton
              text="Transfer Ownership"
              icon={<OwnersSvg />}
              submit={openTransferOwnerModal}
            />
          </div>
          <Typography variant="h5" mt={3} mb={2}>
            Deleting this organization cannot be undone. Please be certain
            before attempting this action.
          </Typography>
          <div style={{ width: "225px" }}>
            <RedFilledButton
              text="Delete Org"
              icon={<Trash />}
              submit={openDeleteOrgModal}
            />
          </div>
        </Box>
      )}
      <Typography variant="h5" mt={3} mb={2}>
        Leaving will remove your access to services & licenses setup by this
        organization. Please be certain before attempting this action.
      </Typography>
      <div style={{ width: "225px" }}>
        <RedFilledButton
          text="Leave Org"
          submit={openLeaveOrgModal}
          icon={<ExitToApp color="#FFFFFF" />}
        />
      </div>
    </Box>
  );
};

export default OrganizationActionsModals;
