import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import {
  Box,
  TextField,
  Typography,
  Button,
  Grid,
  Divider,
  InputAdornment,
} from "@mui/material";
import {
  CalendarCheck,
  Chat,
  File,
  CloudCheck,
  ArrowSquareOut,
  Info,
  MagnifyingGlass,
} from "phosphor-react";
import { fget } from "../../../API/callsApi";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import CommonLoader from "../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../ReusableComponents/NoDataFound/NoDataFound";

const ServicesOverview = () => {
  const history = useHistory();
  const [allProducts, setAllProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const getProducts = async () => {
    setIsLoading(true);
    try {
      const res = await fget({
        url: "/service-products/",
      });
      const filteredProduct = res.data.filter(
        (eachProduct) => eachProduct.name === "MovieColab"
      );
      setAllProducts(filteredProduct);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("error", error);
    }
  };

  useEffect(() => {
    getProducts();
  }, []);

  return (
    <Box boxSizing="border-box" px={8} py={3} style={{ height: "100%" }}>
      <Box>
        <Typography variant="h5">
          Explore our suite of innovative services designed to transform your
          creative vision into reality, from pre-visualization to final pixel
          rendering, and everything in between. Discover how Viga Entertainment
          Technology can elevate your projects with our expertise in real-time
          movie production, graphics engineering, and seamless integration with
          leading platforms.
        </Typography>
        <Box
          mt={3}
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Typography variant="h2" style={{ fontWeight: "600" }}>
            RTMS Suite
          </Typography>
          <TextField
            label="search"
            variant="outlined"
            size="small"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <MagnifyingGlass size={22} />
                </InputAdornment>
              ),
            }}
          />
        </Box>
        <Box
          mt={1}
          style={{ display: "flex", alignItems: "center", gap: "12px" }}
        >
          <Button
            style={{
              borderRadius: "10px",
              border: "1px solid #666666",
              textTransform: "none",
              color: "#666666",
            }}
          >
            Pre Production
          </Button>
          <Button
            style={{
              borderRadius: "10px",
              border: "1px solid #666666",
              textTransform: "none",
              color: "#666666",
            }}
          >
            Production
          </Button>
          <Button
            style={{
              borderRadius: "10px",
              border: "1px solid #666666",
              textTransform: "none",
              color: "#666666",
            }}
          >
            Post Production
          </Button>
          <Button
            style={{
              borderRadius: "10px",
              border: "1px solid #666666",
              textTransform: "none",
              color: "#666666",
            }}
          >
            Plugins
          </Button>
        </Box>
      </Box>
      <Grid
        mt={1}
        container
        spacing={3}
        sx={{
          overflow: "auto",
          paddingRight: "10px",
          height: "68%",
        }}
      >
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CommonLoader />
          </div>
        ) : allProducts && allProducts.length > 0 ? (
          allProducts.map((eachItem) => {
            return (
              <Grid key={eachItem.id} item xl={4} lg={4} xs={6}>
                <div
                  style={{ borderRadius: "10px", border: "1px solid #666666" }}
                >
                  <div
                    style={{
                      height: "20px",
                      width: "100%",
                      backgroundColor: "#6C65D9",
                      borderTopLeftRadius: "10px",
                      borderTopRightRadius: "10px",
                    }}
                  />
                  <div style={{ padding: "20px" }}>
                    <Typography variant="h1" style={{ fontWeight: "600" }}>
                      Movie Colab
                    </Typography>
                    <Typography variant="h5" style={{ marginTop: "20px" }}>
                      Collaboration Software for Virtual Production
                    </Typography>
                    <Divider style={{ margin: "10px 0px" }} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: "8px",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <File size={22} />
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          color="#666666"
                        >
                          Project & File Management
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <CalendarCheck size={22} />
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          color="#666666"
                        >
                          Task Management with Templates
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <CalendarCheck size={22} />
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          color="#666666"
                        >
                          Plugin Integration
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <Chat size={22} />
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          color="#666666"
                        >
                          Real-time Work Reviews
                        </Typography>
                      </div>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          gap: "8px",
                        }}
                      >
                        <CloudCheck size={22} />
                        <Typography
                          variant="h6"
                          fontWeight={400}
                          color="#666666"
                        >
                          Cloud Delivery and Synchronization
                        </Typography>
                      </div>
                    </div>
                    <Grid
                      container
                      spacing={2}
                      justifyContent="space-between"
                      style={{ marginTop: "23px" }}
                    >
                      <Grid item xs={6}>
                        <FilledButton
                          text="Setup Service"
                          icon={<ArrowSquareOut />}
                          submit={() =>
                            history.push(
                              `/services/${eachItem.id}/setup-service`
                            )
                          }
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <OutlinedButton
                          text="More Info"
                          icon={<Info />}
                          submit={() =>
                            history.push(`/services/${eachItem.id}`)
                          }
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>
              </Grid>
            );
          })
        ) : (
          <NoDataFound />
        )}
      </Grid>
    </Box>
  );
};

export default ServicesOverview;
