import React, { useEffect, useState } from "react";
import {
  Select,
  MenuItem,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  TextField,
  InputAdornment,
  FormControl,
  InputLabel,
} from "@mui/material";
import { MagnifyingGlass } from "phosphor-react";
import useStyles from "../OrganizationOverviewUserView/OrganizationsOverviewUserView.styles";
import { fget } from "../../../../API/callsApi";
import UserTableItems from "./UserTableItems";
import CommonLoader from "../../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../../ReusableComponents/NoDataFound/NoDataFound";
// import CustomPagination from "../../../ReusableComponents/CustomPagination/CustomPagination"

const OrganizationUsersUserView = ({ currentOrganization }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const [allActiveUsers, setAllActiveUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [userSearchValue, setUserSearchValue] = useState("");
  const [userRole, setUserRole] = useState("");

  const getActiveUser = async () => {
    setIsLoading(true);
    const url = `/organization/${currentOrganization}/users/`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        setAllActiveUsers(res?.data);
        setActiveUsers(res?.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getActiveUser();
  }, [currentOrganization]);

  useEffect(() => {
    if (userRole === "User" || userRole === "Admin" || userRole === "Owner") {
      const currentUsers = activeUsers.filter((user) => user.role === userRole);
      setAllActiveUsers(currentUsers);
    } else if (userRole === "All") {
      getActiveUser();
    }
  }, [userRole]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          marginBottom: "5px",
        }}
      >
        <TextField
          label="search"
          variant="outlined"
          size="small"
          value={userSearchValue}
          onChange={(e) => setUserSearchValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MagnifyingGlass size={22} />
              </InputAdornment>
            ),
          }}
        />
        <FormControl size="small" style={{ width: "100%" }}>
          <InputLabel
            style={{ padding: "0px 5px" }}
            id="demo-simple-select-helper-label-user"
          >
            User Role
          </InputLabel>
          <Select
            labelId="demo-simple-select-helper-label-user"
            id="demo-simple-select-helper-label-user"
            variant="outlined"
            label="Status"
            style={{ width: "150px", height: "100%" }}
            value={userRole}
            onChange={(event) => setUserRole(event.target.value)}
          >
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="User">User</MenuItem>
            <MenuItem value="Admin">Admin</MenuItem>
            <MenuItem value="Owner">Owner</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer style={{ overflow: "hidden" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                User
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                User Role
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Email ID
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Joining Date
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                <span style={{ marginRight: "15px" }}>Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box
        style={{
          height: "89%",
          overflowY: "auto",
          border: "1px solid black",
          borderRadius: "5px",
        }}
      >
        {isLoading ? (
          <div className={classes.loaderContainer}>
            <CommonLoader />
          </div>
        ) : allActiveUsers && allActiveUsers.length > 0 ? (
          <TableContainer>
            <Table aria-label="simple table">
              <TableBody style={{ height: "100%" }}>
                {allActiveUsers
                  ?.filter((search) => {
                    if (userSearchValue === undefined) {
                      return search;
                    } else if (userSearchValue === "") {
                      return search;
                    } else if (
                      search?.first_name
                        ?.toLowerCase()
                        .includes(userSearchValue.toLowerCase())
                    ) {
                      return search;
                    } else if (
                      search?.last_name
                        ?.toLowerCase()
                        .includes(userSearchValue.toLowerCase())
                    ) {
                      return search;
                    }
                    return null;
                  })
                  .map((eachUser) => (
                    <UserTableItems key={eachUser.id} eachUser={eachUser} />
                  ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <NoDataFound />
        )}
      </Box>
    </>
  );
};

export default OrganizationUsersUserView;
