import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Typography,
  Autocomplete,
  TextField,
  CircularProgress,
} from "@mui/material";
import { X } from "phosphor-react";
import { FilledButton } from "../../../ReusableComponents/Buttons/Buttons";
import useAuth from "../../../../hooks/useAuth";
import { fget, fpost } from "../../../../API/callsApi";

const ReplaceAndTransferModal = ({
  icon,
  header,
  closeModal,
  orgInfo,
  handleOrgActionModal,
  getOrg,
}) => {
  const { getOrgList } = useAuth();
  const [userOptions, setUserOptions] = useState([]);
  const [value, setValue] = useState();
  const [isLoading, setLoading] = useState(false);
  const defaultProps = {
    options: userOptions,
    getOptionLabel: (option) => option.first_name + " " + option.last_name,
  };

  const getActiveUser = async () => {
    const url = `/organization/${orgInfo.id}/users/`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        setUserOptions(res?.data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const transferOwnership = async () => {
    setLoading(true);
    try {
      const res = await fpost({
        url: `/organization/${orgInfo.id}/transfer-ownership/`,
        data: {
          user: value.id,
        },
      });
      if (res.status === 200 || res.status === 201) {
        getOrgList();
        getOrg();
        closeModal();
        handleOrgActionModal();
      }
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getActiveUser();
  }, []);

  return (
    <Box
      elevation={0}
      sx={{
        width: "500px",
        backgroundColor: "white",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
        borderRadius: "10px",
      }}
    >
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography
          variant="h2"
          style={{
            display: "flex",
            alignItems: "center",
            gap: "10px",
            fontWeight: "600",
          }}
        >
          {icon}
          {header}
        </Typography>
        <Button onClick={closeModal}>
          <X color="#000000" size={30} />
        </Button>
      </div>
      <Autocomplete
        {...defaultProps}
        id="controlled-demo"
        fullWidth
        value={value}
        onChange={(event, newValue) => {
          console.log(event.isTrusted);
          setValue(newValue);
        }}
        sx={{ margin: "30px 0px" }}
        renderInput={(params) => (
          <TextField {...params} label="User Name" variant="standard" />
        )}
      />

      <div style={{ width: "200px" }}>
        <FilledButton
          disabled={!value || isLoading}
          text={isLoading ? <CircularProgress /> : "Transfer Ownership"}
          submit={transferOwnership}
        />
      </div>
    </Box>
  );
};

export default ReplaceAndTransferModal;
