import React, { useState, useEffect } from "react";
import { Link, useHistory } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import {
  Box,
  Grid,
  Typography,
  CircularProgress,
  TextField,
} from "@mui/material";
import { Headset } from "phosphor-react";
import useStyles from "../NewLogin/NewLogin.styles";
import { fpatch, readCookie } from "../../../API/callsApi";
import PasswordTextField from "../../ReusableComponents/PasswordTextField/PasswordTextField";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import { toast } from "react-hot-toast";
import NewPasswordResetSuccessful from "./NewPasswordResetSuccessful";

const NewResetPassword = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);

  const Schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
    newpassword: yup.string().required("Input Required"),
    confirmpassword: yup
      .string()
      .required("Input Required")
      .test("passwords-match", "Passwords don't match", function (value) {
        return this.parent.password === value;
      }),
  });

  useEffect(() => {
    let data = readCookie("refresh");
    if (data) {
      history.push("/dashboard/?dashboardType=services");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      newpassword: "",
      confirmpassword: "",
    },
    validationSchema: Schema,

    onSubmit: async (values) => {
      setLoading(true);
      let formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append(
        "reset_code",
        window?.location?.href?.split("/")[4]?.replace("?resetcode=", "")
      );
      formdata.append("new_password", values.confirmpassword);

      let obj = {
        url: "/reset-password-code/",
        token_value: readCookie("accessToken"),
        data: formdata,
      };
      try {
        const res = await fpatch(obj);
        if (res.status === 200 || res.status === 201) {
          setLoading(false);
          setPasswordResetSuccess(true);
          setTimeout(() => {
            history.push("/login");
          }, 5000);
        }
      } catch (error) {
        setLoading(false);
        setPasswordResetSuccess(false);
        toast.error(error.message);
      }
    },
  });

  if (passwordResetSuccess) {
    return <NewPasswordResetSuccessful />;
  }

  return (
    <Box
      px={16}
      py={8}
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <form onSubmit={formik.handleSubmit} style={{ height: "100%" }}>
        <Box
          gap={4}
          sx={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography fontWeight={600} variant="h1">
            Reset Password
          </Typography>
          <Grid container>
            <Grid item xs={12} lg={8}>
              <TextField
                required
                type="email"
                label="Email ID"
                id="email"
                name="email"
                className={classes.inputContainer}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
              />
              {formik.touched.email && formik.errors.email && (
                <Typography variant="h6" color="red">
                  {formik.errors.email}
                </Typography>
              )}
            </Grid>
          </Grid>
          <Grid container gap={4}>
            <Grid item xs={12} lg={4}>
              <PasswordTextField
                label="New Password"
                id="newpassword"
                name="newpassword"
                handleChange={formik.handleChange}
                error={
                  formik.touched.newpassword &&
                  Boolean(formik.errors.newpassword)
                }
              />
              {formik.touched.newpassword && formik.errors.newpassword && (
                <Typography variant="h6" color="red">
                  {formik.errors.newpassword}
                </Typography>
              )}
            </Grid>
            <Grid item xs={12} lg={4}>
              <PasswordTextField
                label="Confirm New Password"
                id="confirmpassword"
                name="confirmpassword"
                handleChange={formik.handleChange}
                error={
                  formik.touched.confirmpassword &&
                  Boolean(formik.errors.confirmpassword)
                }
              />
              {formik.touched.confirmpassword &&
                formik.errors.confirmpassword && (
                  <Typography variant="h6" color="red">
                    {formik.errors.confirmpassword}
                  </Typography>
                )}
            </Grid>
          </Grid>

          <div style={{ width: "250px" }}>
            <FilledButton
              submit={() => formik.submitForm()}
              text={
                loading ? (
                  <CircularProgress
                    width="20"
                    height="20"
                    style={{ color: "white" }}
                  />
                ) : (
                  "Set New Password"
                )
              }
            />
          </div>
        </Box>
      </form>
      <div className={classes.footer}>
        <Headset /> Need help? Contact{" "}
        <Link to="#">
          <span style={{ color: "#666666" }}>support@vigastudios.com</span>
        </Link>
      </div>
    </Box>
  );
};

export default NewResetPassword;
