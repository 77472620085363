import React from "react";

const NoUserFound = () => {
  return (
    <svg
      width="354"
      height="231"
      viewBox="0 0 354 231"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="0.125"
        width="338"
        height="217"
        rx="30"
        fill="url(#paint0_linear_4025_23713)"
      />
      <path
        d="M49.2934 79.5279C46.9907 198.396 33.805 206.871 27.5 217.166H184.166C175.942 178.1 174.434 123.074 174.709 79.5279C176.216 57.6853 166.156 14 113.851 14C61.5471 14 49.0193 57.6853 49.2934 79.5279Z"
        fill="black"
      />
      <path
        d="M87.5527 159.831H140.053L143.334 217.164H83.334L87.5527 159.831Z"
        fill="white"
      />
      <path
        d="M153.332 192.331C98.6659 192.331 78.6104 161.498 75.416 146.082L153.332 142.332V192.331Z"
        fill="black"
      />
      <path
        d="M170.895 90.7824C173.991 126.174 153.881 172.416 122.016 175.203C90.1514 177.991 58.5992 136.269 55.5029 100.878C52.4066 65.4866 75.7279 34.5363 107.593 31.7485C139.457 28.9607 167.799 55.3911 170.895 90.7824Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.811 152.193C111.963 151.766 112.416 151.555 112.824 151.721C116.363 153.161 121.143 152.489 124.148 150.13C124.494 149.858 124.988 149.936 125.252 150.305C125.515 150.673 125.448 151.192 125.102 151.464C121.667 154.161 116.319 154.912 112.274 153.266C111.867 153.1 111.659 152.619 111.811 152.193Z"
        fill="black"
      />
      <path
        d="M132.007 142.772C133.423 142.609 135.008 141.659 135.605 141.592C136.005 141.548 136.171 142.006 135.856 142.211C135.825 142.232 135.793 142.251 135.764 142.275C130.739 146.34 122.761 147.592 118.305 148.218L117.622 143.362C117.622 143.362 118.29 143.311 118.848 142.898C119.406 142.485 119.989 141.929 120.953 142.095C126.313 143.018 124.588 143.631 132.007 142.772Z"
        fill="black"
      />
      <path
        d="M102.782 148.001C101.62 148.312 100.883 148.467 100.29 148.568C99.8743 148.638 99.8571 149.169 100.274 149.245C106.28 150.349 113.836 148.846 118.305 148.218L117.623 143.362C117.623 143.362 116.967 143.497 116.317 143.254C115.667 143.011 114.953 142.637 114.072 143.062C109.174 145.427 110.002 146.064 102.782 148.001Z"
        fill="black"
      />
      <path
        d="M163.281 93.0979C159.467 114.724 158.652 111.198 168.273 112.895C174.435 113.981 183.662 107.983 185.593 97.0321C187.524 86.0809 181.002 81.4127 174.841 80.3263C161.418 77.9595 166.062 77.3256 163.281 93.0979Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M182.895 91.7027C182.313 88.2631 180.26 85.6205 177.326 84.6884C176.887 84.549 176.418 84.7917 176.279 85.2303C176.14 85.6689 176.382 86.1375 176.821 86.2768C179.049 86.9846 180.753 89.0324 181.252 91.981C181.751 94.9282 181.03 98.761 178.354 102.837C178.101 103.222 178.208 103.739 178.593 103.991C178.978 104.244 179.495 104.137 179.747 103.752C182.606 99.3969 183.478 95.1437 182.895 91.7027Z"
        fill="black"
      />
      <path
        d="M62.9454 101.876C70.4563 122.511 70.6466 118.897 61.4664 122.239C55.5874 124.379 45.4592 120.074 41.6558 109.624C37.8525 99.1749 43.4645 93.4452 49.3435 91.3054C62.1515 86.6437 57.4676 86.8259 62.9454 101.876Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M43.3804 103.908C43.3567 100.42 44.9192 97.4611 47.6473 96.0335C48.0551 95.8201 48.5587 95.9777 48.772 96.3855C48.9854 96.7932 48.8278 97.2968 48.4201 97.5102C46.3487 98.5941 45.0267 100.907 45.047 103.897C45.0673 106.886 46.4429 110.536 49.7861 114.085C50.1016 114.42 50.0858 114.948 49.7508 115.263C49.4158 115.579 48.8884 115.563 48.5728 115.228C45.0011 111.436 43.4041 107.398 43.3804 103.908Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.001 100.199C114.689 100.139 115.295 100.648 115.355 101.336L117.389 124.58C117.449 125.268 116.94 125.874 116.253 125.934C115.565 125.995 114.959 125.486 114.898 124.798L112.865 101.554C112.805 100.866 113.313 100.26 114.001 100.199Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.006 127.861C108.45 127.333 109.238 127.264 109.767 127.707L113.378 130.738C113.907 131.181 113.976 131.97 113.532 132.499C113.089 133.027 112.3 133.096 111.771 132.653L108.16 129.623C107.631 129.179 107.562 128.39 108.006 127.861Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M124.708 126.4C124.179 125.956 123.391 126.025 122.947 126.554L119.917 130.165C119.473 130.694 119.542 131.483 120.071 131.926C120.6 132.37 121.388 132.301 121.832 131.772L124.862 128.161C125.306 127.632 125.237 126.844 124.708 126.4Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.6053 97.5347L69.1127 96.3924C68.5053 96.1225 67.7929 96.3753 67.4913 96.9676C67.1897 97.56 67.4045 98.2847 67.9801 98.6172L71.596 100.706C73.0483 101.716 74.5919 103.035 75.6456 104.581C76.0345 105.151 76.8122 105.298 77.3826 104.909C77.953 104.52 78.1001 103.743 77.7112 103.172C77.0071 102.14 76.1469 101.216 75.2514 100.41C78.2596 97.3674 82.4297 94.9265 86.9326 94.7374C91.7342 94.5359 97.2451 96.8848 102.425 104.581C102.811 105.154 103.588 105.305 104.16 104.92C104.733 104.534 104.885 103.758 104.499 103.185C98.9641 94.9615 92.7081 91.9928 86.8277 92.2397C81.4337 92.4661 76.6407 95.3902 73.3166 98.8123L72.9358 98.5924C72.723 98.4456 72.5143 98.3064 72.3116 98.1748C71.4317 97.6037 70.6404 97.162 70.067 96.8621C69.7799 96.712 69.5462 96.5967 69.3821 96.518C69.3 96.4786 69.2352 96.4484 69.1896 96.4274L69.136 96.4028L69.1205 96.3958L69.1156 96.3937L69.1139 96.3929C69.1136 96.3928 69.1127 96.3924 68.6053 97.5347Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M86.6491 94.4538C86.304 95.264 86.1809 96.3411 86.3563 97.7855C86.9268 102.482 89.9134 105.983 93.0269 105.605C95.7302 105.277 97.6168 102.408 97.6937 98.5842C93.9736 95.2269 90.0597 94.2364 86.6491 94.4538Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M67.0354 88.9621C66.8433 87.5948 67.7959 86.3307 69.1632 86.1385L100.522 81.7314C101.889 81.5392 103.153 82.4918 103.345 83.8591C103.537 85.2264 102.585 86.4906 101.217 86.6827L69.859 91.0898C68.4918 91.282 67.2276 90.3294 67.0354 88.9621Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M158.259 89.6912L157.561 88.6543C158.112 88.2831 158.858 88.4083 159.258 88.9392C159.657 89.4702 159.572 90.2213 159.063 90.6486L155.864 93.3332C154.61 94.5808 153.319 96.1478 152.549 97.8527C152.265 98.482 151.525 98.7619 150.896 98.4779C150.266 98.194 149.986 97.4537 150.27 96.8244C150.784 95.6853 151.471 94.6261 152.213 93.6764C148.722 91.2028 144.192 89.5231 139.724 90.1189C134.961 90.7542 129.941 94.0244 126.176 102.503C125.896 103.134 125.157 103.418 124.527 103.138C123.896 102.858 123.611 102.119 123.891 101.489C127.915 92.4288 133.56 88.4188 139.394 87.6408C144.745 86.9272 149.973 88.9746 153.841 91.7675L154.178 91.4847C154.362 91.3032 154.543 91.1299 154.72 90.9651C155.487 90.2499 156.19 89.6775 156.703 89.2826C156.959 89.0849 157.169 88.9308 157.317 88.8248C157.391 88.7718 157.45 88.7307 157.491 88.7021L157.54 88.6686L157.554 88.659L157.558 88.6561L157.56 88.655C157.56 88.6548 157.561 88.6543 158.259 89.6912Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M147.385 88.1032C148.389 88.7875 149.058 90.045 149.179 92.2876C149.432 97.0119 147.099 100.979 143.967 101.147C140.835 101.315 138.185 97.9767 137.932 93.2525C137.86 91.9173 137.992 90.8811 138.289 90.0777C141.823 89.2443 145.209 89.9103 147.506 90.9936L147.385 88.1032Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M121.657 82.2563C121.705 83.6362 122.863 84.7157 124.243 84.6675L155.89 83.5624C157.27 83.5142 158.349 82.3565 158.301 80.9766C158.253 79.5968 157.095 78.5172 155.715 78.5654L124.068 79.6706C122.688 79.7188 121.609 80.8764 121.657 82.2563Z"
        fill="black"
      />
      <path
        d="M67.5007 77.4164L57.043 78.3314C56.4273 92.8402 62.4365 131.732 64.6104 147.602C59.7699 133.571 52.2779 102.255 51.2611 90.6327L53.0928 73.3239L71.8288 43.6615L95.7167 29.8604L137.136 30.001L163.301 53.9777L169.497 65.2312L172.734 78.3322C173.268 98.78 174.214 132.826 172.901 146.493L164.609 68.9206L146.681 70.4891C142.991 69.6073 131.972 57.2206 126.923 51.1375C127.342 55.9192 135.29 66.4666 139.211 71.1426L125.765 72.3189L114.131 59.7853L122.778 72.5803L109.332 73.7567L95.6806 55.3766C96.6219 66.1355 102.273 73.3705 104.981 75.6431C99.9908 73.6705 94.971 64.473 93.0848 60.1209L85.4284 75.848L73.4766 76.8936C72.7445 68.5256 75.0265 60.195 76.259 57.0758L67.5007 77.4164Z"
        fill="black"
      />
      <path
        d="M108.243 98.4455C108.776 104.534 108.059 109.365 105.596 112.816C103.159 116.23 98.8535 118.507 91.6975 119.133C85.7791 119.651 79.3606 117.602 74.3122 114.263C69.2297 110.902 65.7751 106.401 65.4069 102.193C65.0555 98.1761 65.4148 95.2053 66.2735 92.9843C67.1231 90.7868 68.4816 89.2692 70.2453 88.1729C72.0324 87.0621 74.2639 86.3667 76.8665 85.8857C79.4697 85.4046 82.3676 85.1501 85.4741 84.8783C91.6962 84.334 97.0745 84.1949 101.057 85.9336C103.009 86.7859 104.625 88.0923 105.842 90.0608C107.068 92.0439 107.92 94.7515 108.243 98.4455Z"
        fill="#7196CE"
        fillOpacity="0.4"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M65.9205 95.6235L55.8652 90.6476"
        stroke="black"
        strokeWidth="5"
      />
      <path
        d="M119.787 97.4365C120.32 103.525 121.865 108.159 124.89 111.129C127.883 114.068 132.518 115.563 139.674 114.937C145.592 114.419 151.558 111.287 155.949 107.122C160.371 102.929 162.992 97.8971 162.624 93.6889C162.272 89.6718 161.402 86.8086 160.171 84.7704C158.953 82.7538 157.351 81.4952 155.424 80.7218C153.471 79.9382 151.153 79.6409 148.506 79.6191C145.859 79.5973 142.961 79.85 139.854 80.1217C133.632 80.6661 128.312 81.4631 124.692 83.8668C122.917 85.0453 121.553 86.6125 120.696 88.7623C119.833 90.9281 119.464 93.7426 119.787 97.4365Z"
        fill="#7196CE"
        fillOpacity="0.4"
        stroke="black"
        strokeWidth="2"
      />
      <path
        d="M160.977 87.308L170.016 80.6616"
        stroke="black"
        strokeWidth="5"
      />
      <path
        d="M107.646 94.4821L119.269 93.4652"
        stroke="black"
        strokeWidth="3"
      />
      <path
        d="M254.292 171.169L245.193 178.062L236.977 179.048L240.92 195.809L268.528 196.467L287.918 171.16C301.327 166.428 299.859 161.081 297.449 159L285.468 164.53C284.913 164.786 284.326 164.968 283.724 165.072L258.198 169.473C256.779 169.718 255.44 170.299 254.292 171.169Z"
        fill="black"
      />
      <path
        d="M317.907 173.485L286.577 196.439C285.394 197.306 283.89 197.609 282.464 197.27L277.811 196.162L278.468 193.205L305.34 174.246C311.951 169.451 316.006 171.705 317.907 173.485Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M317.802 173.558L317.906 173.482C316.005 171.702 311.95 169.448 305.339 174.244L288.166 186.36L280.687 193.317L309.609 173.789C309.609 173.789 313.65 170.991 317.802 173.558Z"
        fill="black"
      />
      <path
        d="M276.333 197.792L270.442 197.566L290.425 174.032C295.396 167.551 301.297 168.259 303.626 169.423L280.288 196.089C279.294 197.224 277.841 197.85 276.333 197.792Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M303.222 169.243C300.668 168.206 295.129 167.899 290.424 174.033L271.686 196.102L271.817 196.469L294.165 173.134C294.165 173.134 298.712 168.098 303.222 169.243Z"
        fill="black"
      />
      <path
        d="M266.483 201.629L260.22 201.406L278.058 177.405C282.528 170.569 288.137 170.502 290.547 171.488L270.745 199.518C269.771 200.896 268.169 201.69 266.483 201.629Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M290.136 171.34C287.543 170.528 282.29 170.933 278.059 177.405L267.086 192.169L267.542 192.194L281.674 175.104C281.674 175.104 285.913 171.326 290.136 171.34Z"
        fill="black"
      />
      <path
        d="M264.367 198.692L278.059 174.447C273.063 171.292 268.966 175.762 267.542 178.391L259.838 190.718C259.552 191.175 258.955 191.323 258.489 191.053L240.592 180.692C234.282 175.959 226.46 182.335 223.173 188.908L203.125 230.319H231.718L240.927 204.475C241.51 202.837 243.086 201.764 244.824 201.82L258.047 202.246C260.651 202.33 263.086 200.961 264.367 198.692Z"
        fill="white"
      />
      <rect
        x="207.125"
        y="98.8855"
        width="55"
        height="55"
        rx="10"
        transform="rotate(-14.6234 207.125 98.8855)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M238.073 108.573C234.566 109.489 232.465 113.073 233.38 116.58C234.295 120.087 237.88 122.188 241.387 121.273C244.894 120.358 246.995 116.774 246.08 113.267C245.165 109.76 241.58 107.658 238.073 108.573ZM231.566 117.054C230.389 112.545 233.091 107.936 237.6 106.759C242.109 105.583 246.718 108.284 247.894 112.793C249.071 117.302 246.369 121.911 241.86 123.088C237.351 124.264 232.742 121.563 231.566 117.054Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M241.856 123.089C239.785 123.629 237.894 124.704 236.371 126.207C234.848 127.71 233.747 129.587 233.179 131.65C233.042 132.149 232.525 132.442 232.026 132.305C231.527 132.167 231.234 131.651 231.371 131.152C232.026 128.772 233.297 126.606 235.054 124.872C236.811 123.139 238.994 121.898 241.382 121.275C243.771 120.651 246.281 120.668 248.662 121.322C251.042 121.976 253.208 123.245 254.943 125.002C255.307 125.37 255.303 125.964 254.934 126.328C254.566 126.692 253.972 126.688 253.609 126.319C252.105 124.797 250.228 123.697 248.165 123.13C246.102 122.563 243.926 122.549 241.856 123.089Z"
        fill="black"
      />
      <rect
        x="302.338"
        y="67"
        width="55"
        height="55"
        rx="10"
        transform="rotate(20.4454 302.338 67)"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M322.101 92.7109C318.705 91.4449 314.925 93.1716 313.659 96.5677C312.393 99.9637 314.12 103.743 317.516 105.009C320.912 106.275 324.691 104.548 325.957 101.152C327.223 97.7564 325.497 93.977 322.101 92.7109ZM311.902 95.9127C313.53 91.5463 318.389 89.3263 322.756 90.9541C327.122 92.5818 329.342 97.441 327.714 101.807C326.086 106.174 321.227 108.394 316.861 106.766C312.495 105.138 310.274 100.279 311.902 95.9127Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M316.857 106.764C314.852 106.017 312.686 105.81 310.576 106.165C308.466 106.52 306.486 107.424 304.836 108.786C304.437 109.115 303.846 109.059 303.517 108.659C303.187 108.26 303.243 107.669 303.643 107.34C305.547 105.768 307.83 104.725 310.265 104.316C312.699 103.907 315.198 104.145 317.512 105.007C319.825 105.87 321.87 107.326 323.442 109.229C325.015 111.132 326.058 113.415 326.469 115.849C326.555 116.36 326.211 116.844 325.7 116.93C325.19 117.016 324.706 116.672 324.62 116.161C324.264 114.051 323.359 112.072 321.997 110.423C320.634 108.773 318.861 107.512 316.857 106.764Z"
        fill="white"
      />
      <rect
        x="214.125"
        y="32.7148"
        width="43.4988"
        height="43.4988"
        rx="7"
        transform="rotate(-4.89859 214.125 32.7148)"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M236.928 44.0662C233.954 44.3211 231.75 46.9386 232.005 49.9125C232.26 52.8864 234.877 55.0906 237.851 54.8357C240.825 54.5808 243.029 51.9634 242.774 48.9895C242.519 46.0156 239.902 43.8113 236.928 44.0662ZM230.466 50.0443C230.139 46.2207 232.973 42.8554 236.796 42.5277C240.62 42.2 243.985 45.034 244.313 48.8576C244.641 52.6812 241.807 56.0465 237.983 56.3742C234.159 56.7019 230.794 53.8679 230.466 50.0443Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M237.978 56.374C236.222 56.5244 234.537 57.1342 233.092 58.1421C231.646 59.15 230.492 60.5204 229.744 62.1158C229.563 62.5019 229.103 62.6681 228.717 62.4871C228.331 62.3061 228.165 61.8464 228.346 61.4603C229.209 59.6196 230.541 58.0384 232.208 56.8755C233.876 55.7126 235.82 55.0091 237.846 54.8355C239.871 54.6619 241.907 55.0244 243.748 55.8866C245.589 56.7488 247.171 58.0803 248.334 59.7474C248.578 60.0971 248.493 60.5784 248.143 60.8224C247.793 61.0665 247.312 60.9808 247.068 60.6312C246.06 59.1863 244.689 58.0323 243.093 57.285C241.497 56.5378 239.733 56.2236 237.978 56.374Z"
        fill="white"
      />
      <rect
        x="286.438"
        y="13"
        width="34.3333"
        height="34.3333"
        rx="4"
        transform="rotate(19.2382 286.438 13)"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M299.001 29.1034C296.976 28.3967 294.761 29.4655 294.055 31.4905C293.348 33.5155 294.417 35.73 296.442 36.4367C298.467 37.1434 300.681 36.0747 301.388 34.0497C302.095 32.0246 301.026 29.8101 299.001 29.1034ZM293.007 31.1249C293.916 28.5213 296.763 27.1472 299.367 28.0558C301.97 28.9645 303.344 31.8117 302.436 34.4153C301.527 37.0189 298.68 38.3929 296.076 37.4843C293.473 36.5757 292.099 33.7285 293.007 31.1249Z"
        fill="black"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M296.071 37.4828C294.875 37.0656 293.591 36.9703 292.347 37.2066C291.103 37.4428 289.944 38.0022 288.984 38.8286C288.752 39.0286 288.402 39.0025 288.202 38.7704C288.002 38.5383 288.028 38.188 288.26 37.988C289.367 37.0345 290.705 36.389 292.14 36.1165C293.575 35.8439 295.057 35.9538 296.436 36.4352C297.816 36.9165 299.044 37.7524 299.998 38.8587C300.952 39.9651 301.598 41.303 301.871 42.7381C301.928 43.0391 301.731 43.3295 301.43 43.3868C301.129 43.4441 300.838 43.2466 300.781 42.9456C300.544 41.7018 299.984 40.5422 299.158 39.5833C298.331 38.6244 297.266 37.9 296.071 37.4828Z"
        fill="black"
      />
      <defs>
        <linearGradient
          id="paint0_linear_4025_23713"
          x1="183.209"
          y1="4.18071e-07"
          x2="185.77"
          y2="207.069"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#BFFFB7" />
          <stop offset="0.315" stopColor="#63B4FF" />
          <stop offset="0.64" stopColor="#564ECA" />
          <stop offset="1" stopColor="#232058" />
        </linearGradient>
      </defs>
    </svg>
  );
};

export default NoUserFound;
