import { createTheme } from "@mui/material/styles";

const defaultTheme = createTheme({
  //   typography: {
  //     h1: {
  //       [breakpoints.up("xl")]: {
  //         fontSize: "30px !important",
  //         color: "red !important",
  //       },
  //       [breakpoints.up("lg")]: {
  //         fontSize: "28px !important",
  //         color: "blue !important",
  //       },
  //       [breakpoints.up("md")]: {
  //         fontSize: "24px !important",
  //         color: "green !important",
  //       },
  //       [breakpoints.up("sm")]: {
  //         fontSize: "22px !important",
  //         color: "yellow !important",
  //       },
  //     },
  //   },
});

const theme = {
  ...defaultTheme,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          textTransform: "none",
          minWidth: "0px",
        },
      },
    },
  },
};

defaultTheme.typography.h1 = {
  [theme.breakpoints.up("xl")]: {
    fontSize: "30px !important",
    color: "red !important",
  },
  [theme.breakpoints.up("lg")]: {
    fontSize: "28px !important",
    color: "blue !important",
  },
  [theme.breakpoints.up("md")]: {
    fontSize: "24px !important",
    color: "green !important",
  },
  [theme.breakpoints.up("sm")]: {
    fontSize: "22px !important",
    color: "yellow !important",
  },
};

// theme.typography.h2 = {
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "26px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "24px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "22px",
//   },
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "20px",
//   },
// };

// theme.typography.h3 = {
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "22px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "20px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "18px",
//   },
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "16px",
//   },
// };

// theme.typography.h4 = {
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "20px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "18px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "16px",
//   },
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "14px",
//   },
// };

// theme.typography.h5 = {
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "16px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "14px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "12px",
//   },
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "10px",
//   },
// };

// theme.typography.h6 = {
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "14px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "12px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "10px",
//   },
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "8px",
//   },
// };

// theme.typography.body1 = {
//   [theme.breakpoints.up("xl")]: {
//     fontSize: "18px",
//   },
//   [theme.breakpoints.up("lg")]: {
//     fontSize: "16px",
//   },
//   [theme.breakpoints.up("md")]: {
//     fontSize: "14px",
//   },
//   [theme.breakpoints.up("sm")]: {
//     fontSize: "12px",
//   },
// };

export default theme;
