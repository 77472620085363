import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Box, Typography } from "@mui/material";
import { Headset } from "phosphor-react";
import useStyles from "../NewLogin/NewLogin.styles";

const NewForgotPassword = ({ email }) => {
  const classes = useStyles();

  return (
    <Box
      px={16}
      py={8}
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <Box
        gap={4}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <Typography width={600} fontWeight={600} variant="h1">
          A link to reset your password has been sent to your registered Email
          ID
        </Typography>
        <Typography fontWeight={500} variant="h2">
          <span style={{ color: "#666666" }}>Email ID : </span>
          {email.slice(0, 2)}*****{email.slice(-3)}
        </Typography>
        <Typography variant="h5" color="#666666">
          Follow the steps mentioned in the email to reset your password.
        </Typography>
      </Box>
      <div className={classes.footer}>
        <Headset /> Need help? Contact{" "}
        <Link to="#">
          <span style={{ color: "#666666" }}>support@vigastudios.com</span>
        </Link>
      </div>
    </Box>
  );
};

export default NewForgotPassword;
