import React from "react";
import { Box, Typography, Grid, Divider, Tooltip } from "@mui/material";
import { useHistory, Link } from "react-router-dom";
import ConfettiIcon from "../../ReusableComponents/Svgs/ConfettiIcon";
import {
  FilledButton,
  OutlinedButton,
} from "../../ReusableComponents/Buttons/Buttons";
import NoDataFound from "../../ReusableComponents/NoDataFound/NoDataFound";

const PaymentConfirmedModal = ({
  closeModal,
  transactionData,
  isLoadingSessionData,
  currentOrg,
  serviceId,
  searchParams,
}) => {
  const history = useHistory();

  return (
    <Box
      style={{
        padding: "36px",
        borderRadius: "10px",
        backgroundColor: "#ffffff",
        width: "560px",
      }}
    >
      {isLoadingSessionData ? (
        <div
          style={{
            width: "100%",
            height: "100%",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <NoDataFound />
        </div>
      ) : (
        transactionData &&
        transactionData.checkout_id && (
          <Box
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
              gap: "30px",
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <ConfettiIcon />
              <Typography variant="h2" fontWeight={600}>
                Purchase Confirmed!
              </Typography>
              <div style={{ rotate: "90deg" }}>
                <ConfettiIcon />
              </div>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "flex-start",
                gap: "10px",
              }}
            >
              <Typography variant="h5">
                You can check your org transaction history by{" "}
                <Link
                  to={`/organizations/${currentOrg}/?orgInfoType=manage-transactions`}
                >
                  clicking here
                </Link>
              </Typography>

              <Box style={{ width: "90%" }}>
                <Grid container>
                  <Grid item xs={5}>
                    <Typography variant="h5" color="#666666">
                      Transaction ID
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5">:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h5">
                      <Tooltip title={transactionData.checkout_id}>
                        <Typography
                          variant="h5"
                          fontWeight={500}
                          style={{
                            width: "250px",
                            overflow: "hidden",
                            whiteSpace: "nowrap",
                            textOverflow: "ellipsis",
                          }}
                        >
                          {transactionData.checkout_id}
                        </Typography>
                      </Tooltip>
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container mt={1}>
                  <Grid item xs={5}>
                    <Typography variant="h5" color="#666666">
                      License
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5">:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h5">
                      {transactionData.product_name}
                    </Typography>
                  </Grid>
                </Grid>
                <Grid container mt={1}>
                  <Grid item xs={5}>
                    <Typography variant="h5" color="#666666">
                      No. of Licenses
                    </Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5">:</Typography>
                  </Grid>
                  <Grid item xs={5}>
                    <Typography variant="h5">
                      {transactionData.quantity}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
              <Grid container mt={3}>
                <Grid item xs={6} color="#666666">
                  Licenses ID
                </Grid>
                <Grid item xs={2} color="#666666">
                  Type
                </Grid>
                <Grid item xs={2} color="#666666">
                  :
                </Grid>
                <Grid item xs={2} color="#666666">
                  Date
                </Grid>
              </Grid>
              <Divider flexItem />
              {transactionData?.licenses_data?.map((eachLicense) => {
                return (
                  <Grid container mt={1} key={eachLicense.id}>
                    <Grid item xs={6}>
                      {eachLicense.id}
                    </Grid>
                    <Grid item xs={2} color="#666666">
                      {eachLicense.license_type}
                    </Grid>
                    <Grid item xs={2} color="#666666">
                      :
                    </Grid>
                    <Grid item xs={2} color="#666666">
                      {eachLicense.validity
                        ? new Date(eachLicense.validity).toLocaleDateString(
                            "en-US",
                            {
                              month: "numeric",
                              day: "numeric",
                              year: "numeric",
                            }
                          )
                        : "--"}
                    </Grid>
                  </Grid>
                );
              })}
              <Typography variant="h6" color="rgba(0, 0, 0, 0.6)">
                *Check the End dates for the licenses, once a License ID crosses
                the end date, monthly charges will apply to that particular
                License ID. *Cancel the license on or before [End Date], to
                avoid unwanted billing.
              </Typography>
            </div>
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div style={{ width: "200px" }}>
                <OutlinedButton
                  text="Cancel"
                  submit={() => {
                    searchParams.delete("paymentstatus");
                    searchParams.delete("session_id");
                    const currentPath = window.location.pathname.replace(
                      /\/$/,
                      ""
                    );
                    const updatedUrl = `${currentPath}${searchParams.toString()}`;
                    window.history.replaceState(
                      { path: updatedUrl },
                      "",
                      updatedUrl
                    );
                    closeModal();
                  }}
                />
              </div>
              <div style={{ width: "200px" }}>
                <FilledButton
                  text="Manage License"
                  submit={() => {
                    history.push(
                      `/organizations/${currentOrg}/services/${serviceId}/license/?licensetype=available&serviceName=MovieColab`
                    );
                  }}
                />
              </div>
            </div>
          </Box>
        )
      )}
    </Box>
  );
};

export default PaymentConfirmedModal;
