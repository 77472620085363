import React from "react";

const OwnersSvg = ({ size }) => {
  return (
    <svg
      width={size ? size : "30"}
      height={size ? size : "30"}
      viewBox="0 0 30 30"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.20119 19.4911C7.67958 19.4911 7.23286 19.1016 7.14067 18.5664L5.47157 8.87722C5.2891 7.81794 6.50219 7.12342 7.26181 7.85228L10.4992 10.9586C11.0072 11.4461 11.8178 11.3166 12.1625 10.6929L14.7468 6.01708C15.1613 5.26709 16.1995 5.26709 16.614 6.01708L19.1983 10.6929C19.543 11.3166 20.3536 11.4461 20.8616 10.9586L24.099 7.85228C24.8586 7.12342 26.0717 7.81794 25.8892 8.87722L24.2201 18.5664C24.1279 19.1016 23.6812 19.4911 23.1596 19.4911H8.20119ZM24.0608 23.2819C24.0608 24.0401 23.582 24.5455 22.8636 24.5455H8.49717C7.77884 24.5455 7.29996 24.0401 7.29996 23.2819V23.1415C7.29996 22.5212 7.78237 22.0183 8.37744 22.0183H22.9834C23.5784 22.0183 24.0608 22.5212 24.0608 23.1415V23.2819Z"
        fill="black"
      />
    </svg>
  );
};

export default OwnersSvg;
