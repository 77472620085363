import React, { useState, useEffect } from "react";
import { Box, TextField, InputAdornment, Typography } from "@mui/material";
import { MagnifyingGlass, ArrowSquareOut } from "phosphor-react";
import { OutlinedButton } from "../../../ReusableComponents/Buttons/Buttons";
import { fget } from "../../../../API/callsApi";
import CommonLoader from "../../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../../ReusableComponents/NoDataFound/NoDataFound";

const OrganizationServicesUserView = ({ currentOrganization }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [orgServices, serOrgServices] = useState([]);
  const [serviceSearchValue, setServiceSearchValue] = useState("");

  const getOrgServices = async () => {
    setIsLoading(true);
    const url = `/user/service/?org=${currentOrganization}`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        serOrgServices(res.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getOrgServices();
  }, [currentOrganization]);

  return (
    <>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          gap: "15px",
          marginBottom: "5px",
        }}
      >
        <TextField
          label="search"
          variant="outlined"
          size="small"
          value={serviceSearchValue}
          onChange={(e) => setServiceSearchValue(e.target.value)}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <MagnifyingGlass size={22} />
              </InputAdornment>
            ),
          }}
        />
      </div>

      <Box
        mt={2}
        style={{
          height: "93%",
          overflowY: "auto",
          borderRadius: "5px",
          display: "flex",
          flexDirection: "column",
          gap: "12px",
        }}
      >
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CommonLoader />
          </div>
        ) : orgServices && orgServices.length > 0 ? (
          orgServices
            ?.filter((search) => {
              if (serviceSearchValue === undefined) {
                return search;
              } else if (serviceSearchValue === "") {
                return search;
              } else if (
                search?.name
                  ?.toLowerCase()
                  .includes(serviceSearchValue.toLowerCase())
              ) {
                return search;
              }
              return null;
            })
            .map((eachService) => {
              return (
                <Box
                  key={eachService.id}
                  style={{
                    boxSizing: "border-box",
                    width: "100%",
                    padding: "20px",
                    borderRadius: "10px",
                    border: "1px solid #666666",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Typography variant="h2" fontWeight={600}>
                      {eachService.name}
                    </Typography>
                    <div style={{ width: "180px" }}>
                      <OutlinedButton
                        text="Go to App"
                        small={true}
                        submit={() =>
                          (window.location.href =
                            eachService.service_urls.frontend_url)
                        }
                        icon={<ArrowSquareOut />}
                      />
                    </div>
                  </div>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: {
                        xs: "column",
                        lg: "row",
                      },
                      alignItems: "center",
                      justifyContent: "space-between",
                      marginTop: "12px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <Typography variant="h5">Subscribed by</Typography>
                      <Typography variant="h5">:</Typography>
                      <Typography variant="h5">
                        {eachService.license.subscribed_by.first_name}{" "}
                        {eachService.license.subscribed_by.last_name}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <Typography variant="h5">Subscribed on</Typography>
                      <Typography variant="h5">:</Typography>
                      <Typography variant="h5">
                        {new Date(eachService.added_on).toLocaleDateString(
                          "en-US",
                          {
                            month: "numeric",
                            day: "numeric",
                            year: "numeric",
                          }
                        )}
                      </Typography>
                    </div>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "12px",
                      }}
                    >
                      <Typography variant="h5">Expiring on</Typography>
                      <Typography variant="h5">:</Typography>
                      <Typography variant="h5">
                        {" "}
                        {new Date(
                          eachService.license.license_validity
                        ).toLocaleDateString("en-US", {
                          month: "numeric",
                          day: "numeric",
                          year: "numeric",
                        })}
                      </Typography>
                      {((new Date(eachService.license.license_validity) -
                        new Date()) /
                        1000) *
                        3600 *
                        24 <=
                        5 && (
                        <Typography variant="h5" color="#E74100">
                          {" "}
                          Near expiry
                        </Typography>
                      )}
                    </div>
                  </Box>
                </Box>
              );
            })
        ) : (
          <NoDataFound />
        )}
      </Box>
    </>
  );
};

export default OrganizationServicesUserView;
