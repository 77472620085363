import React from "react";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  Typography,
  FormControl,
  InputLabel,
} from "@mui/material";
import useStyles from "./NewOrganizationsOverview.styles";
import ManageOrganizations from "../ManageOrganization/ManageOrganization";
import ManageUsers from "../ManageUsers/ManageUsersOverview/ManageUsersOverview";
import ManageServices from "../ManageServices/ManageServices";
import ManageTransactionAndInvoicesOverview from "../ManageTransactionAndInvoices/ManageTransactionAndInvoicesOverview/ManageTransactionAndInvoicesOverview";
import useAuth from "../../../../hooks/useAuth";

const NewOrganizationsOverview = () => {
  const classes = useStyles();
  const history = useHistory();
  const { organizationsList, currentOrg, setCurrentOrg } = useAuth();
  const [selectedTab, setSelectedTab] = useState("manage-orgs");
  const [activeUsers, setActiveUsers] = useState(true);
  const [transactionType, setTransactionType] = useState("successful");
  const searchParams = new URLSearchParams(
    history.location.search.substring(1)
  );

  useEffect(() => {
    searchParams.get("orgInfoType") &&
      setSelectedTab(searchParams.get("orgInfoType"));
  }, [searchParams.get("orgInfoType")]);

  const handleOrgChange = (value) => {
    const filteredOrg = organizationsList.filter(
      (eachorg) => eachorg.id === value
    );
    setCurrentOrg(filteredOrg[0]);
    // If User role is admin, redirect to following "URL".
    let RedirectURL = null;
    if (
      (filteredOrg.length > 0 && filteredOrg[0]?.role === "admin") ||
      filteredOrg[0]?.role === "owner"
    ) {
      RedirectURL = `/organizations/${filteredOrg[0].id}/?orgInfoType=${selectedTab}`;
    } else if (filteredOrg.length > 0 && filteredOrg[0]?.role === "user") {
      // Redirect
      RedirectURL =
        selectedTab === "manage-transactions"
          ? `/user/organizations/${filteredOrg[0].id}/?orgInfoType=manage-orgs`
          : `/user/organizations/${filteredOrg[0].id}/?orgInfoType=${selectedTab}`;
    }
    history.push(RedirectURL);
  };

  return (
    <Box boxSizing="border-box" px={4} py={3} style={{ height: "100%" }}>
      <Grid container spacing={3} height="100%">
        <Grid item xs={3} lg={2} style={{ height: "100%" }}>
          <FormControl size="small" style={{ width: "100%" }}>
            <InputLabel id="demo-simple-select-helper-label-org">
              Organization
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label-org"
              id="demo-simple-select-helper-label-org"
              variant="outlined"
              label="Organization"
              style={{ width: "100%" }}
              value={currentOrg.id}
              onChange={(event) => handleOrgChange(event.target.value)}
            >
              {organizationsList.map((eachOrg) => {
                return (
                  <MenuItem value={eachOrg.id} key={eachOrg.id}>
                    {eachOrg.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box mt={2} className={classes.listContainer}>
            <List style={{ padding: "0px", height: "max-content" }}>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("orgInfoType", "manage-orgs");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  selectedTab === "manage-orgs"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemText primary="Manage Organization" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("orgInfoType", "manage-users");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  selectedTab === "manage-users"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemText primary="Manage User & Invities" />
              </ListItem>
              {selectedTab === "manage-users" && (
                <ListItem
                  button={true}
                  onClick={() => {
                    setActiveUsers(true);
                  }}
                  className={
                    selectedTab === "manage-users" && activeUsers
                      ? classes.sublistItmBtnSelected
                      : classes.listItemBtn
                  }
                >
                  <Typography>
                    <div
                      style={{
                        backgroundColor: "#000000",
                        borderRadius: "50%",
                        height: "7px",
                        width: "7px",
                        margin: "0px 10px",
                      }}
                    />
                  </Typography>
                  <ListItemText primary="Active Users" />
                </ListItem>
              )}
              {selectedTab === "manage-users" && (
                <ListItem
                  button={true}
                  onClick={() => {
                    setActiveUsers(false);
                  }}
                  className={
                    selectedTab === "manage-users" && !activeUsers
                      ? classes.sublistItmBtnSelected
                      : classes.listItemBtn
                  }
                >
                  <Typography>
                    <div
                      style={{
                        backgroundColor: "#000000",
                        borderRadius: "50%",
                        height: "7px",
                        width: "7px",
                        margin: "0px 10px",
                      }}
                    />
                  </Typography>
                  <ListItemText primary="Invitations" />
                </ListItem>
              )}
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("orgInfoType", "manage-services");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  selectedTab === "manage-services"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemText primary="Buy & Manage Services" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("orgInfoType", "manage-transactions");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  selectedTab === "manage-transactions"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemText primary="Transactions & Invoices" />
              </ListItem>
              {selectedTab === "manage-transactions" && (
                <>
                  <ListItem
                    button={true}
                    onClick={() => {
                      setTransactionType("successful");
                    }}
                    className={
                      selectedTab === "manage-transactions" &&
                      transactionType === "successful"
                        ? classes.sublistItmBtnSelected
                        : classes.listItemBtn
                    }
                  >
                    <Typography>
                      <div
                        style={{
                          backgroundColor: "#000000",
                          borderRadius: "50%",
                          height: "7px",
                          width: "7px",
                          margin: "0px 10px",
                        }}
                      />
                    </Typography>
                    <ListItemText primary="Successful" />
                  </ListItem>
                  <ListItem
                    button={true}
                    onClick={() => {
                      setTransactionType("failed");
                    }}
                    className={
                      selectedTab === "manage-transactions" &&
                      transactionType === "failed"
                        ? classes.sublistItmBtnSelected
                        : classes.listItemBtn
                    }
                  >
                    <Typography>
                      <div
                        style={{
                          backgroundColor: "#000000",
                          borderRadius: "50%",
                          height: "7px",
                          width: "7px",
                          margin: "0px 10px",
                        }}
                      />
                    </Typography>
                    <ListItemText primary="Failed" />
                  </ListItem>
                </>
              )}
            </List>
          </Box>
        </Grid>
        <Grid item xs={9} lg={10} style={{ height: "100%" }}>
          {selectedTab === "manage-orgs" && (
            <ManageOrganizations
              currentOrganization={currentOrg.id}
              organizationsList={organizationsList}
            />
          )}
          {selectedTab === "manage-users" && (
            <ManageUsers activeUsers={activeUsers} selectedOrg={currentOrg} />
          )}
          {selectedTab === "manage-services" && (
            <ManageServices currentOrganization={currentOrg.id} />
          )}
          {selectedTab === "manage-transactions" && (
            <ManageTransactionAndInvoicesOverview
              transactionType={transactionType}
            />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default NewOrganizationsOverview;
