import { Button } from "@mui/material";
import React from "react";
import useStyles from "./Buttons.styles";

export const FilledButton = ({ text, submit, icon, small, disabled }) => {
  const classes = useStyles();
  return (
    <Button
      disabled={disabled}
      className={classes.filledBtn}
      sx={{
        height: small ? "35px" : "45px",
        "&.Mui-disabled": {
          backgroundColor: "#BBBBBB !important",
        },
      }}
      startIcon={icon}
      onClick={submit}
    >
      {text}
    </Button>
  );
};

export const OutlinedButton = ({ text, submit, icon, small, disabled }) => {
  const classes = useStyles();
  return (
    <Button
      disabled={disabled}
      sx={{
        height: small ? "35px" : "45px",
        "&.Mui-disabled": {
          backgroundColor: "#D9D9D9 !important",
        },
      }}
      className={classes.outlinedBtn}
      onClick={submit}
      startIcon={icon}
    >
      {text}
    </Button>
  );
};

export const RedFilledButton = ({ text, submit, icon, small, disabled }) => {
  const classes = useStyles();
  return (
    <Button
      disabled={disabled}
      className={classes.redFilledBtn}
      sx={{
        height: small ? "35px" : "45px",
        "&.Mui-disabled": {
          backgroundColor: "#E85151 !important",
        },
      }}
      onClick={submit}
      startIcon={icon}
    >
      {text}
    </Button>
  );
};
