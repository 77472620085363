import React from "react";

const InvitationPlus = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.66732 12.6667C8.66732 12.5717 8.672 12.4778 8.6799 12.3847C8.69713 12.1814 8.54366 12 8.33968 12H3.00065C2.81656 12 2.66732 11.8508 2.66732 11.6667V5.33335L7.82399 8.55627C7.93208 8.62383 8.06923 8.62383 8.17732 8.55627L13.334 5.33335V8.44431C13.334 8.60726 13.4522 8.74481 13.6106 8.78315C13.796 8.82802 13.9759 8.88595 14.1499 8.95568C14.3877 9.05098 14.6673 8.88733 14.6673 8.63113V4.00002C14.6673 3.26669 14.0673 2.66669 13.334 2.66669H2.66732C1.93398 2.66669 1.33398 3.26669 1.33398 4.00002V12C1.33398 12.7334 1.93398 13.3334 2.66732 13.3334H8.33968C8.54366 13.3334 8.69713 13.152 8.6799 12.9487C8.672 12.8555 8.66732 12.7617 8.66732 12.6667ZM13.1015 4.00002C13.1685 4.00002 13.1937 4.08772 13.1369 4.12322L8.17732 7.22294C8.06923 7.29049 7.93208 7.29049 7.82399 7.22294L2.86444 4.12322C2.80764 4.08772 2.83279 4.00002 2.89977 4.00002H13.1015ZM13.0007 10C13.1847 10 13.334 10.1493 13.334 10.3334V12H15.0007C15.1847 12 15.334 12.1493 15.334 12.3334V13C15.334 13.1841 15.1847 13.3334 15.0007 13.3334H13.334V15C13.334 15.1841 13.1847 15.3334 13.0007 15.3334H12.334C12.1499 15.3334 12.0007 15.1841 12.0007 15V13.3334H10.334C10.1499 13.3334 10.0007 13.1841 10.0007 13V12.3334C10.0007 12.1493 10.1499 12 10.334 12H12.0007V10.3334C12.0007 10.1493 12.1499 10 12.334 10H13.0007Z"
        fill={color}
      />
    </svg>
  );
};

export default InvitationPlus;
