import React from "react";
import { useParams } from "react-router-dom";
import TopBannerLayout from "../components/SideBarAndNavbar/TopBannerLayout";
import NewOrganizationsOverview from "../components/NewOrganizations/NewOrganizationsAdminOwnerView/NewOrganizationsOverview/NewOrganizationsOverview";
import OrganizationOverviewUserView from "../components/NewOrganizations/NewOrganizationsUserView/OrganizationOverviewUserView/OrganizationOverviewUserView";
import LicensesOverview from "../components/LicensesPage/LicensesOverview/LicensesOverview";
import PaymentOverview from "../components/PaymentPage/PaymentOverview";
import LicenseAndServiceSetupBanner from "../components/SideBarAndNavbar/LicenseAndServiceSetupBanner";
import NotFound from "../components/NotFound";
import useAuth from "../hooks/useAuth";

export const OrganizationComponent = () => {
  const params = useParams();
  const { checkUserRole } = useAuth();

  return checkUserRole(params) === "admin" ||
    checkUserRole(params) === "owner" ? (
    <TopBannerLayout>
      <NewOrganizationsOverview />
    </TopBannerLayout>
  ) : (
    <NotFound />
  );
};

export const UserOrganizationComponent = () => {
  const params = useParams();
  const { checkUserRole } = useAuth();

  return checkUserRole(params) === "user" ? (
    <TopBannerLayout>
      <OrganizationOverviewUserView />
    </TopBannerLayout>
  ) : (
    <NotFound />
  );
};

export const LicenseOverviewComponent = () => {
  const params = useParams();
  const { checkUserRole } = useAuth();

  return checkUserRole(params) === "admin" ||
    checkUserRole(params) === "owner" ? (
    <TopBannerLayout>
      <LicensesOverview />
    </TopBannerLayout>
  ) : (
    <NotFound />
  );
};

export const PaymentOverviewComponent = () => {
  const params = useParams();
  const { checkUserRole } = useAuth();

  return checkUserRole(params) === "owner" ? (
    <LicenseAndServiceSetupBanner>
      <PaymentOverview />
    </LicenseAndServiceSetupBanner>
  ) : (
    <NotFound />
  );
};
