import React from "react";
import { Box, Button, Typography } from "@mui/material";
import TopBanner from "../../assets/TopBanner.png";
import Navbar from "../NewNavbar/Navbar";

const ServicePageBanner = ({ children }) => {
  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        boxSizing: "border-box !important",
        overflow: "hidden",
      }}
    >
      <Box style={{ height: "33%" }}>
        <div style={{ height: "29%" }}>
          <Navbar servicePage={true} />
        </div>
        <Box
          style={{
            height: "71%",
            position: "relative",
          }}
        >
          <Box display="flex" style={{ height: "100%" }}>
            <Box
              p={4}
              style={{
                width: "50%",
                height: "100%",
                backgroundImage: `url(${TopBanner})`,
                backgroundSize: "100% 100%",
                color: "#FFFFFF",
                boxSizing: "border-box",
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography variant="h3">Movie Colab</Typography>
              <Typography variant="h6">
                Collaboration Software for Virtual Production
              </Typography>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: "20px",
                  marginTop: "30px",
                }}
              >
                <Button
                  style={{
                    border: "1px solid #FFFFFF",
                    color: "#FFFFFF",
                    backgroundColor: "rgba(0, 0, 0, 0.6)",
                    textTransform: "none",
                  }}
                >
                  Watch Demo
                </Button>
              </div>
            </Box>
            <Box
              style={{
                width: "50%",
                height: "100%",
                backgroundColor: "#6C63FF",
              }}
            ></Box>
          </Box>
        </Box>
      </Box>
      <Box
        style={{
          height: "66%",
          boxSizing: "border-box",
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default ServicePageBanner;
