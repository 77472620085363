import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
} from "@mui/material";
import { fget } from "../../../API/callsApi";
import useStyles from "../LicensesOverview/LicensesOverview.styles";
import CommonLoader from "../../ReusableComponents/CommonLoader/CommonLoader";
import NoDataFound from "../../ReusableComponents/NoDataFound/NoDataFound";
import ActiveLicenseTableItem from "./ActiveLicenseTableItem";
import CustomPagination from "../../ReusableComponents/CustomPagination/CustomPagination";

const ActiveLicensesTable = ({ licenseSearchValue }) => {
  const classes = useStyles();
  const params = useParams();
  const [activeLicense, setActiveLicense] = useState({
    count: 0,
    data: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [refreshCounter, setRefreshCounter] = useState(0);
  const [licensedUsers, setLicensedUsers] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [updateOnPageChange, setUpdateOnPageChange] = useState(0);

  const handlePageChange = (pageNo) => {
    setCurrentPage(pageNo);
    setUpdateOnPageChange(updateOnPageChange + 1);
  };

  const handleRefreshCounter = () => {
    setRefreshCounter(!refreshCounter);
  };

  const getLicenses = async () => {
    if (params.sid) {
      setIsLoading(true);
      try {
        const res = await fget({
          url: `/admin/license/?org=${params.orgId}&service=${params.sid}&status=active&page=${currentPage}`,
        });
        const newRes = res.data.results.map((eachLicense) => {
          return eachLicense.user.id;
        });
        setLicensedUsers(newRes);
        setActiveLicense({
          count: res.data.count,
          data: res.data.results,
        });
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getLicenses();
  }, [params.sid, refreshCounter, updateOnPageChange]);

  return (
    <>
      <TableContainer style={{ height: "8%", overflow: "hidden" }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                License ID
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                User
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Subscribed on
              </TableCell>
              <TableCell
                align="center"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                Expiring on
              </TableCell>
              <TableCell
                align="right"
                className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
              >
                <span style={{ marginRight: "40px" }}>Action</span>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
      </TableContainer>
      <Box
        style={{
          height: activeLicense.count > 50 ? "80%" : "83%",
          overflowY: "auto",
          border: "1px solid black",
          borderRadius: "5px",
        }}
      >
        <TableContainer>
          <Table aria-label="simple table">
            <TableBody style={{ height: "100%" }}>
              {isLoading ? (
                <div className={classes.commonLoader}>
                  <CommonLoader />
                </div>
              ) : activeLicense && activeLicense.data.length > 0 ? (
                activeLicense.data
                  ?.filter((search) => {
                    if (licenseSearchValue === undefined) {
                      return search;
                    } else if (licenseSearchValue === "") {
                      return search;
                    } else if (
                      search.license_number
                        .toLowerCase()
                        .includes(licenseSearchValue.toLowerCase())
                    ) {
                      return search;
                    } else if (
                      search.user?.first_name
                        .toLowerCase()
                        .includes(licenseSearchValue.toLowerCase())
                    ) {
                      return search;
                    } else if (
                      search.user?.last_name
                        .toLowerCase()
                        .includes(licenseSearchValue.toLowerCase())
                    ) {
                      return search;
                    }
                    return null;
                  })
                  .map((eachLicense) => {
                    return (
                      <ActiveLicenseTableItem
                        key={eachLicense.id}
                        eachLicense={eachLicense}
                        handleRefreshCounter={handleRefreshCounter}
                        licensedUsers={licensedUsers}
                      />
                    );
                  })
              ) : (
                <NoDataFound />
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
      {activeLicense.count > 50 && (
        <footer style={{ width: "100%", position: "relative" }}>
          <CustomPagination
            handlePageChange={handlePageChange}
            currentPage={currentPage}
            totalItem={activeLicense?.count}
            totalPage={activeLicense ? Math.ceil(activeLicense?.count / 50) : 0}
            type="Active Licenses"
          />
        </footer>
      )}
    </>
  );
};

export default ActiveLicensesTable;
