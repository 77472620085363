import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Box, Button, Tabs, Tab, Typography } from "@mui/material";
import VigaLogoWhite from "../../assets/VigaLogoWhite.png";
import { User } from "phosphor-react";
import { Logout } from "@mui/icons-material";
import { deleteCookies } from "../../authorization";
import useAuth from "../../hooks/useAuth";

const Navbar = () => {
  const history = useHistory();
  const location = useLocation();
  const [currentTab, setCurrentTab] = useState(0);
  const { currentOrg } = useAuth();

  useEffect(() => {
    const pathSegments = location.pathname.split("/");
    if (pathSegments.includes("dashboard")) {
      setCurrentTab(0);
    } else if (
      pathSegments.includes("organizations") &&
      !pathSegments.includes("dashboard")
    ) {
      setCurrentTab(1);
    } else if (
      pathSegments.includes("services") &&
      !pathSegments.includes("organizations") &&
      !pathSegments.includes("dashboard")
    ) {
      setCurrentTab(2);
    }
  }, [location]);

  const logOut = () => {
    localStorage.removeItem("refresh");
    localStorage.removeItem("access");
    deleteCookies();
    toast.success("Logged out successfully");
    history.push("/logout/2");
  };

  return (
    <Box
      px={3}
      py={1}
      style={{
        width: "100%",
        backgroundColor: "#000000",
        color: "#FFFFFF",
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        boxSizing: "border-box",
        height: "100%",
      }}
    >
      <Box style={{ display: "flex", alignItems: "center", gap: "64px" }}>
        <img height={24} src={VigaLogoWhite} />
        <Tabs
          value={currentTab}
          onChange={(e, newValue) => setCurrentTab(newValue)}
          aria-label="basic tabs example"
        >
          <Tab
            label={<Typography variant="h6">Dashboard</Typography>}
            style={{
              color: "white",
              textTransform: "none",
              padding: "12px 16px",
            }}
            onClick={() => {
              history.push("/dashboard/?dashboardType=services");
            }}
          />
          <Tab
            label={<Typography variant="h6">Organizations</Typography>}
            style={{
              color: "white",
              textTransform: "none",
              padding: "12px 16px",
            }}
            onClick={() => {
              currentOrg.role === "admin" || currentOrg.role === "owner"
                ? history.push(
                    `/organizations/${currentOrg.id}/?orgInfoType=manage-orgs`
                  )
                : currentOrg.role === "user" &&
                  history.push(
                    `/user/organizations/${currentOrg.id}/?orgInfoType=manage-orgs`
                  );
            }}
          />
          <Tab
            label={<Typography variant="h6">Services</Typography>}
            style={{
              color: "white",
              textTransform: "none",
              padding: "12px 16px",
            }}
            onClick={() => {
              history.push("/services");
            }}
          />
        </Tabs>
      </Box>
      <div>
        <Button
          onClick={() => {
            history.push("/dashboard/?dashboardType=myprofile");
          }}
        >
          <User size={26} color="white" />
        </Button>
        <Button onClick={logOut}>
          <Logout style={{ color: "#FFFFFF" }} />
        </Button>
      </div>
    </Box>
  );
};

export default Navbar;
