import React, { useState } from "react";
import { Box, Button, Typography, TextField, Grid } from "@mui/material";
import { CaretLeft, UserPlus, X } from "phosphor-react";
import { FilledButton } from "../../../ReusableComponents/Buttons/Buttons";
import { fpost } from "../../../../API/callsApi";
import useAuth from "../../../../hooks/useAuth";
import toast from "react-hot-toast";

const AddNewUserModal = ({ closeModal }) => {
  const [userData, setUserData] = useState("");
  const [step, setStep] = useState("1");

  const handleCheckUserExist = async (email) => {
    try {
      let obj = {
        url: `/user-exists/`,
        data: {
          email,
        },
      };
      const res = await fpost(obj);
      if (res.data.user_exists) {
        setUserData(res.data);
        setStep("3");
      } else {
        setUserData({ email });
        setStep("2");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const goBack = () => {
    setStep("1");
    setUserData("");
  };

  return (
    <Box
      style={{
        width: "600px",
        padding: "36px",
        borderRadius: "10px",
        backgroundColor: "#FFFFFF",
      }}
    >
      {!userData && step === "1" && (
        <AddUserEmail
          closeModal={closeModal}
          handleCheckUserExist={handleCheckUserExist}
        />
      )}
      {userData.email && step === "2" && (
        <AddNewUser
          closeModal={closeModal}
          userData={userData}
          goBack={goBack}
        />
      )}
      {userData.email && step === "3" && (
        <AddExistingUser
          closeModal={closeModal}
          userData={userData}
          goBack={goBack}
        />
      )}
    </Box>
  );
};

const AddUserEmail = ({ closeModal, handleCheckUserExist }) => {
  const [userEmail, setUserEmail] = useState("");

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
          <UserPlus size={30} />
          <Typography variant="h2" fontWeight={600}>
            Add New User
          </Typography>
        </div>
        <Button onClick={closeModal}>
          <X size={30} color="black" />
        </Button>
      </div>
      <TextField
        value={userEmail}
        onChange={(event) => setUserEmail(event.target.value)}
        style={{ margin: "30px 0px" }}
        fullWidth
        required
        type="email"
        label="Email ID of New User"
      />
      <div style={{ width: "200px", marginLeft: "auto", marginRight: "auto" }}>
        <FilledButton
          disabled={userEmail ? false : true}
          text="Next"
          submit={() => handleCheckUserExist(userEmail)}
        />
      </div>
    </div>
  );
};

const AddNewUser = ({ closeModal, userData, goBack }) => {
  const { currentOrg } = useAuth();
  const [userEmail, setUserEmail] = useState(userData.email);
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const inviteNewUser = async () => {
    let formData = {
      email: userEmail,
      first_name: firstName,
      last_name: lastName,
    };
    try {
      let obj = {
        url: `/organization/${currentOrg.id}/invite-user/`,
        data: formData,
      };
      const res = await fpost(obj);
      if (res.status === 200 || res.status === 201) {
        toast.success("Invitation sent to user");
        closeModal();
      }
    } catch (error) {
      toast.error("Error on Sending Invitation");
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button style={{ backgroundColor: "black" }} onClick={goBack}>
          <CaretLeft size={30} color="white" />
        </Button>
        <Typography variant="h2" fontWeight={600}>
          Add New User
        </Typography>

        <Button onClick={closeModal}>
          <X size={30} color="black" />
        </Button>
      </div>
      <TextField
        style={{ marginTop: "30px" }}
        value={userEmail}
        onChange={(event) => setUserEmail(event.target.value)}
        fullWidth
        required
        type="email"
        label="Email ID of New User"
      />
      <Grid
        container
        columnSpacing={3}
        style={{ marginTop: "24px", marginBottom: "30px" }}
      >
        <Grid item xs={6}>
          <TextField
            value={firstName}
            onChange={(event) => setFirstName(event.target.value)}
            fullWidth
            required
            type="email"
            label="First Name"
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            value={lastName}
            onChange={(event) => setLastName(event.target.value)}
            fullWidth
            required
            type="email"
            label="Last Name"
          />
        </Grid>
      </Grid>
      <div style={{ width: "200px", marginLeft: "auto", marginRight: "auto" }}>
        <FilledButton
          disabled={userEmail && firstName && lastName ? false : true}
          text="Add New User"
          submit={() => inviteNewUser()}
        />
      </div>
    </div>
  );
};

const AddExistingUser = ({ closeModal, userData, goBack }) => {
  const { currentOrg } = useAuth();

  const inviteNewUser = async () => {
    let formData = {
      email: userData.email,
    };
    try {
      let obj = {
        url: `/organization/${currentOrg.id}/invite-user/`,
        data: formData,
      };
      const res = await fpost(obj);
      if (res.status === 200 || res.status === 201) {
        toast.success("Invitation sent to user");
        closeModal();
      }
    } catch (error) {
      toast.error("Error on Sending Invitation");
    }
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <div
        style={{
          width: "100%",
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Button style={{ backgroundColor: "black" }} onClick={goBack}>
          <CaretLeft size={30} color="white" />
        </Button>
        <Typography variant="h2" fontWeight={600}>
          User Found
        </Typography>
        <Button onClick={closeModal}>
          <X size={30} color="black" />
        </Button>
      </div>
      <Typography
        variant="h5"
        color="rgba(0, 0, 0, 0.6)"
        textAlign="center"
        mt={4}
      >
        A user with the following details already exists in the database. Would
        you like to go ahead with this user?
      </Typography>
      <Typography
        variant="h5"
        color="rgba(0, 0, 0, 0.6)"
        textAlign="center"
        margin={2}
      >
        Email: <span style={{ color: "black" }}>{userData.email}</span>
      </Typography>
      <Typography
        variant="h5"
        color="rgba(0, 0, 0, 0.6)"
        textAlign="center"
        mb={4}
      >
        Name:{" "}
        <span style={{ color: "black" }}>
          {userData.first_name} {userData.last_name}
        </span>
      </Typography>
      <div style={{ width: "200px", marginLeft: "auto", marginRight: "auto" }}>
        <FilledButton
          disabled={false}
          text="Add New User"
          submit={() => inviteNewUser()}
        />
      </div>
    </div>
  );
};

export default AddNewUserModal;
