import React, { useState } from "react";
import {
  Box,
  Card,
  CardContent,
  Typography,
  CircularProgress,
  Button,
  TextField,
} from "@mui/material";
import {
  OutlinedButton,
  RedFilledButton,
} from "../../ReusableComponents/Buttons/Buttons";
import { Trash } from "phosphor-react";
import LeaveIcon from "../Svgs/LeaveIcon";

const DeleteModal = ({
  handleDelete,
  closeModal,
  text1,
  text2,
  btnText,
  isRemoving,
  leave,
  license,
  cancleLicense,
  removeOrg,
}) => {
  const [orgName, setOrgName] = useState("");

  return (
    <Card
      elevation={0}
      sx={{
        backgroundColor: "white",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        p: 4,
        borderRadius: "10px",
      }}
    >
      <CardContent
        sx={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          height: "100%",
          justifyContent: "space-around",
        }}
      >
        {leave ? (
          <Typography
            variant="h1"
            style={{
              textAlign: "center",
              fontWeight: 600,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <LeaveIcon /> Leave Confirmation
          </Typography>
        ) : cancleLicense ? (
          <Typography
            variant="h1"
            style={{
              fontWeight: "600",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Trash size={36} color="#E01717" /> Cancle Confirmation
          </Typography>
        ) : (
          <Typography
            variant="h1"
            style={{
              fontWeight: "600",
              textAlign: "center",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Trash size={36} color="#E01717" />{" "}
            {license ? "Disable License" : "Delete Confirmation"}
          </Typography>
        )}
        <Box
          mt={4}
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Typography variant="h5">{text1}</Typography>
          <Typography
            variant="h3"
            style={{
              fontWeight: "600",
              margin: "20px 0px",
              width: "90%",
              textAlign: "center",
            }}
          >
            {text2}
          </Typography>
          {removeOrg && (
            <Box mb={3}>
              <Typography mb={2} variant="h3">
                If yes, then type out the name of your org
              </Typography>
              <TextField
                value={orgName}
                fullWidth
                onChange={(event) => setOrgName(event.target.value)}
              />
            </Box>
          )}
          <Typography variant="h5" color="#666666">
            This action cannot be undone!
          </Typography>
        </Box>

        <Box
          mt={4}
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Box sx={{ width: "200px" }}>
            <OutlinedButton
              text={cancleLicense ? "Don't Cancle" : "Cancel"}
              submit={closeModal}
            />
          </Box>
          <Box sx={{ width: "200px" }}>
            {isRemoving ? (
              <Button
                style={{
                  width: "100%",
                  backgroundColor: "red",
                  borderRadius: "0px",
                  height: "40px",
                }}
                disableElevation
              >
                <CircularProgress style={{ color: "white" }} />
              </Button>
            ) : (
              <RedFilledButton
                disabled={removeOrg && !orgName}
                text={btnText}
                submit={() => {
                  if (removeOrg) {
                    handleDelete(orgName);
                  } else {
                    handleDelete();
                  }
                }}
              />
            )}
          </Box>
        </Box>
      </CardContent>
    </Card>
  );
};

export default DeleteModal;
