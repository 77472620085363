import React from "react";

const SubscriptionSvg = () => {
  return (
    <svg
      width="142"
      height="147"
      viewBox="0 0 152 157"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        x="9.90234"
        y="68.9385"
        width="3.64045"
        height="59.7417"
        rx="1.82023"
        fill="#FFBB56"
      />
      <rect
        x="70.2734"
        y="56.5049"
        width="3.64045"
        height="70.659"
        rx="1.82023"
        fill="#FFBB56"
      />
      <path
        d="M8.38672 78.3395C8.38672 75.0207 10.7171 72.1581 13.9669 71.485L67.6186 60.3715C71.9633 59.4716 76.0384 62.7891 76.0384 67.226V88.6497C76.0384 91.9684 73.708 94.831 70.4583 95.5042L16.8066 106.618C12.4619 107.518 8.38672 104.2 8.38672 99.7631V78.3395Z"
        fill="black"
      />
      <rect
        x="97.8828"
        y="128.983"
        width="4.54887"
        height="70.6854"
        rx="2.27443"
        transform="rotate(90 97.8828 128.983)"
        fill="#FFBB56"
      />
      <rect
        x="106.375"
        y="99.5674"
        width="4.54887"
        height="70.6854"
        rx="2.27443"
        transform="rotate(90 106.375 99.5674)"
        fill="#FFBB56"
      />
      <path
        d="M1.04017 109.113C4.34914 80.7657 34.9908 71.3291 49.4779 70.9658C94.8483 67.422 94.7379 103.067 94.8483 128.983L0.532327 128.983C0.532815 121.717 0.307417 115.39 1.04017 109.113Z"
        fill="#6C65D9"
      />
      <path
        d="M28.7154 81.676C19.1284 68.1189 31.8636 61.1678 40.223 59.1411C40.7286 59.0185 41.2209 59.3623 41.3012 59.8761L41.8641 63.4776C41.8952 63.6762 41.8596 63.8794 41.7629 64.0555L37.1526 72.4595C36.927 72.8707 37.0552 73.3875 37.4381 73.6587C56.726 87.3196 59.7366 113.981 59.6678 127.945C59.6654 128.442 59.2597 128.833 58.7623 128.833H40.3047C39.7787 128.833 39.3626 128.391 39.3892 127.866C39.9549 116.692 38.3793 95.3417 28.7154 81.676Z"
        fill="#A09AFB"
      />
      <path
        d="M72.2489 75.7623C74.8601 67.0868 67.7489 63.4803 62.9961 62.4189L25.9848 73.4878C22.4657 74.337 21.5859 77.9862 21.5859 79.7046C25.2264 78.249 30.4848 80.3111 32.659 81.5242C50.4062 91.4259 67.0916 92.8963 72.2489 75.7623Z"
        fill="#A09AFB"
      />
      <path
        d="M69.7988 100.72C70.2727 95.5636 64.9629 82.186 61.7709 75.076C61.5854 74.6627 61.9009 74.2011 62.3537 74.2203L72.2285 74.6394C72.4519 74.6488 72.6519 74.7804 72.7491 74.9817L85.4932 101.373C85.6141 101.623 85.5508 101.927 85.325 102.089C81.4926 104.839 76.5654 101.767 70.4592 101.51C70.0603 101.493 69.7623 101.117 69.7988 100.72Z"
        fill="#A09AFB"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M68.5765 81.2849C68.8762 81.4347 68.9977 81.799 68.8478 82.0986C67.4077 84.9778 65.1678 86.8787 62.054 88.435C61.7543 88.5848 61.3898 88.4633 61.24 88.1637C61.0901 87.8641 61.2116 87.4998 61.5113 87.35C64.465 85.8737 66.4723 84.1355 67.7624 81.5561C67.9123 81.2565 68.2768 81.1351 68.5765 81.2849Z"
        fill="#252A38"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M42.7783 79.9629C42.971 79.6889 43.3494 79.6228 43.6236 79.8154C49.3919 83.8673 52.479 90.2321 54.6099 95.7096C54.7314 96.0218 54.5766 96.3733 54.2643 96.4947C53.952 96.6161 53.6004 96.4614 53.4789 96.1492C51.3627 90.7094 48.3823 84.6407 42.9259 80.8079C42.6517 80.6153 42.5857 80.237 42.7783 79.9629Z"
        fill="#252A38"
      />
      <path
        d="M33.4343 65.0089C39.2409 61.5263 47.7087 61.623 51.2168 62.1067C58.9589 63.0741 72.8463 66.7502 66.4591 73.7156C60.0719 80.6809 47.5878 78.0689 42.1441 75.8922C36.8215 73.7155 27.6278 68.4915 33.4343 65.0089Z"
        fill="#252A38"
      />
      <path
        d="M40.3317 75.1664L39.9688 55.9395L60.2916 62.4694L61.3803 76.9803C55.5738 79.8824 44.6866 77.343 40.3317 75.1664Z"
        fill="#E5986D"
      />
      <path
        d="M61.018 70.813C54.0502 71.9739 46.9855 66.2179 44.3242 63.1948L60.6551 65.3715L61.018 70.813Z"
        fill="#252A38"
      />
      <path
        d="M67.304 43.1154C69.1314 54.7942 67.4395 65.1719 59.2008 66.4601C45.0474 68.6731 39.2964 59.4591 37.469 47.7803C35.6416 36.1014 40.839 25.5895 49.0777 24.3013C57.3164 23.0132 65.4766 31.4365 67.304 43.1154Z"
        fill="#E6986D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M61.3282 37.5156C60.6351 37.6184 60.0138 37.98 59.6708 38.2738C59.3665 38.5346 58.9083 38.4994 58.6474 38.1951C58.3865 37.8909 58.4218 37.4329 58.7261 37.1721C59.2299 36.7404 60.0966 36.2313 61.1151 36.0802C62.1703 35.9237 63.377 36.1578 64.4295 37.2099C64.713 37.4933 64.713 37.9527 64.4295 38.236C64.1461 38.5193 63.6865 38.5193 63.4031 38.236C62.7137 37.5469 61.9848 37.4182 61.3282 37.5156Z"
        fill="#1B1D28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.1205 41.4977C52.5206 41.4644 52.8774 41.7075 52.9174 42.0408L53.2814 45.0733C53.3214 45.4066 53.0295 45.7038 52.6294 45.7372C52.2293 45.7705 51.8725 45.5273 51.8325 45.194L51.4685 42.1615C51.4285 41.8282 51.7204 41.531 52.1205 41.4977Z"
        fill="#1B1D28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M62.433 40.588C62.8331 40.5547 63.1899 40.7979 63.2299 41.1312L63.5939 44.1637C63.6339 44.497 63.342 44.7942 62.9419 44.8275C62.5418 44.8608 62.185 44.6177 62.145 44.2844L61.781 41.2519C61.741 40.9186 62.0329 40.6213 62.433 40.588Z"
        fill="#1B1D28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M53.2173 39.1523C51.2667 38.6649 50.0029 39.7613 49.6423 40.302C49.42 40.6354 48.9693 40.7255 48.6358 40.5032C48.3022 40.281 48.2121 39.8305 48.4345 39.4971C49.0416 38.5867 50.8747 37.0712 53.5693 37.7446C53.9582 37.8418 54.1947 38.2357 54.0974 38.6244C54.0002 39.0132 53.6061 39.2495 53.2173 39.1523Z"
        fill="#1B1D28"
      />
      <path
        d="M44.5679 45.4849C45.2238 49.6767 44.6165 53.4015 41.6595 53.8639C36.5795 54.6581 34.5154 51.351 33.8595 47.1593C33.2036 42.9675 35.069 39.1945 38.0261 38.7322C40.9831 38.2698 43.912 41.2931 44.5679 45.4849Z"
        fill="#E6986D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M36.6633 45.876C37.0642 45.876 37.3891 46.2008 37.3891 46.6015C37.3891 47.0885 37.4732 47.8056 37.7001 48.3727C37.9358 48.9617 38.207 49.1409 38.4779 49.1409C38.8787 49.1409 39.2037 49.4658 39.2037 49.8665C39.2037 50.2672 38.8787 50.592 38.4779 50.592C37.2971 50.592 36.661 49.6829 36.3523 48.9116C36.0349 48.1182 35.9375 47.2029 35.9375 46.6015C35.9375 46.2008 36.2625 45.876 36.6633 45.876Z"
        fill="#1B1D28"
      />
      <path
        d="M41.755 41.646C43.5753 51.047 45.5775 55.8384 53.5865 55.5958C55.7399 55.5306 57.5987 55.3742 59.1988 55.1205L61.019 53.7763L63.446 53.9843C65.681 53.0331 66.9033 51.7438 67.5698 49.917C67.6853 49.6003 68.1566 49.6229 68.1756 49.9594C68.4712 55.1907 66.9538 65.5605 59.8056 66.968C52.4444 68.4174 43.4031 66.5179 39.783 57.1121C36.7494 51.9568 42.2101 53.0182 38.418 44.5269L41.755 41.646Z"
        fill="#C9C9C9"
      />
      <path
        d="M68.4996 56.0397C64.1056 57.7105 61.8074 55.639 61.0303 54.021C60.915 53.7809 60.942 53.4965 61.1 53.2821C62.9464 50.7765 66.5023 52.7827 68.7308 54.6703C69.2068 55.0734 69.0826 55.818 68.4996 56.0397Z"
        fill="#ECECEC"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M58.9323 43.9497C59.296 43.8413 59.6787 44.0481 59.7872 44.4116C60.2563 45.9841 60.8684 47.2058 61.4052 48.2772C61.6382 48.7424 61.8571 49.1793 62.044 49.6043C62.3337 50.2634 62.5702 50.9553 62.5039 51.6297C62.4309 52.3709 61.5569 53.2578 60.8505 53.7244C60.5339 53.9336 60.1076 53.8466 59.8984 53.5301C59.6891 53.2136 59.7762 52.7875 60.0928 52.5783C60.5484 52.2773 61.1137 51.7238 61.1362 51.4953C61.1652 51.2 61.0645 50.7909 60.7858 50.157C60.6405 49.8263 60.4501 49.4456 60.2359 49.0174C59.6845 47.915 58.9754 46.4973 58.4702 44.8043C58.3618 44.4407 58.5687 44.0581 58.9323 43.9497Z"
        fill="#1B1D28"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M57.4724 54.7106C57.8675 54.6021 58.2752 54.8366 58.3829 55.2343C58.5609 55.8911 58.7937 56.5502 59.3673 57.0674C59.9313 57.576 60.937 58.0433 62.8584 58.0433C63.2679 58.0433 63.5999 58.3775 63.5999 58.7898C63.5999 59.202 63.2679 59.5362 62.8584 59.5362C60.7013 59.5362 59.2968 59.0082 58.3777 58.1794C57.4683 57.3593 57.1449 56.3388 56.9521 55.6271C56.8443 55.2294 57.0773 54.819 57.4724 54.7106Z"
        fill="#1B1D28"
      />
      <path
        d="M50.8807 56.281C56.9181 58.0804 60.105 55.9307 61.2163 54.2074C61.3966 53.9277 61.3559 53.5633 61.1234 53.3251C58.5763 50.7159 53.7268 52.8215 50.6647 54.8198C50.0693 55.2083 50.1993 56.0779 50.8807 56.281Z"
        fill="#ECECEC"
      />
      <path
        d="M37.9665 44.6788C31.7563 44.0484 30.3527 37.5291 30.3297 35.3642C24.8636 31.455 29.0792 28.1263 31.8703 26.9506C31.8703 24.8157 33.5677 21.1764 43.7305 17.2341C56.4307 12.3077 65.9759 14.3498 70.1239 20.5698C72.8542 24.6641 72.1118 26.0399 70.897 27.7675C63.8986 34.5817 54.3668 32.3317 50.4757 30.3549C46.8962 35.771 41.4656 37.0328 39.1977 36.9868C41.4398 39.1361 44.1768 45.3092 37.9665 44.6788Z"
        fill="#ECECEC"
      />
      <path
        d="M61.4762 129.137C58.3402 131.226 54.275 132.155 53.8104 133.665L42.6602 127.047C57.2948 120.777 59.3855 127.395 61.4762 129.137Z"
        fill="#E5986D"
      />
      <path
        d="M13.044 147.248C2.59062 145.158 0.499891 134.012 0.5 127.742L16.18 128.439L39.8741 124.956C49.9093 124.399 54.8572 130.18 55.2057 134.012C55.5541 137.844 23.4973 149.339 13.044 147.248Z"
        fill="#6C65D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M50.8127 127.703C51.0271 127.394 51.4519 127.317 51.7615 127.531C52.5673 128.089 53.7534 129.111 54.7452 130.402C55.73 131.683 56.585 133.309 56.585 135.058C56.585 135.434 56.2797 135.739 55.9031 135.739C55.5265 135.739 55.2212 135.434 55.2212 135.058C55.2212 133.741 54.5663 132.407 53.6637 131.232C52.7681 130.067 51.6893 129.139 50.9852 128.652C50.6756 128.438 50.5983 128.013 50.8127 127.703Z"
        fill="#252A38"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M38.347 125.681C38.7163 125.608 39.0756 125.847 39.1494 126.216C39.2233 126.585 38.9838 126.944 38.6145 127.018C34.1921 127.902 30.2147 127.99 27.3427 127.858C25.9058 127.792 24.7433 127.67 23.9372 127.564C23.534 127.512 23.2198 127.463 23.0048 127.427C22.8972 127.409 22.8145 127.394 22.7578 127.384C22.7294 127.378 22.7076 127.374 22.6924 127.371L22.6747 127.368L22.6696 127.367L22.668 127.367C22.6678 127.367 22.667 127.366 22.8008 126.698"
        fill="#252A38"
      />
      <path
        d="M102.508 78.8781L99.1421 83.3124C98.6345 83.9811 98.843 84.9471 99.5809 85.3447L106.342 88.989C106.677 89.1695 107.072 89.2011 107.431 89.0763L130.678 80.9978C132.128 80.4936 131.768 78.3398 130.233 78.3398H103.59C103.166 78.3398 102.765 78.5389 102.508 78.8781Z"
        fill="#E5986D"
      />
      <path
        d="M113.361 92.2034C106.286 100.318 100.763 116.992 97.7425 127.01C97.2711 128.573 95.5592 129.394 94.0443 128.778L78.6453 122.523C77.4063 122.02 76.6998 120.701 77.0304 119.407C81.1314 103.353 92.1839 88.4134 98.5349 81.4656C99.3763 80.5451 100.738 80.3599 101.826 80.9709L112.745 87.1044C114.514 88.0979 114.693 90.6749 113.361 92.2034Z"
        fill="#6C64D9"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M91.747 88.7032C92.0505 88.9147 92.1243 89.3309 91.9119 89.6329C89.5663 92.9668 85.6071 98.8869 83.9625 105.432C83.8727 105.79 83.5084 106.007 83.149 105.918C82.7895 105.829 82.571 105.466 82.6608 105.108C84.3706 98.3036 88.462 92.2085 90.8126 88.8673C91.0251 88.5653 91.4434 88.4918 91.747 88.7032Z"
        fill="#252A38"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.64 84.7C108.838 84.3299 109.274 84.2048 109.613 84.4208L113.882 87.1364C114.222 87.3524 114.336 87.8275 114.138 88.1976C113.94 88.5678 113.505 88.6928 113.165 88.4769L108.896 85.7612C108.557 85.5453 108.442 85.0702 108.64 84.7Z"
        fill="#252A38"
      />
      <path
        d="M88.334 41.2236C88.0693 41.106 87.9562 40.653 87.8177 40.4293C87.4456 39.8282 86.9831 39.2768 86.5469 38.7217"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M102.633 38.1854C101.87 33.1021 100.58 28.0787 99.7734 22.9951"
        stroke="#6C63FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M115.141 36.0409C115.141 33.4843 115.302 30.9051 115.498 28.3564"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M132.117 36.3983C133.314 33.0632 134.549 29.7462 135.691 26.3906"
        stroke="#6C63FF"
        strokeWidth="3"
        strokeLinecap="round"
      />
      <path
        d="M144.914 40.1513C146.575 39.0187 148.011 37.3425 149.56 36.041"
        stroke="white"
        strokeWidth="3"
        strokeLinecap="round"
      />
    </svg>
  );
};

export default SubscriptionSvg;
