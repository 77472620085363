import React, { useState, useEffect } from "react";
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Box,
  Typography,
} from "@mui/material";
import useStyles from "../../NewOrganizationsOverview/NewOrganizationsOverview.styles";
import { fget } from "../../../../../API/callsApi";
import CommonLoader from "../../../../ReusableComponents/CommonLoader/CommonLoader";
import ActiveUserTableItems from "./ActiveUserTableItems";
// import CustomPagination from "../../../../ReusableComponents/CustomPagination/CustomPagination";
import NoUserFound from "../../../../ReusableComponents/Svgs/NoUserFound";

const ActiveUsersTable = ({
  currentOrganization,
  selectedOrg,
  userSearchValue,
  userRoleValue,
}) => {
  const classes = useStyles();
  // const [currentPage, setCurrentPage] = useState(1);
  // const [isNewPageLoading, setIsNewPageLoading] = useState(false);
  // const [updateOnPageChange, setUpdateOnPageChange] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [allActiveUsers, setAllActiveUsers] = useState([]);
  const [activeUsers, setActiveUsers] = useState([]);
  const [refreshCounter, setRefreshCounter] = useState(false);

  // const handlePageChange = (pageNo) => {
  //   setCurrentPage(pageNo);
  //   setUpdateOnPageChange(updateOnPageChange + 1);
  // };

  const getActiveUser = async () => {
    setIsLoading(true);
    const url = `/organization/${currentOrganization}/users/`;
    try {
      const res = await fget({ url });
      if (res?.status === 200) {
        setAllActiveUsers(res?.data);
        setActiveUsers(res.data);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (
      userRoleValue === "User" ||
      userRoleValue === "Admin" ||
      userRoleValue === "Owner"
    ) {
      const currentUsers = activeUsers.filter(
        (user) => user.role === userRoleValue
      );
      setAllActiveUsers(currentUsers);
    } else if (userRoleValue === "All") {
      getActiveUser();
    }
  }, [userRoleValue]);

  const handleRefreshCounter = () => {
    setRefreshCounter(refreshCounter + 1);
  };

  useEffect(() => {
    getActiveUser();
  }, [currentOrganization, refreshCounter]);

  return (
    <>
      {isLoading ? (
        <div className={classes.loaderContainer}>
          <CommonLoader />
        </div>
      ) : allActiveUsers && allActiveUsers.length > 0 ? (
        <Box sx={{ height: "100%", width: "100%" }}>
          <TableContainer style={{ height: "6%", overflow: "hidden" }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                  >
                    User
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                  >
                    User Role
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                  >
                    Email ID
                  </TableCell>
                  <TableCell
                    align="center"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                  >
                    Joining Date
                  </TableCell>
                  <TableCell
                    align="right"
                    className={`${classes.tableRowHeadService} ${classes.tableHead}`}
                  >
                    <span style={{ marginRight: "40px" }}>Action</span>
                  </TableCell>
                </TableRow>
              </TableHead>
            </Table>
          </TableContainer>
          <Box
            style={{
              height: "87%",
              overflowY: "auto",
              border: "1px solid black",
              borderRadius: "5px",
            }}
          >
            <TableContainer>
              <Table aria-label="simple table">
                <TableBody style={{ height: "100%" }}>
                  {allActiveUsers
                    ?.filter((search) => {
                      if (userSearchValue === undefined) {
                        return search;
                      } else if (userSearchValue === "") {
                        return search;
                      } else if (
                        search?.first_name
                          ?.toLowerCase()
                          .includes(userSearchValue.toLowerCase())
                      ) {
                        return search;
                      } else if (
                        search?.last_name
                          ?.toLowerCase()
                          .includes(userSearchValue.toLowerCase())
                      ) {
                        return search;
                      }
                      return null;
                    })
                    .map((eachUser) => (
                      <ActiveUserTableItems
                        key={eachUser.id}
                        eachUser={eachUser}
                        selectedOrg={selectedOrg}
                        handleRefreshCounter={handleRefreshCounter}
                      />
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Box>
          {/* <footer style={{ width: "100%", position: "relative" }}>
            <CustomPagination
              handlePageChange={handlePageChange}
              currentPage={currentPage}
              totalItem={allActiveUsers?.count}
              totalPage={
                allActiveUsers ? Math.ceil(allActiveUsers?.count / 50) : 0
              }
              setIsNewPageLoading={setIsNewPageLoading}
              type="Active Users"
            />
          </footer> */}
        </Box>
      ) : (
        <Box
          sx={{
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "flex-start",
            mt: 15,
          }}
        >
          <Box>
            <NoUserFound />
            <Typography width={350} variant="h5" textAlign="center" mt={1}>
              Let’s get you started by adding new users to your organization.
            </Typography>
          </Box>
        </Box>
      )}
    </>
  );
};

export default ActiveUsersTable;
