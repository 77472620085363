import React from "react";
import { Box } from "@mui/material";
import Navbar from "../NewNavbar/Navbar";
// import TopBannerComponent from "../ReusableComponents/TopBannerComponent/TopBannerComponent";

const TopBannerLayout = ({ children }) => {
  // const [collapseBanner, setCollapseBanner] = useState(true);

  return (
    <Box
      style={{
        width: "100%",
        height: "100vh",
        boxSizing: "border-box !important",
        overflow: "hidden",
      }}
    >
      <div
        style={{
          boxSizing: "border-box",
          height: "10%",
        }}
      >
        <Navbar />

        {/* <div
          style={{
            height: collapseBanner && type !== "services" ? "40%" : "71%",
          }}
        >
          <TopBannerComponent
            type={type}
            collapseBanner={collapseBanner}
            handleBannerCollapse={() => setCollapseBanner(!collapseBanner)}
          />
        </div> */}
      </div>
      <div
        style={{
          height: "90%",
          boxSizing: "border-box",
        }}
      >
        {children}
      </div>
    </Box>
  );
};

export default TopBannerLayout;
