import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  root: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    marginTop: "10px",
    position: "absolute",
    width: "100%",
    bottom: "-50px",
  },
  showingNumber: {
    color: "#000000",
  },
  gray: {
    color: "#606060",
  },
  buttonsAndPageNumber: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
  },
  pageListContainer: {
    position: "absolute",
    bottom: 40,
    right: 0,
    maxHeight: "200px",
    overflow: "auto",
    backgroundColor: "#000000",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px",
    borderRadius: "10px 10px 0px 0px",
    width: "100%",
    padding: "5px 0px",
  },
  dropdown: {
    padding: "10px 15px",
    backgroundColor: "#000000",
    borderRadius: "5px",
    display: "flex",
    gap: "8px",
    alignItems: "center",
    justifyContent: "center",
    position: "relative",
  },
  triangleContainer: {
    backgroundColor: "transparent",
    position: "absolute",
    bottom: "30px",
    right: "190px",
    height: "20px",
    width: "110px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  triangle: {
    backgroundColor: "#ECECEC",
    height: "20px",
    width: "30px",
    clipPath: "polygon(100% 0, 0 0, 49% 100%)",
  },
  singlePageNumber: {
    width: "30px",
    height: "30px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
    color: "#606060",
    "&:hover": {
      color: "#000000",
      backgroundColor: "#6C65D9",
    },
  },
  selectedPageNumber: {
    backgroundColor: "#6C65D9",
    color: "#000000",
    width: "30px",
    height: "30px",
    borderRadius: "10px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    cursor: "pointer",
  },
  nextAndPrev: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    borderRadius: "10px",
  },
  previous: {
    boxSizing: "border-box",
    display: "flex",
    alignItems: "center",
    gap: "5px",
    border: "2px solid #000000",
    color: "#000000 !important",
    "&:hover": {
      backgroundColor: "#D9D9D9 !important",
    },
  },
  next: {
    display: "flex",
    alignItems: "center",
    gap: "5px",
    backgroundColor: "#000000",
    color: "white !important",
    borderRadius: "0px",
    cursor: "pointer",
    "&:hover": {
      backgroundColor: "#BBBBBB !important",
    },
  },
}));

export default useStyles;
