import React from "react";
import { useHistory } from "react-router-dom";
import { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Select,
  MenuItem,
  List,
  ListItem,
  ListItemText,
  FormControl,
  InputLabel,
} from "@mui/material";
import useStyles from "./OrganizationsOverviewUserView.styles";
import MyOrganizationUserView from "../MyOrganizationUserView/MyOrganizationUserView";
import OrganizationServicesUserView from "../OrganizationServicesUserView/OrganizationServicesUserView";
import OrganizationUsersUserView from "../OrganizationUsersUserView/OrganizationUsersUserView";
import useAuth from "../../../../hooks/useAuth";

const OrganizationOverviewUserView = () => {
  const classes = useStyles();
  const history = useHistory();
  const { organizationsList, currentOrg, setCurrentOrg } = useAuth();
  const [selectedTab, setSelectedTab] = useState("manage-orgs");
  const searchParams = new URLSearchParams(
    history.location.search.substring(1)
  );

  useEffect(() => {
    searchParams.get("orgInfoType") &&
      setSelectedTab(searchParams.get("orgInfoType"));
  }, [searchParams.get("orgInfoType")]);

  const handleOrgChange = (value) => {
    const filteredOrg = organizationsList.filter(
      (eachorg) => eachorg.id === value
    );
    setCurrentOrg(filteredOrg[0]);
    if (
      (filteredOrg.length > 0 && filteredOrg[0]?.role === "admin") ||
      filteredOrg[0]?.role === "owner"
    ) {
      history.push(
        `/organizations/${filteredOrg[0].id}/?orgInfoType=${selectedTab}`
      );
    } else if (filteredOrg.length > 0 && filteredOrg[0]?.role === "user") {
      history.push(
        `/user/organizations/${filteredOrg[0].id}/?orgInfoType=${selectedTab}`
      );
    }
  };

  return (
    <Box boxSizing="border-box" px={4} py={3} style={{ height: "100%" }}>
      <Grid container spacing={3} height="100%">
        <Grid item xs={3} lg={2} style={{ height: "100%" }}>
          <FormControl size="small" style={{ width: "100%" }}>
            <InputLabel
              style={{ padding: "0px 5px" }}
              id="demo-simple-select-helper-label-org"
            >
              Organization
            </InputLabel>
            <Select
              labelId="demo-simple-select-helper-label-org"
              id="demo-simple-select-helper-label-org"
              variant="outlined"
              label="Organization"
              style={{ width: "100%" }}
              value={currentOrg.id}
              onChange={(event) => handleOrgChange(event.target.value)}
            >
              {organizationsList.map((eachOrg) => {
                return (
                  <MenuItem value={eachOrg.id} key={eachOrg.id}>
                    {eachOrg.name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
          <Box mt={2} className={classes.listContainer}>
            <List style={{ padding: "0px", height: "max-content" }}>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("orgInfoType", "manage-orgs");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  selectedTab === "manage-orgs"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemText primary="My Organization" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("orgInfoType", "manage-users");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  selectedTab === "manage-users"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemText primary="Organization Users" />
              </ListItem>
              <ListItem
                button={true}
                onClick={() => {
                  searchParams.set("orgInfoType", "manage-services");
                  history.push({ search: searchParams.toString() });
                }}
                className={
                  selectedTab === "manage-services"
                    ? classes.listItmBtnSelected
                    : classes.listItemBtn
                }
              >
                <ListItemText primary="Organization Services" />
              </ListItem>
            </List>
          </Box>
        </Grid>
        <Grid item xs={9} lg={10} style={{ height: "100%" }}>
          {selectedTab === "manage-orgs" && (
            <MyOrganizationUserView
              currentOrganization={currentOrg.id}
              organizationsList={organizationsList}
            />
          )}
          {selectedTab === "manage-users" && (
            <OrganizationUsersUserView currentOrganization={currentOrg.id} />
          )}
          {selectedTab === "manage-services" && (
            <OrganizationServicesUserView currentOrganization={currentOrg.id} />
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default OrganizationOverviewUserView;
