import React from "react";
import { Link } from "react-router-dom/cjs/react-router-dom";
import { Box, Typography } from "@mui/material";
import { Headset } from "phosphor-react";
import useStyles from "../NewLogin/NewLogin.styles";
import CheckCircleSvg from "../../ReusableComponents/Svgs/CheckCircleSvg";

const NewPasswordResetSuccessful = () => {
  const classes = useStyles();

  return (
    <Box
      px={16}
      py={8}
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <Box
        gap={4}
        style={{
          height: "100%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <div className={classes.gapContainer2}>
          <CheckCircleSvg />
          <Typography variant="h1" fontWeight={600}>
            Your new password has been set!
          </Typography>
        </div>
        <Typography variant="h5" color="#666666" ml={6}>
          You will be redirected to the login page in a few seconds...
        </Typography>
      </Box>
      <div className={classes.footer}>
        <Headset /> Need help? Contact{" "}
        <Link to="#">
          <span style={{ color: "#666666" }}>support@vigastudios.com</span>
        </Link>
      </div>
    </Box>
  );
};

export default NewPasswordResetSuccessful;
