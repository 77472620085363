import React, { createContext, useEffect, useState } from "react";
// import userInfoProviderHook from "../hooks/useLogin";
import { fget } from "../API/callsApi";

export const AuthContext = createContext(null);

const UserDataProvider = ({ children }) => {
  const allContext = userInfoProviderHook();
  return (
    <AuthContext.Provider value={allContext}>{children}</AuthContext.Provider>
  );
};

export default UserDataProvider;

const userInfoProviderHook = () => {
  const [isUserDataLoading, setIsUserDataLoading] = useState(true);
  const [userData, setUserData] = useState({});
  const [organizationsList, setOrganizationsList] = useState([]);
  const [adminOrgs, setAdminOrgs] = useState([]);
  const [currentOrganization, setCurrentOrganization] = useState();
  const [isAdmin, setIsAdmin] = useState(false);
  const [currentGreeting, setCurrentGreeting] = useState();
  const [currentOrg, setCurrentOrg] = useState({});

  useEffect(() => {
    getOrgList();
    getUserData();
    getGreeting();
  }, []);

  const checkUserRole = (params) => {
    if (
      params.orgId &&
      currentOrg.id &&
      Number(params.orgId) !== currentOrg?.id
    ) {
      const filteredOrg = organizationsList.filter(
        (eachOrg) => eachOrg.id === Number(params.orgId)
      );
      if (filteredOrg.length > 0) {
        setCurrentOrg(filteredOrg[0]);
        return filteredOrg[0].role;
      }
    } else {
      return currentOrg.role;
    }
  };

  useEffect(() => {
    const isAvailable = userData?.admin_orgs?.some(
      (sinOrg) => sinOrg?.id === Number(currentOrganization)
    );
    setIsAdmin(isAvailable);
  }, [currentOrganization, userData]);

  //gets the greeting according to the time of the day
  const getGreeting = () => {
    const currentTime = new Date();
    const hours = currentTime.getHours();
    let greeting;
    if (hours < 12) {
      greeting = "Good Morning";
    } else if (hours < 18) {
      greeting = "Good Afternoon";
    } else {
      greeting = "Good Evening";
    }
    setCurrentGreeting(greeting);
  };

  // reload user information on reload
  const getUserData = async () => {
    let userData = {
      url: "/user/",
    };

    try {
      const res = await fget(userData);
      if (res?.status === 200) {
        setUserData(res.data);
        setAdminOrgs(res.data.admin_orgs);
        setIsUserDataLoading(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // load user information on first time on log in
  const loadUserData = async (token) => {
    let userData = {
      url: "/user/",
      token_value: token,
    };

    try {
      const res = await fget(userData);
      if (res?.status === 200) {
        setUserData(res.data);
        setIsUserDataLoading(false);
        setAdminOrgs(res.data.admin_orgs);
      }
    } catch (error) {
      console.log(error);
    }
  };

  // get organizations list of an user
  const getOrgList = async () => {
    let userData = {
      url: "/user/org/",
    };
    try {
      const res = await fget(userData);
      if (res?.status === 200 && res?.data?.length) {
        setOrganizationsList(res?.data);
        if (res?.data?.length > 0 && !currentOrg.id) {
          setCurrentOrg(res?.data[0]);
          setCurrentOrganization(res?.data[0]?.id);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };

  // return all value/function which will needed in other component
  return {
    isAdmin, // will return a boolean value which will define user role based on org
    isUserDataLoading, // state of user data loading
    userData, // user whole information
    loadUserData, // function to load user data after first log in
    organizationsList, // organizations list to load in other
    currentOrganization, // current selected organization
    setCurrentOrganization, // set state to select new organization
    currentGreeting, //greeting to display according to current time
    getOrgList, //to lead the list of orgs
    currentOrg, //gives current org object
    setCurrentOrg, // set state to current org
    adminOrgs, // all orgs user is admin at
    checkUserRole, //check user role for each org
  };
};
