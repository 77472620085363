import React, { useEffect, useState } from "react";
import { useHistory, Link } from "react-router-dom";
import { useFormik } from "formik";
import * as yup from "yup";
import { Box, TextField, Typography, CircularProgress } from "@mui/material";
import { Headset } from "phosphor-react";
import useStyles from "./CreateOrganization.styles";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import CreateOrgRequestPending from "../CreateOrgRequestPending/CreateOrgRequestPending";
import { readCookie } from "../../../authorization";
import { fpost } from "../../../API/callsApi";
import toast from "react-hot-toast";

const CreateOrganizationOverview = () => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [requestSuccessful, setRequestSuccessful] = useState(false);

  const Schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
    firstName: yup.string().required("Required"),
    lastName: yup.string().required("Required"),
    orgName: yup.string().required("Required"),
    orgAbbreviation: yup.string().required("Required"),
  });

  useEffect(() => {
    let data = readCookie("refresh");
    if (data) {
      history.push("/dashboard/?dashboardType=services");
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      firstName: "",
      lastName: "",
      email: "",
      orgName: "",
      orgAbbreviation: "",
      employeeCount: "",
      note: "",
    },
    validationSchema: Schema,

    onSubmit: async (values) => {
      setLoading(true);
      let formdata = new FormData();
      formdata.append("organization_name", values.orgName);
      formdata.append("organization_abbreviation", values.orgAbbreviation);
      formdata.append("user_email", values.email);
      formdata.append("first_name", values.firstName);
      formdata.append("last_name", values.lastName);
      formdata.append("employees_count", values.employeeCount);
      formdata.append("message", values.note);
      let obj = {
        url: "/request-org/",
        data: formdata,
      };
      try {
        const res = await fpost(obj);
        if (res.status === 200 || res.status === 201) {
          setLoading(false);
          setRequestSuccessful(true);
        }
      } catch (error) {
        setLoading(false);
        toast.error(error.response.data.detail);
      }
    },
  });

  if (requestSuccessful) {
    return <CreateOrgRequestPending />;
  }

  return (
    <Box px={14} py={8} className={classes.container2}>
      <form onSubmit={formik.handleSubmit}>
        <Box
          sx={{
            gap: {
              xl: 3,
              xs: 2,
            },
          }}
          className={classes.container2}
        >
          <Typography variant="h1" fontWeight={600}>
            Setting up Your Organization
          </Typography>
          <Box className={classes.innerContainer}>
            <Typography fontWeight={500} variant="h3">
              Owner Info
            </Typography>
            <div className={classes.flexBoxSpaceBetween}>
              <TextField
                required
                className={classes.inputContainer}
                label="First Name"
                name="firstName"
                id="firstName"
                onChange={formik.handleChange}
                error={
                  formik.touched.firstName && Boolean(formik.errors.firstName)
                }
              />
              <TextField
                required
                className={classes.inputContainer}
                label="Last Name"
                name="lastName"
                id="lastName"
                onChange={formik.handleChange}
                error={
                  formik.touched.lastName && Boolean(formik.errors.lastName)
                }
              />
            </div>
            <TextField
              required
              type="email"
              id="email"
              name="email"
              label="Organization Email ID"
              className={classes.inputContainer}
              onChange={formik.handleChange}
              error={formik.touched.email && Boolean(formik.errors.email)}
            />
          </Box>
          <div className={classes.innerContainer}>
            <Typography fontWeight={500} variant="h3">
              Organization Info
            </Typography>
            <div className={classes.flexBoxSpaceBetween}>
              <TextField
                required
                className={classes.inputContainer}
                label="Org Name"
                id="orgName"
                name="orgName"
                onChange={formik.handleChange}
                error={formik.touched.orgName && Boolean(formik.errors.orgName)}
              />
              <TextField
                required
                className={classes.inputContainer}
                label="Organization Abbreviation"
                name="orgAbbreviation"
                id="orgAbbreviation"
                onChange={formik.handleChange}
                error={
                  formik.touched.orgAbbreviation &&
                  Boolean(formik.errors.orgAbbreviation)
                }
              />
            </div>
            <div className={classes.flexBoxSpaceBetween}>
              <TextField
                className={classes.inputContainer}
                label="Org Size(Optional)"
                type="number"
                id="employeeCount"
                name="employeeCount"
                onChange={formik.handleChange}
              />
              <TextField
                label="Note (optional)"
                className={classes.inputContainer}
                name="note"
                id="note"
                onChange={formik.handleChange}
              />
            </div>
          </div>
          <div className={classes.gapContainer}>
            <div className={classes.btn}>
              <FilledButton
                disabled={loading}
                text={
                  loading ? (
                    <CircularProgress
                      width="20"
                      height="20"
                      style={{ color: "white" }}
                    />
                  ) : (
                    "Create Organization"
                  )
                }
                submit={() => formik.submitForm()}
              />
            </div>
            <Link to="/login">
              <Typography color="#666666" variant="h6">
                Alreay have an account? Log in
              </Typography>
            </Link>
          </div>
        </Box>
      </form>
      <div className={classes.footer}>
        <Headset /> Need help? Contact{" "}
        <Link to="#">
          <span style={{ color: "#666666" }}>support@vigastudios.com</span>
        </Link>
      </div>
    </Box>
  );
};

export default CreateOrganizationOverview;
