import React from "react";
import { Box } from "@mui/material";
import VigaSideBanner from "../../assets/VigaSideBanner.png";

const SideBannerLayout = ({ children }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "100vh",
        boxSizing: "border-box",
        overflow: "hidden",
      }}
    >
      <Box style={{ width: "17%", height: "100%" }}>
        <img src={VigaSideBanner} height="100%" width="100%" />
      </Box>
      <Box style={{ height: "100%", width: "80%" }}>{children}</Box>
      <Box
        style={{ width: "3%", height: "100%", backgroundColor: "#141417" }}
      />
    </div>
  );
};

export default SideBannerLayout;
