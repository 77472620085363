import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as yup from "yup";
import { useHistory, useLocation } from "react-router-dom";
import { toast } from "react-hot-toast";
import { Box, Typography, TextField, CircularProgress } from "@mui/material";
import { Headset } from "phosphor-react";
import useStyles from "./NewLogin.styles";
import { Link } from "react-router-dom/cjs/react-router-dom";
import PasswordTextField from "../../ReusableComponents/PasswordTextField/PasswordTextField";
import { FilledButton } from "../../ReusableComponents/Buttons/Buttons";
import { createCookie, readCookie } from "../../../authorization";
import { fpost } from "../../../API/callsApi";

const NewLogin = (props) => {
  const classes = useStyles();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const queryParams = new URLSearchParams(useLocation().search);
  const redirectUrl = queryParams.get("redirectUrl");

  const Schema = yup.object().shape({
    email: yup.string().email("Invalid email").required("Required"),
    password: yup.string().required("Password Required"),
  });

  useEffect(() => {
    if (props?.clearLocalStorage) {
      localStorage.clear();
    } else {
      let data = readCookie("refresh");
      let data2 = readCookie("access");

      // let slug = params.slug;

      if (!redirectUrl && data) {
        // history.push(data ? "/home" : "");
        history.push("/dashboard/?dashboardType=services");
      } else if (redirectUrl && data && data2) {
        window.location.href = `https://${redirectUrl}`;
      }
    }
  }, []);

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Schema,

    onSubmit: async (values) => {
      setLoading(true);
      let formdata = new FormData();
      formdata.append("email", values.email);
      formdata.append("password", values.password);

      let obj = {
        url: "/api/token/",
        data: formdata,
      };
      try {
        const res = await fpost(obj);
        if (res) {
          let refreshToken = res.data.refresh;
          let accessToken = res.data.access;
          localStorage.setItem("refresh", refreshToken);
          localStorage.setItem("access", accessToken);

          let rToken = localStorage.getItem("refresh");
          let aToken = localStorage.getItem("access");

          createCookie("access", aToken);
          createCookie("refresh", rToken);
          // loadUserData(accessToken);

          toast.success("Successfully logged in");
          // let slug = params.slug;
          if (!redirectUrl) {
            history.push("/dashboard/?dashboardType=services");
          } else {
            window.location.href = `https://${redirectUrl}`;
          }
        }
      } catch (error) {
        setLoading(false);
        if (error.response.data.detail === "Incorrect Password!") {
          toast.error("Incorrect Username/Password");
        } else if (
          error.response.data.detail ===
          "No active account found with the given credentials"
        ) {
          toast.error("You seem new here. Have you registered?");
        } else {
          toast.error("Oops, something went wrong. Try again");
        }
      }
    },
  });

  return (
    <Box
      px={16}
      py={8}
      style={{
        width: "100%",
        height: "100%",
        boxSizing: "border-box",
        position: "relative",
      }}
    >
      <form style={{ height: "100%" }} onSubmit={formik.handleSubmit}>
        <Box
          gap={4}
          style={{
            height: "100%",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
          }}
        >
          <Typography variant="h1" fontWeight={600}>
            Log in
          </Typography>

          <TextField
            required
            type="email"
            label="Organization Email ID"
            id="email"
            name="email"
            className={classes.inputContainer}
            onChange={formik.handleChange}
            error={formik.touched.email && Boolean(formik.errors.email)}
          />
          {formik.touched.email && formik.errors.email && (
            <Typography variant="h6" color="red">
              {formik.errors.email}
            </Typography>
          )}
          <div className={classes.inputContainer}>
            <PasswordTextField
              label="Password"
              id="password"
              name="password"
              handleChange={formik.handleChange}
              error={formik.touched.password && Boolean(formik.errors.password)}
            />
            {formik.touched.password && formik.errors.password && (
              <Typography variant="h6" color="red">
                {formik.errors.password}
              </Typography>
            )}
          </div>
          <div className={classes.gapContainer}>
            <div style={{ width: "250px" }}>
              <FilledButton
                disabled={loading}
                text={
                  loading ? (
                    <CircularProgress
                      width="20"
                      height="20"
                      style={{ color: "white" }}
                    />
                  ) : (
                    "Login"
                  )
                }
                submit={() => formik.submitForm()}
              />
            </div>
            <Link to="/forgotpassword">
              <Typography color="#666666" variant="h6">
                Forgot password?
              </Typography>
            </Link>
          </div>
          <Link to="/create-org">
            <Typography color="#666666" variant="h6">
              Want to create an org? Sign up
            </Typography>
          </Link>
        </Box>
      </form>
      <div className={classes.footer}>
        <Headset /> Need help? Contact{" "}
        <Link to="#">
          <span style={{ color: "#666666" }}>support@vigastudios.com</span>
        </Link>
      </div>
    </Box>
  );
};

export default NewLogin;
