import React, { useEffect, useState } from "react";
import { Typography, Box } from "@mui/material";
import { CheckCircle } from "phosphor-react";
import SubscriptionSvg from "../../../ReusableComponents/Svgs/SubscriptionSvg";
import { fget } from "../../../../API/callsApi";
import CommonLoader from "../../../ReusableComponents/CommonLoader/CommonLoader";

const ServiceSubscriptionPlan = ({ currentServiceProduct }) => {
  const [stripeProduct, setStripeProduct] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const getStripeProducts = async () => {
    if (currentServiceProduct?.id) {
      setIsLoading(true);
      try {
        const res = await fget({
          url: "/stripe/product/",
        });
        const filtered = res.data.data.filter(
          (eachProduct) =>
            eachProduct.metadata.service === currentServiceProduct.name
        );
        if (filtered.length > 0) {
          setStripeProduct(filtered[0]);
        }
        setIsLoading(false);
      } catch (error) {
        console.log(error);
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    getStripeProducts();
  }, [currentServiceProduct?.id]);

  return (
    <>
      <Typography variant="h2" style={{ fontWeight: "600" }}>
        Subscription Plans
      </Typography>
      <Typography variant="h5" style={{ margin: "10px 0px" }}>
        Movie Colab offers a license based subscription, where each user
        requires a license to login and use it’s services. Renewals can be
        adjusted to either auto or manual during and after purchase.
      </Typography>
      <Box
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "-50px",
        }}
      >
        {isLoading ? (
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <CommonLoader />
          </div>
        ) : (
          stripeProduct?.prices?.data.map((eachPriceData) => {
            return eachPriceData?.lookup_key ===
              "yearly_license_subscription" ? (
              <Box
                mt={1}
                px={3}
                py={2}
                style={{
                  backgroundColor: "#1B1D28",
                  width: "max-content",
                  borderRadius: "10px",
                  marginLeft: "50px",
                }}
              >
                <div
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    position: "relative",
                  }}
                >
                  <Typography
                    color="#ffffff"
                    style={{ position: "absolute", left: "130px", top: "44px" }}
                  >
                    Yearly
                  </Typography>
                  <SubscriptionSvg />
                </div>
                <Box
                  style={{
                    borderRadius: "10px",
                    padding: "12px 24px",
                    backgroundColor: "#6C63FF",
                  }}
                >
                  <Typography variant="h2" color="#FFFFFF">
                    Rs, {eachPriceData.unit_amount} /year
                  </Typography>
                </Box>
                <Box mt={2} style={{ color: "#FFFFFF" }}>
                  <Typography
                    variant="h6"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <CheckCircle weight="fill" color="#A09AFB" /> 1 user account
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <CheckCircle weight="fill" color="#A09AFB" /> Movie Colab
                    Web & Desktop
                  </Typography>
                  <Typography
                    variant="h6"
                    style={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <CheckCircle weight="fill" color="#A09AFB" /> 24/7 Customer
                    support
                  </Typography>
                </Box>
              </Box>
            ) : (
              eachPriceData?.lookup_key === "monthly_license_subscription" && (
                <Box
                  mt={1}
                  px={3}
                  py={2}
                  style={{
                    backgroundColor: "#1B1D28",
                    width: "max-content",
                    borderRadius: "10px",
                    marginLeft: "50px",
                  }}
                >
                  <div
                    style={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      position: "relative",
                    }}
                  >
                    <Typography
                      color="#ffffff"
                      style={{
                        position: "absolute",
                        left: "130px",
                        top: "44px",
                      }}
                    >
                      Monthly
                    </Typography>
                    <SubscriptionSvg />
                  </div>
                  <Box
                    style={{
                      borderRadius: "10px",
                      padding: "12px 24px",
                      backgroundColor: "#6C63FF",
                    }}
                  >
                    <Typography variant="h2" color="#FFFFFF">
                      Rs, {eachPriceData.unit_amount} /mo
                    </Typography>
                  </Box>
                  <Box mt={2} style={{ color: "#FFFFFF" }}>
                    <Typography
                      variant="h6"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CheckCircle weight="fill" color="#A09AFB" /> 1 user
                      account
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CheckCircle weight="fill" color="#A09AFB" /> Movie Colab
                      Web & Desktop
                    </Typography>
                    <Typography
                      variant="h6"
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "10px",
                      }}
                    >
                      <CheckCircle weight="fill" color="#A09AFB" /> 24/7
                      Customer support
                    </Typography>
                  </Box>
                </Box>
              )
            );
          })
        )}
      </Box>
    </>
  );
};

export default ServiceSubscriptionPlan;
