import React from "react";

const ToolBoxIcon = ({ color }) => {
  return (
    <svg
      width="20"
      height="20"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.0007 10.6667H10.6673V10H5.33398V10.6667H4.00065V10H1.33398V13.3334H14.6673V10H12.0007V10.6667ZM13.334 5.33335H11.334V4.00002C11.334 3.26669 10.734 2.66669 10.0007 2.66669H6.00065C5.26732 2.66669 4.66732 3.26669 4.66732 4.00002V5.33335H2.66732C1.93398 5.33335 1.33398 5.93335 1.33398 6.66669V9.33335H4.00065V8.00002H5.33398V9.33335H10.6673V8.00002H12.0007V9.33335H14.6673V6.66669C14.6673 5.93335 14.0673 5.33335 13.334 5.33335ZM10.0007 5.33335H6.00065V4.00002H10.0007V5.33335Z"
        fill={color}
      />
    </svg>
  );
};

export default ToolBoxIcon;
