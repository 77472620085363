import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  container: {
    height: "94%",
    marginTop: "0px",
  },
  listContainer: {
    border: "1px solid #000000",
    borderRadius: "5px",
    height: "100%",
  },
  listItemBtn: {
    borderBottom: "1px solid black",
    transition: "none !important",
    "&:hover": {
      backgroundColor: "#BBBBBB",
    },
  },
  listItmBtnSelected: {
    backgroundColor: "#000000",
    color: "#FFFFFF",
  },
  listIcon: {
    minWidth: "45px",
  },
  tableRowHeadService: {
    width: "20%",
    padding: "8px",
  },
  tableHead: {
    borderBottom: "1px solid white",
  },
  tableRowCells: {
    fontSize: "16px",
  },
  tableRowCellsColor: {
    color: "#666666",
    padding: "10px",
  },
  tableRowHeadInvitation: {
    width: "25%",
  },
  tableRowHeadInvitationTwo: {
    width: "20%",
  },
  centerContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  loaderContainer: {
    width: "100%",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
}));

export default useStyles;
