import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Button,
  Typography,
  Modal,
  Tooltip,
} from "@mui/material";
import { Info } from "phosphor-react";
import useStyles from "../ManageTransactionAndInvoicesOverview/ManageTransactionAndInvoicesOverview.styles";
import TransactionInfoModal from "../TransactionModals/TransactionInfoModal";

const TransactionFailedTableItem = ({ eachTransaction }) => {
  const classes = useStyles();
  const [isTransactionInfoModal, setTransactionInfoModal] = useState(false);

  const handleTransactionInfoModal = () => {
    setTransactionInfoModal(!isTransactionInfoModal);
  };

  return (
    <>
      <TableRow sx={{ "&:last-child td, &:last-child th": { border: 0 } }}>
        <TableCell
          component="th"
          scope="row"
          className={`${classes.tableRowHeadService}`}
        >
          <Tooltip title={eachTransaction.checkout_id}>
            <Typography
              variant="h5"
              fontWeight={500}
              style={{
                width: "150px",
                overflow: "hidden",
                whiteSpace: "nowrap",
                textOverflow: "ellipsis",
              }}
            >
              {eachTransaction.checkout_id}
            </Typography>
          </Tooltip>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
        >
          <Typography variant="h5">
            {new Date(eachTransaction.created_at).toLocaleDateString("en-US", {
              month: "long",
              day: "numeric",
              year: "numeric",
            })}
          </Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
        >
          <Typography variant="h5">{eachTransaction.product_name}</Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
        >
          <Typography variant="h5">Recurring</Typography>
        </TableCell>
        <TableCell
          align="center"
          className={`${classes.tableRowHeadService} ${classes.tableRowCellsColor}`}
        >
          <Typography variant="h5">
            Rs. {eachTransaction.total_amount}
          </Typography>
        </TableCell>
        <TableCell align="right" className={`${classes.tableRowHeadService}`}>
          <Button
            style={{
              margin: "0px",
              padding: "5px",
              minWidth: "0px",
              textTransform: "none",
              marginRight: "40px",
            }}
            onClick={handleTransactionInfoModal}
          >
            <Info size={20} color="#666666" />
          </Button>
        </TableCell>
      </TableRow>
      <Modal
        open={isTransactionInfoModal}
        className={classes.centerContainer}
        onClose={handleTransactionInfoModal}
      >
        <TransactionInfoModal closeModal={handleTransactionInfoModal} />
      </Modal>
    </>
  );
};

export default TransactionFailedTableItem;
